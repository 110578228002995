const colors = {
  colors: {
    alerts: {
      affirmative: "#67DD8F",
      warning: "#E46968",
    },
    black: "#000000",
    brand: {
      200: "#A7BDD6",
      400: "#F67904",
      700: "#2C3A51",
      900: "#14202C",
    },
    tooltip: {
      bg: "#202D3E",
    },
    dataVis: {
      300: "#FCC089",
      600: "#3E8087",
      graphGradientMidStop: "#738fb1",
    },
    white: "#FFFFFF",
  },
}

export default colors
