import { graphql } from "msw"

export const getClientsMock = graphql.query("GetClients", (req, res, ctx) => {
  return res(
    ctx.data({
      getClients: [
        {
          clientId: 1,
          name: "59A",
          __typename: "Fifty9AClient",
        },
        {
          clientId: 2,
          name: "Client 2",
          __typename: "DirectClient",
        },
      ],
    })
  )
})
