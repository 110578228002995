import { IconBase, IconBaseProps } from "../IconBase"

export const ViewabilityIcon = ({ viewBox = "0 0 270 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M155.88 179.515H114.109C112.118 179.515 110.506 181.12 110.506 183.101C110.506 185.083 112.118 186.688 114.109 186.688H155.88C157.87 186.688 159.483 185.083 159.483 183.101C159.483 181.12 157.87 179.515 155.88 179.515Z"
        fill="currentColor"
      />
      <path
        d="M246.284 160.614C245.316 159.421 243.963 158.36 242.358 157.446C244.987 155.235 246.697 151.976 246.697 148.292V12.0286C246.697 5.39333 241.272 0 234.612 0H35.3757C28.7095 0 23.291 5.4003 23.291 12.0286V148.285C23.291 151.969 25.0013 155.235 27.63 157.439C26.0248 158.36 24.6649 159.414 23.7045 160.614L1.62399 188.041C-0.0863765 190.162 -0.4649 192.625 0.579545 194.795C2.19879 198.151 6.86024 200 13.7017 200H256.293C263.135 200 267.796 198.151 269.416 194.795C270.467 192.625 270.088 190.162 268.371 188.034L246.284 160.607V160.614ZM30.4969 12.0286C30.4969 9.34937 32.684 7.17253 35.3687 7.17253H234.605C237.297 7.17253 239.477 9.34937 239.477 12.0286V148.285C239.477 150.965 237.297 153.141 234.605 153.141H35.3687C32.677 153.141 30.4969 150.965 30.4969 148.292V12.0356V12.0286ZM256.286 192.834H13.6947C10.5684 192.834 8.67575 192.339 7.71542 191.941L29.3193 165.107C30.9315 163.112 37.9202 160.321 45.6449 160.321H224.329C232.054 160.321 239.043 163.112 240.655 165.107L262.245 191.948C261.277 192.346 259.392 192.841 256.279 192.841L256.286 192.834Z"
        fill="currentColor"
      />
      <path
        d="M134.863 106.841C106.383 106.841 83.8259 83.2374 82.8796 82.2327L80.8047 80.028L82.8796 77.8231C83.8259 76.8184 106.383 53.2147 134.863 53.2147C163.344 53.2147 185.901 76.8184 186.847 77.8231L188.922 80.028L186.847 82.2327C185.901 83.2374 163.344 106.841 134.863 106.841ZM89.8822 80.028C96.0648 85.7074 114.073 100.394 134.863 100.394C155.654 100.394 173.676 85.7144 179.845 80.028C173.662 74.3486 155.647 59.6616 134.863 59.6616C114.024 59.6616 96.0578 74.3486 89.8822 80.028Z"
        fill="currentColor"
      />
      <path
        d="M134.864 62.1874C124.679 62.1874 116.47 70.6437 116.954 80.8862C117.382 90.0262 124.84 97.4498 134.03 97.8614C144.313 98.3289 152.802 90.1658 152.802 80.035C152.802 70.1763 144.775 62.1874 134.871 62.1874H134.864ZM134.864 84.8002C132.144 84.8002 129.957 82.5467 130.083 79.8117C130.195 77.3836 132.2 75.3881 134.632 75.2765C137.38 75.1509 139.651 77.3278 139.651 80.035C139.651 82.6654 137.506 84.8002 134.864 84.8002Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
)
