import { graphql } from "msw"

export const updatePlatformAdvertiser = graphql.mutation("UpdatePlatformAdvertiser", (req, res, ctx) => {
  return res(
    ctx.data({
      updatePlatformAdvertiser: {
        clientId: 1,
        platformAdvertiserId: {
          id: "1012768263",
          platform: "DV360",
          __typename: "PlatformAdvertiserId",
        },
        name: "Goodstuff - Subaru",
        platform: "DV360",
        managed: true,
        __typename: "PlatformAdvertiser",
      },
    })
  )
})
