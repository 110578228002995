import React, { createContext, FC, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Flex, useDisclosure } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import Button from "../../../components/Button"
import ErrorFallBack from "../../../components/ErrorFallback"
import { TransferListsData } from "../../../components/forms/TransferLists"
import Modal from "../../../components/Modal"
import UsersTable from "../../../components/tables/UsersTable"
import { UserTableRowData } from "../../../components/tables/UsersTable/UsersTable"
import { MutationUpdateUserRolesArgs, RoleWithUsers, UserProfileWithRoles, UserRoleInput } from "../../../generated/graphql"
import { CREATE_USER, GET_ROLES, GET_USER_PROFILES } from "../../../graphql"
import { UPDATE_USER_ROLES } from "../../../graphql/mutations/updateUserRoles"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import ManageUserRolesForm, { manageUserRolesFormSchema } from "../components/ManageUserRolesForm"
import UserSetupForm, { UserFormValues, userFormValuesSchema } from "../components/UserSetupForm"
import { USERS_TAB_HASH } from "../UserManagement"

type UsersTabProps = {
  user: UserProfile
}

export const UsersTabContext = createContext<TransferListsData | undefined>(undefined)

const UsersTab: FC<UsersTabProps> = ({ user }) => {
  const { hash } = useLocation()

  const [usersData, setUsersData] = useState<UserProfileWithRoles[]>()
  const [clientId, setClientId] = useState<number>()
  const [email, setEmail] = useState<string>()

  const [selectedUser, setSelectedUser] = useState<string>()
  const [allRoles, setAllRoles] = useState<RoleWithUsers[]>([])
  const [availableRoles, setAvailableRoles] = useState<RoleWithUsers[]>([])
  const [selectedRoles, setSelectedRoles] = useState<RoleWithUsers[]>([])
  const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>([])

  const {
    loading: loadingGetUserProfiles,
    error: errorGetUserProfiles,
    data: dataGetUserProfiles,
    refetch: refetchGetUserProfiles,
  } = useQuery(GET_USER_PROFILES, { fetchPolicy: "network-only" })
  const { data: dataGetRoles, refetch: refetchGetRoles } = useQuery(GET_ROLES, { fetchPolicy: "network-only" })
  const [createUser, { loading: createUserLoading, error: createUserError, reset: resetCreateUser }] = useMutation(CREATE_USER, {
    refetchQueries: [GET_USER_PROFILES],
  })
  const [updateUserRoles, { error: updateUserRolesError, reset: resetUpdateUserRoles }] = useMutation(UPDATE_USER_ROLES)

  const { isOpen: createUserFormIsOpen, onClose: createUserFormOnClose, onOpen: createUserFormOnOpen } = useDisclosure()
  const { isOpen: manageUserRolesFormIsOpen, onClose: manageUserRolesOnClose, onOpen: manageUserRolesOnOpen } = useDisclosure()

  const defaultUserFormValues = {
    clientId: Number(user.clientId),
    isSuperUser: false,
    isAdministrator: false,
    email: "",
    firstName: "",
    lastName: "",
  }

  const {
    reset: resetUserForm,
    control: controlUserForm,
    handleSubmit: handleUserFormSubmit,
    watch: watchUserForm,
  } = useForm<UserFormValues>({
    resolver: zodResolver(userFormValuesSchema),
    defaultValues: defaultUserFormValues,
  })

  const defaultManageUserRolesFormValues = {
    clientId: Number(user.clientId),
    userId: undefined,
    roles: [],
  }

  const {
    reset: resetManageUserRolesForm,
    handleSubmit: handleManageUserRolesForm,
    setValue: setManageUserRolesFormValue,
  } = useForm<MutationUpdateUserRolesArgs>({
    resolver: zodResolver(manageUserRolesFormSchema),
    defaultValues: defaultManageUserRolesFormValues,
  })

  const createUserHandler = async (data: UserFormValues) => {
    setClientId(data.clientId)
    setEmail(data.email)

    try {
      await createUser({
        variables: {
          ...data,
        },
      })
      resetUserForm()
      createUserFormOnClose()
    } catch (error) {
      logError(error)
    }
  }

  const closeCreateUserFormHandler = () => {
    resetCreateUser()
    resetUserForm()
    createUserFormOnClose()
  }

  const onManageUserRolesOpenHandler = async (row: UserTableRowData) => {
    const userAssignedRoles = usersData?.find((user: UserProfileWithRoles) => user.userId === row.userId)?.roles as RoleWithUsers[]

    const getRemainingAvailableRoles = () => {
      // get distinct items
      return allRoles.filter((item) => item.clientId === row.clientId).filter(({ roleId: id1 }) => !userAssignedRoles.some(({ roleId: id2 }) => id2 === id1))
    }

    setSelectedRoles(userAssignedRoles)
    setAvailableRoles(getRemainingAvailableRoles())

    setManageUserRolesFormValue("userId", row.userId)
    setManageUserRolesFormValue("clientId", row.clientId)
    setSelectedUser(row.email)

    manageUserRolesOnOpen()
  }

  const closeManageUserRolesFormHandler = async () => {
    manageUserRolesOnClose()
    resetManageUserRolesForm()
    resetUpdateUserRoles()
    await refetchGetRoles()
    await refetchGetUserProfiles()
  }

  const updateUserRolesHandler = async (data: MutationUpdateUserRolesArgs) => {
    await updateUserRoles({
      variables: {
        ...data,
      },
    })

    await closeManageUserRolesFormHandler()
  }

  useEffect(() => {
    if (!selectedRoleIds) {
      setManageUserRolesFormValue("roles", [])
    } else {
      const roleData: UserRoleInput[] = []
      selectedRoleIds.forEach((id) => {
        const role = allRoles.find((r) => r.roleId === id)
        if (!role) return

        roleData.push({
          clientId: role.clientId,
          roleId: role.roleId,
        })
      })
      setManageUserRolesFormValue("roles", roleData)
    }
  }, [allRoles, selectedRoleIds, setManageUserRolesFormValue])

  useEffect(() => {
    if (dataGetRoles) {
      setAllRoles(dataGetRoles.getRoles)
    }
  }, [dataGetRoles])

  useEffect(() => {
    if (dataGetUserProfiles) {
      setUsersData(dataGetUserProfiles.getUserProfiles)
    }
  }, [dataGetUserProfiles])

  useEffect(() => {
    if (hash === USERS_TAB_HASH) {
      ;(async () => {
        await refetchGetUserProfiles()
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  const userIsAdministrator = user.roles.some((role) => role.isAdmin)

  const filteredAvailableRoles = availableRoles.filter((role) => !role.isAdmin || (role.isAdmin && userIsAdministrator))

  const usersTabContextStore: TransferListsData = {
    canMakeAdminChanges: userIsAdministrator && selectedUser === user.email,
    userEmail: user.email,
    available: {
      items: filteredAvailableRoles.map((item) => ({
        name: item.name,
        value: item.roleId,
        isAdmin: item.isAdmin,
      })),
    },
    selected: {
      items: selectedRoles.map((item) => ({
        name: item.name,
        value: item.roleId,
        isAdmin: item.isAdmin,
      })),
      setItems: setSelectedRoleIds,
    },
  }

  const fallbackErrorMessage = `There is a problem with the Users Tab.`
  const fallbackErrorEmailSubject = `Unhandled Error in User Tab`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in the Users Tab when doing...
`

  return (
    <>
      {errorGetUserProfiles ? (
        <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
      ) : (
        <>
          <Flex justify="start" py={3} mb="-3.25rem">
            <Button onClick={createUserFormOnOpen}>Create User</Button>
          </Flex>
          <Modal hasForm isCentered isOpen={createUserFormIsOpen} onClose={closeCreateUserFormHandler} title={"Create New User"} width="container.sm">
            <UserSetupForm
              control={controlUserForm}
              onSubmitHandler={createUserHandler}
              error={createUserError}
              handleSubmit={handleUserFormSubmit}
              submitLoading={createUserLoading}
              watch={watchUserForm}
              user={user}
              fieldsState={{ clientId, email }}
            />
          </Modal>
          <Modal
            hasForm
            isCentered
            isOpen={manageUserRolesFormIsOpen}
            onClose={closeManageUserRolesFormHandler}
            title={`Manage roles for ${selectedUser}`}
            width="container.lg"
          >
            <UsersTabContext.Provider value={usersTabContextStore}>
              <ManageUserRolesForm
                onSubmitHandler={updateUserRolesHandler}
                error={updateUserRolesError}
                selectedUser={selectedUser}
                handleSubmit={handleManageUserRolesForm}
                caller="UsersTab"
              />
            </UsersTabContext.Provider>
          </Modal>
          <UsersTable
            user={user}
            data={usersData}
            isLoading={loadingGetUserProfiles}
            refetchGetUserProfiles={refetchGetUserProfiles}
            onManageRolesOpenHandler={onManageUserRolesOpenHandler}
          />
        </>
      )}
    </>
  )
}

export default UsersTab
