import { Center, Spinner } from "@chakra-ui/react"
import colors from "../../theme/colors"

const Loading = () => (
  <Center pt="28">
    <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color={colors.colors.brand["700"]} size="xl" />
  </Center>
)

export default Loading
