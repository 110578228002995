import { IconBase, IconBaseProps } from "../IconBase"

export const CtrIcon = ({ viewBox = "0 0 223 181", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <g clipPath="url(#clip0_415_1073)">
        <path
          d="M34.3106 0C32.9096 0 31.7714 1.14073 31.7714 2.54471V17.8255C31.7714 19.2294 32.9096 20.3702 34.3106 20.3702C35.7115 20.3702 36.8498 19.2294 36.8498 17.8255V2.54471C36.8498 1.14073 35.7115 0 34.3106 0ZM11.833 9.31387C11.1825 9.31387 10.5446 9.57085 10.0443 10.0723C9.04984 11.0688 9.04984 12.6985 10.0443 13.695L19.019 22.6892C20.0135 23.6858 21.6396 23.6858 22.634 22.6892C23.6284 21.6927 23.6284 20.0631 22.634 19.0665L13.6592 10.0723C13.1651 9.57712 12.4834 9.31387 11.833 9.31387ZM56.7819 9.31387C56.1315 9.31387 55.4498 9.57085 54.9557 10.0723L45.9809 19.0665C44.9865 20.0631 44.9865 21.6927 45.9809 22.6892C46.9753 23.6858 48.6014 23.6858 49.5959 22.6892L58.5706 13.695C59.5651 12.6985 59.5651 11.0688 58.5706 10.0723C58.0766 9.57712 57.4324 9.31387 56.7819 9.31387ZM25.611 25.6664L48.6827 86.5889L55.2747 66.2125L73.2618 84.2009L84.0253 73.4141L66.0757 55.3881L86.4081 48.7819L25.611 25.6664ZM2.53921 31.8339C1.13827 31.8339 0 32.9746 0 34.3786C0 35.7826 1.13827 36.9233 2.53921 36.9233H17.787C19.1879 36.9233 20.3262 35.7826 20.3262 34.3786C20.3262 32.9746 19.1879 31.8339 17.787 31.8339H2.53921ZM20.8453 45.3221C20.1948 45.3221 19.5131 45.5791 19.019 46.0805L10.0443 55.0747C9.04984 56.0713 9.04984 57.7009 10.0443 58.6974C11.0387 59.694 12.6648 59.694 13.6592 58.6974L22.634 49.7032C23.6284 48.7067 23.6284 47.077 22.634 46.0805C22.1399 45.5853 21.4957 45.3221 20.8453 45.3221Z"
          fill="currentColor"
        />
        <path
          d="M174.712 180.179C149.276 180.179 129.125 158.956 128.287 158.048L126.436 156.067L128.287 154.086C129.131 153.184 149.276 131.961 174.712 131.961C200.148 131.961 220.299 153.184 221.143 154.086L222.994 156.067L221.143 158.048C220.299 158.95 200.154 180.179 174.712 180.179ZM134.535 156.067C140.057 161.175 156.143 174.388 174.712 174.388C193.281 174.388 209.373 161.181 214.889 156.073C209.366 150.965 193.281 137.753 174.712 137.753C156.099 137.753 140.051 150.959 134.535 156.067Z"
          fill="currentColor"
        />
        <path
          d="M174.711 140.021C165.611 140.021 158.288 147.624 158.713 156.838C159.094 165.061 165.755 171.73 173.961 172.106C183.148 172.526 190.728 165.186 190.728 156.073C190.728 147.211 183.561 140.021 174.711 140.021ZM174.711 160.36C172.285 160.36 170.327 158.33 170.44 155.873C170.54 153.691 172.328 151.899 174.505 151.792C176.956 151.679 178.989 153.635 178.989 156.073C178.989 158.442 177.075 160.36 174.711 160.36Z"
          fill="currentColor"
        />
        <path d="M176.468 41.6617L40.752 177.672" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_415_1073">
          {/*// TODO Barns: Get width and height from the viewBox*/}
          <rect width="223" height="181" fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  </IconBase>
)
