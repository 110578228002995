import { gql } from "@apollo/client"

export const ASSIGN_USERS_TO_PLATFORM_CAMPAIGN = gql`
  mutation AssignUsersToPlatformCampaign(
    $clientId: Int!
    $platform: Platform!
    $platformIntegrationId: Int!
    $platformCampaignId: ID!
    $users: [RoleUserInput!]
  ) {
    assignUsersToPlatformCampaign(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformCampaignId: $platformCampaignId
      users: $users
    ) {
      success
    }
  }
`
