import { FC } from "react"
import { Skeleton } from "@chakra-ui/react"

const StackedBarsWithLinesGraphLoader: FC = () => (
  <>
    <Skeleton />
  </>
)

export default StackedBarsWithLinesGraphLoader
