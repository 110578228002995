import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { useMutation } from "@apollo/client"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { Platform, PlatformIntegration } from "../../../generated/graphql"
import { DELETE_INTEGRATION } from "../../../graphql/mutations/deleteIntegration"
import usePermissions, { canDelete, canUpdate, canView } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import Button from "../../Button"
import DSPLogoMapper from "../../DSPLogoMapper"
import PageTable from "../PageTable"

interface TableRowData {
  clientId: number
  name: string
  platformIntegrationId: number
  platform: Platform
  state: string
}

const toTableRow = (data: PlatformIntegration[]): TableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    name: v.name ?? "",
  }))

const columnHelper = createColumnHelper<TableRowData>()

interface PlatformIntegrationsTableProps {
  data?: PlatformIntegration[]
  loading: boolean
}

const IntegrationsTable: FC<PlatformIntegrationsTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { platformAdvertiserResourceUri, platformIntegrationResourceUri } = useResourceUris()
  const { permissions } = usePermissions()
  const [integrationToBeDeleted, setIntegrationToBeDeleted] = useState<number | undefined>(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.integrations)

  useEffect(() => {
    if (pageIndex !== paginationSettings.integrations.pageIndex || pageSize !== paginationSettings.integrations.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        integrations: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [deleteIntegration, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE_INTEGRATION)

  const onDeleteIntegration = async () => {
    await deleteIntegration({
      variables: {
        platformIntegrationId: integrationToBeDeleted,
      },
    })
    onClose()
  }

  const onCancelDeleteIntegration = () => {
    onClose()
  }

  const tableColumns = useMemo(() => {
    const onStartDeleteIntegration = (integrationId: number) => {
      setIntegrationToBeDeleted(integrationId)
      onOpen()
    }

    return [
      columnHelper.accessor("clientId", {
        id: "clientId",
        cell: (info) => info.getValue(),
        header: () => <span>Client ID</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "platform",
        cell: ({ row }) => (
          <div className="px-1">
            <DSPLogoMapper platform={row.original.platform} />
          </div>
        ),
        header: () => <span>Platform</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("platformIntegrationId", {
        id: "platformIntegrationId",
        cell: (info) => info.getValue(),
        header: () => <span>Integration Id</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("state", {
        id: "state",
        cell: (info) => info.getValue(),
        header: () => <span>State</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => (
          <HStack>
            {canView(permissions, platformAdvertiserResourceUri) && (
              <Button
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  navigate(RoutePaths.advertisersList.resolve(row.original!.clientId.toString(), row.original!.platformIntegrationId.toString()))
                }
                isInline={true}
              >
                View Advertisers
              </Button>
            )}
            {canUpdate(permissions, platformIntegrationResourceUri) && (
              <Button
                onClick={() => {
                  switch (row.original.platform) {
                    case Platform.Dv360:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupDV360.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                    case Platform.Facebook:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupFacebook.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                    case Platform.Xandr:
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      navigate(RoutePaths.integrationSetupXandr.resolve(row.original!.clientId.toString()), {
                        state: { integrationId: row.original.platformIntegrationId },
                      })
                      break
                  }
                }}
                isInline={true}
              >
                Edit
              </Button>
            )}
            {/*Forcing delete button to be hidden until we have a soft-deletion feature i.e. move state to Inactive*/}
            {canDelete(permissions, platformIntegrationResourceUri) && false && (
              <Button isInline={true} onClick={() => onStartDeleteIntegration(row.original.platformIntegrationId)}>
                Delete
              </Button>
            )}
          </HStack>
        ),
      }),
    ] as ColumnDef<TableRowData, unknown>[]
  }, [navigate, onOpen, permissions, platformIntegrationResourceUri, platformAdvertiserResourceUri])

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as TableRowData[]
  }, [data])

  return (
    <>
      {deleteError ? (
        <div>Error State</div>
      ) : (
        <>
          <PageTable
            pagination={{ pageIndex, pageSize }}
            onPaginationChange={setPagination}
            loading={loading || deleteLoading}
            defaultSort={[{ desc: false, id: "platformIntegrationId" }]}
            hasGlobalFilter={true}
            heightOtherElementsRem={21.05}
            tableColumns={tableColumns}
            tableRows={tableRows}
          />
          <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCancelDeleteIntegration}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Integration
                </AlertDialogHeader>

                <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

                <AlertDialogFooter>
                  <VStack>
                    <Button colorScheme="red" onClick={onDeleteIntegration}>
                      Delete
                    </Button>
                    <Button buttonRef={cancelRef} isInline={true} isSecondary={true} onClick={onCancelDeleteIntegration}>
                      Cancel
                    </Button>
                  </VStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </>
  )
}

export default IntegrationsTable
