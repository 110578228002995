import { IconBase, IconBaseProps } from "./IconBase"

export const ResetIcon = ({ viewBox = "0 0 24 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <path
      d="M6.27016 2.59792C7.99592 1.54661 9.97913 0.993564 11.9999 1.00006C14.9173 1.00006 17.7154 2.1589 19.7783 4.22176C21.8411 6.28463 23 9.08255 23 12C23 14.9174 21.8411 17.7154 19.7783 19.7782C17.7154 21.8411 14.9175 23 12.0001 23C9.08271 23 6.28483 21.8411 4.22199 19.7782C2.67491 18.2311 1 15.5 1 15L2.46776 15C2.83275 16.2961 3.59602 17.3113 4.70183 18.5455C6.3127 20.3435 8.53516 21.4774 10.9366 21.7262C13.338 21.975 15.7458 21.3209 17.6912 19.8914C19.6365 18.4619 20.9801 16.3594 21.46 13.9935C21.9399 11.6275 21.5218 9.16756 20.2872 7.09304C19.0525 5.01834 17.0899 3.47777 14.7814 2.77125C12.4729 2.06474 9.98409 2.24294 7.79988 3.27112C5.61579 4.2995 3.89253 6.10401 2.96604 8.33332H7.11104V9.55555H1V3.44443H2.22221V6.95637C3.14327 5.15774 4.54436 3.64932 6.27016 2.59792Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
  </IconBase>
)
