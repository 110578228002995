import { Global } from "@emotion/react"

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
          font-family: 'DIN Pro';
          src: url('fonts/DINPro-Light.eot');
          src: local('DIN Pro Light'), local('DINPro-Light'),
              url('fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'),
              url('fonts/DINPro-Light.woff2') format('woff2'),
              url('fonts/DINPro-Light.woff') format('woff'),
              url('fonts/DINPro-Light.ttf') format('truetype');
          font-weight: 300;
          font-style: normal;
      }

      /* latin */
      @font-face {
          font-family: 'DIN Pro';
          src: url('fonts/DINPro.eot');
          src: local('DIN Pro'), local('DINPro'),
              url('fonts/DINPro.eot?#iefix') format('embedded-opentype'),
              url('fonts/DINPro.woff2') format('woff2'),
              url('fonts/DINPro.woff') format('woff'),
              url('fonts/DINPro.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
      }
      
      /* latin */
      @font-face {
          font-family: 'DIN Pro';
          src: url('fonts/DINPro-Bold.eot');
          src: local('DIN Pro Bold'), local('DINPro-Bold'),
              url('fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
              url('fonts/DINPro-Bold.woff2') format('woff2'),
              url('fonts/DINPro-Bold.woff') format('woff'),
              url('fonts/DINPro-Bold.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
      }
      
      /* latin */
      @font-face {
          font-family: 'DIN Pro';
          src: url('fonts/DINPro-Black.eot');
          src: local('DIN Pro Black'), local('DINPro-Black'),
              url('fonts/DINPro-Black.eot?#iefix') format('embedded-opentype'),
              url('fonts/DINPro-Black.woff2') format('woff2'),
              url('fonts/DINPro-Black.woff') format('woff'),
              url('fonts/DINPro-Black.ttf') format('truetype');
          font-weight: 900;
          font-style: normal;
      }
      
      /* latin */
      @font-face {
          font-family: 'DIN Pro';
          src: url('fonts/DINPro-Medium.eot');
          src: local('DIN Pro Medium'), local('DINPro-Medium'),
              url('fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
              url('fonts/DINPro-Medium.woff2') format('woff2'),
              url('fonts/DINPro-Medium.woff') format('woff'),
              url('fonts/DINPro-Medium.ttf') format('truetype');
          font-weight: 500;
          font-style: normal;
      }
      `}
  />
)

export default Fonts
