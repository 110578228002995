import React, { FC } from "react"
import { ButtonGroup, HStack } from "@chakra-ui/react"
import Button, { ButtonProps } from "../Button"

interface WizardNavigationButtonProps extends ButtonProps {
  shown?: boolean
  text?: string
}

interface WizardNavigateSaveButtonProps {
  save?: WizardNavigationButtonProps
}

const WizardNavigateSaveButton: FC<WizardNavigateSaveButtonProps> = ({ save = {} }) => {
  const saveText = save?.text || "Save"

  const { shown: saveShown, ...saveProps } = save

  return (
    <>
      {(saveShown ?? true) && (
        <HStack justify={"start"} py={3}>
          <ButtonGroup>
            <Button {...saveProps}>{saveText}</Button>
          </ButtonGroup>
        </HStack>
      )}
    </>
  )
}

export default WizardNavigateSaveButton
