import { IconBase, IconBaseProps } from "../icons/IconBase"

export const DV360Logo = ({ viewBox = "0 0 99 108", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M77.5578 42.2241C83.8616 38.3767 92.0722 40.396 95.8943 46.7343C99.7209 53.0726 97.7125 61.3279 91.4086 65.171C91.2405 65.2733 91.0724 65.3711 90.9043 65.4645L21.3582 105.732C15.0543 109.579 6.84382 107.56 3.01726 101.221C-0.809306 94.8831 1.19909 86.6277 7.50296 82.7803C7.67106 82.678 7.83916 82.5801 8.00727 82.4867L77.5578 42.2241Z"
        fill="#81C995"
      />
      <path
        d="M27.5869 13.5083C27.6178 6.11145 21.6767 0.0845108 14.32 0.0533754C13.3733 0.0489275 12.431 0.146782 11.502 0.34249C5.26895 1.88147 0.911535 7.53923 1.00001 13.9976V93.9757L27.5603 94.4605L27.5869 13.5083Z"
        fill="#34A853"
      />
      <path
        d="M77.8586 65.5135C84.2863 68.8627 92.196 66.7188 96.0801 60.5718C99.8138 54.2558 97.7479 46.0894 91.4661 42.3353C91.4175 42.3086 91.3732 42.2819 91.3246 42.2508L21.314 1.9126C15.0809 -1.87258 6.97211 0.137884 3.20306 6.40501C3.11016 6.56068 3.01726 6.72081 2.92878 6.88093C-0.769488 13.2459 1.36277 21.4256 7.69318 25.1486C7.73742 25.1752 7.78166 25.1975 7.8259 25.2242L77.8586 65.5135Z"
        fill="#5BB974"
      />
      <path
        d="M90.8645 65.6069L27.5648 101.159V70.6197L77.7922 42.4599C84.45 38.7503 92.8419 41.17 96.5313 47.8685C96.8764 48.4912 97.1684 49.1406 97.4117 49.8078C99.2962 55.9682 96.5446 62.609 90.8645 65.6069Z"
        fill="#81C995"
      />
      <path
        d="M14.3067 107.996C21.6411 107.996 27.5869 102.017 27.5869 94.6429C27.5869 87.2684 21.6411 81.2902 14.3067 81.2902C6.97228 81.2902 1.02655 87.2684 1.02655 94.6429C1.02655 102.017 6.97228 107.996 14.3067 107.996Z"
        fill="#1E8E3E"
      />
    </g>
  </IconBase>
)
