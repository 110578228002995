import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import TextInputUI, { TextInputUIProps } from "./TextInputUI"

interface TextInputProps extends Omit<TextInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const TextInput: FC<TextInputProps> = ({
  placeholder = "",
  control,
  isTextArea = false,
  name,
  helperText = "",
  tooltip = "",
  label,
  isDisabled = false,
  size = "sm",
  width,
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <TextInputUI //
      placeholder={placeholder}
      field={field}
      name={name}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
      width={width}
      isTextArea={isTextArea}
    />
  )
}

export default TextInput
