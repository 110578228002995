import React, { FC, useEffect } from "react"
import { ApolloProvider } from "@apollo/client"
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter } from "react-router-dom"
import { RecoilRoot } from "recoil"
import ApolloClient from "./ApolloClient"
import AppRoutes from "./AppRoutes"
import Env, { EnvKey } from "./Env"
import useAuth from "./hooks/useAuth"
import useAuthLifecycle from "./hooks/useAuthLifecycle"
import usePermissionsLifecycle from "./hooks/usePermissionsLifecycle"
import { log } from "./log"
import MaintenanceMode from "./MaintenanceMode"
import theme from "./theme"
import Fonts from "./theme/fonts"

const maintenanceHours = Number(Env.requireString(EnvKey.MaintenanceHours))

const AppContainer: FC = () => (
  <div className="App">
    <ChakraProvider theme={theme}>
      <Fonts />
      {maintenanceHours > 0 ? (
        <MaintenanceMode maintenanceHours={maintenanceHours} />
      ) : (
        <ApolloProvider client={ApolloClient}>
          <RecoilRoot>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </RecoilRoot>
        </ApolloProvider>
      )}
    </ChakraProvider>
  </div>
)

const App: FC = () => {
  // Register a lifecycle listener to determine the user's auth status and keep their profile up to date
  useAuthLifecycle()
  usePermissionsLifecycle()

  const { user, authStage } = useAuth()

  useEffect(() => {
    log("User updated", authStage, user)
  }, [user, authStage])

  return <AppRoutes />
}

export default AppContainer
