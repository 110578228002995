import { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { Tab, TabList, Tabs, TabPanel, TabPanels, Text, Tooltip, HStack } from "@chakra-ui/react"
import { MetricName } from "../../../generated/graphql"
import { QRTZtmIcon, ResetIcon } from "../../../icons"
import { TileProps } from "../../../layouts/TilesGrid"
import GridState from "../../../layouts/TilesGrid/GridState"
import CampaignDashboardStateController from "../../../pages/CampaignDashboardPage/types"
import colors from "../../../theme/colors"
import { StringKeyObject } from "../../../types"
import TileTable from "../../tables/TileTable"
import Tile from "../../Tile"
import { ItemisedMetricDataSet, SegmentType } from "../types"
import { extractPixelNamesFromPixels, mapMetricNameToDisplayName } from "../utils"

type TableData = {
  columns: { header: () => JSX.Element; accessorKey: string }[]
  data: StringKeyObject<string | number>[]
}

type TabProps = {
  title: string
  tableData: ItemisedMetricDataSet[]
}

const TablesInTabs: FC<TileProps<CampaignDashboardStateController, GridState, StringKeyObject<TabProps>>> = ({
  dashboardController,
  tileData,
  queryDetails,
}) => {
  const { campaignDashboardState, onResetSelectedSegments, onSelectedSegmentsTableChange, onSegmentsTabChange } = dashboardController
  const { selectedSegmentsTab, selectedCreativeIds, selectedLineItemIds } = campaignDashboardState

  const tabNames = Object.keys(tileData)

  const tablesData: StringKeyObject<TableData> = {}

  tabNames.forEach((tabName) => {
    const tableData = tileData[tabName].tableData

    const data = tableData.map((row, index) => {
      const rowObject: StringKeyObject<string | number> = {}

      rowObject["id"] = index + 1
      rowObject["itemId"] = row.itemId ?? ""
      rowObject["itemName"] = row.itemName ?? ""

      row.metricDataSets.forEach((metricDataSet, index) => {
        rowObject[`${metricDataSet.name}_${index}`] = metricDataSet.data.length ? metricDataSet.data[0].formattedValue : "No Data"
      })

      return rowObject
    })

    const metricsColumns = tableData[0].metricDataSets.map((metricDataSet, index) => ({
      header: () => {
        const { name, pixels, isKpi } = metricDataSet
        const pixelNames = extractPixelNamesFromPixels(pixels)
        const displayPixelNameAsHeader = name === MetricName.Conversions && pixels?.length === 1
        return (
          <HStack>
            {isKpi && <QRTZtmIcon width="1rem" height="1rem" color={colors.colors.white} />}
            <Text as="b" color="brand.400">
              {displayPixelNameAsHeader ? pixelNames : mapMetricNameToDisplayName[name]}
            </Text>
            {!displayPixelNameAsHeader && pixelNames.length > 0 && (
              <Tooltip aria-label={pixelNames} label={pixelNames} placement="right-start">
                <InfoIcon color={"brand.400"} w={4} h={4} display="flex" alignSelf="flex-start" />
              </Tooltip>
            )}
          </HStack>
        )
      },
      accessorKey: `${metricDataSet.name}_${index}`,
    }))

    const columns = [
      {
        header: () => <span>ID</span>,
        accessorKey: "itemId",
      },
      {
        header: () => <span>NAME</span>,
        accessorKey: "itemName",
      },
    ]
      .concat(metricsColumns)
      .sort()

    if (columns && data) {
      tablesData[tabName] = {
        columns,
        data,
      }
    }
  })

  const hasItemsSelected = selectedCreativeIds.length > 0 || selectedLineItemIds.length > 0
  const resetIconColor = hasItemsSelected ? colors.colors.brand["400"] : colors.colors.brand["200"]

  return (
    <Tile error={queryDetails?.error} overflowX="hidden">
      {tablesData && (
        <>
          <Tabs onChange={onSegmentsTabChange} index={selectedSegmentsTab} w="100%" overflowY="hidden">
            <TabList>
              {tabNames.map((tabName) => (
                <Tab key={tabName} mr={2}>
                  <Text as="b" fontSize="2rem">
                    {tileData?.[tabName].title}
                  </Text>
                </Tab>
              ))}
            </TabList>
            <TabPanels overflowX="hidden" pr="1px">
              {tabNames.map((tabName, index) => {
                const tableData = tablesData[tabName]
                if (tableData?.columns?.length > 0 && tableData?.data?.length > 0) {
                  const tableRows = tableData.data
                  const selectedItems = tabName === SegmentType.Creatives ? selectedCreativeIds : selectedLineItemIds
                  const rowSelection = selectedItems.reduce((result, item) => {
                    const indexOfSelectedItem = tableRows.findIndex(
                      (row) =>
                        (
                          row as {
                            itemId: string
                          }
                        ).itemId === item
                    )
                    result[indexOfSelectedItem] = true
                    return result
                  }, {} as StringKeyObject<boolean>)

                  return (
                    <TabPanel key={tabName} px={0} pt={2} overflow="hidden" height="27.15rem">
                      <TileTable<(typeof tableData.data)[0]>
                        dataType={tabName as SegmentType}
                        loading={false}
                        rowSelection={rowSelection}
                        onSelectedRowsChange={onSelectedSegmentsTableChange}
                        tableColumns={tableData.columns}
                        tableRows={tableRows}
                      />
                    </TabPanel>
                  )
                }
                return <></>
              })}
            </TabPanels>
          </Tabs>
          <ResetIcon
            color={resetIconColor}
            cursor="pointer"
            onClick={async () => {
              onResetSelectedSegments()
            }}
          />
        </>
      )}
    </Tile>
  )
}

export default TablesInTabs
