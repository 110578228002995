import { IconBase, IconBaseProps } from "../icons/IconBase"

export const XandrLogo = ({ viewBox = "0 0 102 92", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.308 3.83701C22.5443 4.58277 21.8868 5.43004 21.354 6.35501L0 43.459H46.112L23.308 3.83701Z"
        fill="#E10500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74 0.437001C72.9656 0.146819 71.8963 -0.000215917 70.822 2.37976e-07H31.178C30.088 2.37976e-07 29.021 0.150001 28 0.437001L51 40L74 0.437001Z"
        fill="#F73232"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 49L21.302 86.458C21.842 87.405 22.5 88.257 23.252 89L46 49H0Z" fill="#FC5047" />
      <path fillRule="evenodd" clipRule="evenodd" d="M80.698 86.458L102 49H56L78.75 89C79.5125 88.2457 80.1679 87.3904 80.698 86.458" fill="#FF7061" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 91.563C29.021 91.85 30.089 92 31.178 92H70.822C71.912 92 72.979 91.85 74 91.563L51 52L28 91.563Z"
        fill="#FC8D7E"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M102 43L80.698 5.542C80.167 4.60965 79.511 3.75438 78.748 3L56 43H102Z" fill="#FC5047" />
    </g>
  </IconBase>
)
