export const convertPercentageToDecimalPercentage = (value?: string) => {
  if (value === undefined) return value

  return (Number(value) / 100).toString()
}

type DecimalPercentageToPercentageProps = {
  value?: number
  precision?: number
}

export const convertDecimalPercentageToPercentage = ({ value, precision = 4 }: DecimalPercentageToPercentageProps): string | undefined => {
  if (value === undefined) return value

  return Number((value * 100.0).toPrecision(precision)).toString()
}

type RoundToUpToDecimalPlaces = {
  value: number
  decimalPlaces?: number
}
export const roundToUpToDecimals = ({ value, decimalPlaces = 4 }: RoundToUpToDecimalPlaces) => {
  return Math.round((value + Number.EPSILON) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
}
