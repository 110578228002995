import React, { FC } from "react"
import { ButtonGroup, HStack } from "@chakra-ui/react"
import Button, { ButtonProps } from "../Button"

interface WizardNavigationButtonProps extends ButtonProps {
  shown?: boolean
  text?: string
}

interface WizardNavigationButtonsProps {
  next?: WizardNavigationButtonProps
}

const WizardNavigateNextButton: FC<WizardNavigationButtonsProps> = ({ next = {} }) => {
  const nextText = next?.text || "Next"

  const { shown: nextShown, ...nextProps } = next

  return (
    <>
      {(nextShown ?? true) && (
        <HStack justify={"start"} py={3}>
          <ButtonGroup>
            <Button {...nextProps}>{nextText}</Button>
          </ButtonGroup>
        </HStack>
      )}
    </>
  )
}

export default WizardNavigateNextButton
