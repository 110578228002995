import React, { FC } from "react"
import { ApolloError } from "@apollo/client"
import { HStack } from "@chakra-ui/react"
import { UseFormHandleSubmit } from "react-hook-form"
import { z } from "zod"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import TransferLists from "../../../components/forms/TransferLists"
import { MutationUpdateUserRolesArgs } from "../../../generated/graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { CustomGraphQLError } from "../../../types"

export const manageUserRolesFormSchema = z.object({
  clientId: z.number(),
  userId: z.string(),
  roles: z.array(
    z.object({
      clientId: z.number(),
      roleId: z.number(),
    })
  ),
})

type ManageUserRolesFormProps = {
  onSubmitHandler: (data: MutationUpdateUserRolesArgs) => void
  error?: ApolloError
  selectedUser?: string
  handleSubmit: UseFormHandleSubmit<MutationUpdateUserRolesArgs>
  caller: string
}

const ManageUserRolesForm: FC<ManageUserRolesFormProps> = ({ handleSubmit, onSubmitHandler, error, selectedUser, caller }) => {
  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <TransferLists caller={caller} />
      <HStack>
        <Button type="submit">Save</Button>
        <ErrorText
          height={12}
          message={
            errorType
              ? mapErrorTypeToMessage({
                  errorType,
                  email: selectedUser,
                })
              : ""
          }
          size="small"
        />
      </HStack>
    </form>
  )
}

export default ManageUserRolesForm
