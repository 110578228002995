import { IconBase, IconBaseProps } from "../IconBase"

export const CpmEurIcon = ({ viewBox = "0 0 208 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_502_772)">
        <path
          d="M143.312 44.5701C143.312 69.7336 122.864 90.1523 97.65 90.1523C72.4365 90.1523 52 69.7336 52 44.5701C52 19.4067 72.4365 -1 97.65 -1C122.864 -1 143.312 19.4007 143.312 44.5701ZM113.351 26.9047L117.696 18.273C103.136 10.499 85.0372 15.9576 77.2496 30.4738C76.2041 32.3873 75.3929 34.4088 74.822 36.5022H68.1701L65.1296 42.5307H73.7765C73.6863 43.8623 73.6743 45.194 73.7765 46.5256H68.1761L65.1356 52.5541H74.786C79.2086 68.4199 95.6851 77.7115 111.573 73.2967C113.688 72.7268 115.755 71.905 117.702 70.8613L113.357 62.2355C112.757 62.5655 112.168 62.8474 111.573 63.0993C101.34 67.5082 89.4178 62.7874 85.0132 52.5541H100.444L103.467 46.5256H83.4629C83.3307 45.206 83.3307 43.8623 83.4629 42.5307H105.468L108.496 36.5022H85.0372C85.3076 35.9384 85.572 35.3685 85.8725 34.8167C91.2685 25.0632 103.575 21.5241 113.345 26.9047H113.351Z"
          fill="currentColor"
        />
        <path d="M185.772 109.675L10.4795 109.521" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
        <path
          d="M26.1971 188.139V151.538H12V142.912C13.9883 142.965 15.9113 142.817 17.7691 142.467C19.6209 142.117 21.2887 141.5 22.7666 140.616C24.2444 139.726 25.4968 138.563 26.5176 137.109C27.5385 135.656 28.2092 133.858 28.5356 131.71H37.6522V188.145H26.1971V188.139Z"
          fill="currentColor"
        />
        <path
          d="M53.861 145.379C54.9767 141.622 56.4823 138.6 58.3838 136.331C60.2853 134.055 62.4987 132.437 65.03 131.463C67.5553 130.49 70.2306 130 73.0558 130C75.881 130 78.6403 130.49 81.1656 131.463C83.6909 132.437 85.9223 134.061 87.8478 136.331C89.7732 138.6 91.2968 141.622 92.4125 145.379C93.5282 149.141 94.0861 153.776 94.0861 159.294C94.0861 164.812 93.5282 169.722 92.4125 173.538C91.2968 177.354 89.7792 180.394 87.8478 182.669C85.9163 184.945 83.6909 186.563 81.1656 187.537C78.6403 188.51 75.935 189 73.0558 189C70.1766 189 67.5553 188.51 65.03 187.537C62.5047 186.563 60.2853 184.939 58.3838 182.669C56.4823 180.4 54.9707 177.354 53.861 173.538C52.7453 169.722 52.1875 164.974 52.1875 159.294C52.1875 153.614 52.7453 149.135 53.861 145.379ZM63.9263 165.183C64.0343 167.482 64.3762 169.704 64.946 171.836C65.5159 173.974 66.4276 175.801 67.6753 177.312C68.9229 178.829 70.7164 179.582 73.0558 179.582C75.3952 179.582 77.2786 178.823 78.5563 177.312C79.8339 175.795 80.7577 173.974 81.3275 171.836C81.8974 169.698 82.2393 167.482 82.3473 165.183C82.4552 162.883 82.5092 160.924 82.5092 159.3C82.5092 158.326 82.4972 157.15 82.4672 155.77C82.4372 154.391 82.3293 152.969 82.1433 151.512C81.9514 150.049 81.6814 148.604 81.3275 147.17C80.9736 145.737 80.4458 144.453 79.738 143.312C79.0302 142.177 78.1364 141.258 77.0507 140.553C75.965 139.848 74.6334 139.496 73.0558 139.496C71.4782 139.496 70.1646 139.848 69.1029 140.553C68.0412 141.258 67.1714 142.177 66.4936 143.312C65.8158 144.447 65.2819 145.737 64.904 147.17C64.5261 148.604 64.2502 150.049 64.0882 151.512C63.9263 152.975 63.8303 154.391 63.8003 155.77C63.7703 157.15 63.7583 158.326 63.7583 159.3C63.7583 160.924 63.8123 162.883 63.9203 165.183H63.9263Z"
          fill="currentColor"
        />
        <path
          d="M99.1814 145.379C100.297 141.622 101.803 138.6 103.704 136.331C105.606 134.055 107.819 132.437 110.35 131.463C112.876 130.49 115.551 130 118.376 130C121.201 130 123.961 130.49 126.486 131.463C129.011 132.437 131.243 134.061 133.168 136.331C135.094 138.6 136.617 141.622 137.733 145.379C138.849 149.141 139.406 153.776 139.406 159.294C139.406 164.812 138.849 169.722 137.733 173.538C136.617 177.354 135.1 180.394 133.168 182.669C131.237 184.945 129.011 186.563 126.486 187.537C123.961 188.51 121.255 189 118.376 189C115.497 189 112.876 188.51 110.35 187.537C107.825 186.563 105.606 184.939 103.704 182.669C101.803 180.4 100.291 177.354 99.1814 173.538C98.0657 169.722 97.5078 164.974 97.5078 159.294C97.5078 153.614 98.0657 149.135 99.1814 145.379ZM109.247 165.183C109.355 167.482 109.696 169.704 110.266 171.836C110.836 173.974 111.748 175.801 112.996 177.312C114.243 178.829 116.037 179.582 118.376 179.582C120.715 179.582 122.599 178.823 123.877 177.312C125.154 175.795 126.078 173.974 126.648 171.836C127.218 169.698 127.56 167.482 127.668 165.183C127.776 162.883 127.83 160.924 127.83 159.3C127.83 158.326 127.818 157.15 127.788 155.77C127.758 154.391 127.65 152.969 127.464 151.512C127.272 150.049 127.002 148.604 126.648 147.17C126.294 145.737 125.766 144.453 125.058 143.312C124.35 142.177 123.457 141.258 122.371 140.553C121.285 139.848 119.954 139.496 118.376 139.496C116.799 139.496 115.485 139.848 114.423 140.553C113.361 141.258 112.492 142.177 111.814 143.312C111.136 144.447 110.602 145.737 110.224 147.17C109.846 148.604 109.571 150.049 109.409 151.512C109.247 152.975 109.151 154.391 109.121 155.77C109.091 157.15 109.079 158.326 109.079 159.3C109.079 160.924 109.133 162.883 109.241 165.183H109.247Z"
          fill="currentColor"
        />
        <path
          d="M143.644 145.379C144.76 141.622 146.266 138.6 148.167 136.331C150.068 134.055 152.282 132.437 154.813 131.463C157.339 130.49 160.014 130 162.839 130C165.664 130 168.423 130.49 170.949 131.463C173.474 132.437 175.705 134.061 177.631 136.331C179.556 138.6 181.08 141.622 182.196 145.379C183.311 149.141 183.869 153.776 183.869 159.294C183.869 164.812 183.311 169.722 182.196 173.538C181.08 177.354 179.562 180.394 177.631 182.669C175.699 184.945 173.474 186.563 170.949 187.537C168.423 188.51 165.718 189 162.839 189C159.96 189 157.339 188.51 154.813 187.537C152.288 186.563 150.068 184.939 148.167 182.669C146.266 180.4 144.754 177.354 143.644 173.538C142.529 169.722 141.971 164.974 141.971 159.294C141.971 153.614 142.529 149.135 143.644 145.379ZM153.709 165.183C153.817 167.482 154.159 169.704 154.729 171.836C155.299 173.974 156.211 175.801 157.458 177.312C158.706 178.829 160.5 179.582 162.839 179.582C165.178 179.582 167.062 178.823 168.339 177.312C169.617 175.795 170.541 173.974 171.111 171.836C171.681 169.698 172.022 167.482 172.13 165.183C172.238 162.883 172.292 160.924 172.292 159.3C172.292 158.326 172.28 157.15 172.25 155.77C172.22 154.391 172.112 152.969 171.927 151.512C171.735 150.049 171.465 148.604 171.111 147.17C170.757 145.737 170.229 144.453 169.521 143.312C168.813 142.177 167.92 141.258 166.834 140.553C165.748 139.848 164.417 139.496 162.839 139.496C161.261 139.496 159.948 139.848 158.886 140.553C157.824 141.258 156.955 142.177 156.277 143.312C155.599 144.447 155.065 145.737 154.687 147.17C154.309 148.604 154.033 150.049 153.871 151.512C153.709 152.975 153.614 154.391 153.584 155.77C153.554 157.15 153.542 158.326 153.542 159.3C153.542 160.924 153.596 162.883 153.703 165.183H153.709Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_502_772">
          <rect width="208" height="200" fill="white" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
