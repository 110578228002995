import React, { FC, useMemo, useState } from "react"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { ErrorBoundary } from "react-error-boundary"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import { GET_PLATFORM_INTEGRATION } from "../../graphql"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canCreate, canUpdate } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import WizardLayout, { WizardStep } from "../../layouts/Wizard/WizardLayout"
import { logError, logUnhandledError } from "../../log"
import { Optional } from "../../types"
import AuthenticatedProps from "../AuthenticatedProps"
import IntegrationDetailsForm from "./components/IntegrationDetailsForm"
import IntegrationSetupCompleteBlock from "./components/IntegrationSetupCompleteBlock"
import IntegrationVerifyBlock from "./components/IntegrationVerifyBlock"
import XandrSetupData from "./components/XandrSetupData"

const CREATE_XANDR_INTEGRATION = gql`
  mutation createXandrPlatformIntegration($clientId: Int!, $name: String!, $username: String!, $password: String!) {
    createXandrPlatformIntegration(clientId: $clientId, name: $name, username: $username, password: $password) {
      platformIntegrationId
    }
  }
`

const UPDATE_XANDR_INTEGRATION = gql`
  mutation updateXandrPlatformIntegration($clientId: Int!, $name: String!, $platformIntegrationId: Int!, $username: String!, $password: String!) {
    updateXandrPlatformIntegration(clientId: $clientId, name: $name, platformIntegrationId: $platformIntegrationId, username: $username, password: $password) {
      platformIntegrationId
    }
  }
`

type IntegrationSetupXandrPageProps = AuthenticatedProps

const IntegrationSetupXandrPage: FC<IntegrationSetupXandrPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformIntegrationResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()
  const navigate = useNavigate()
  const { clientId } = useParams()
  if (!clientId) throw Error("Expected clientId in parameters")

  const { state } = useLocation()
  const stateIntegrationId = (state as { integrationId?: string })?.integrationId

  const [platformIntegrationId, setPlatformIntegrationId] = useState<Optional<string>>(stateIntegrationId)

  const breadcrumbItems: BreadcrumbItemProps[] = [
    {
      text: "Integration Setup",
      onClick: () => navigate(RoutePaths.integrationSetupIndex.resolve(clientId)),
    },
    { text: "Xandr" },
  ]

  const [createIntegration, createIntegrationQueryDetails] = useMutation(CREATE_XANDR_INTEGRATION)
  const [updateIntegration, updateIntegrationQueryDetails] = useMutation(UPDATE_XANDR_INTEGRATION)
  const [getIntegration, getIntegrationQueryDetails] = useLazyQuery(GET_PLATFORM_INTEGRATION)
  const { loading, called, error, data } = getIntegrationQueryDetails

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onIntegrationDetailsSubmit = async (data: any) => {
    const { name, username, password } = data
    if (stateIntegrationId) {
      const result = await updateIntegration({
        variables: {
          clientId: Number(clientId),
          name,
          platformIntegrationId,
          username,
          password,
        },
      })
      setPlatformIntegrationId(result.data.updateXandrPlatformIntegration.platformIntegrationId)
    } else {
      const result = await createIntegration({
        variables: {
          clientId: Number(clientId),
          name,
          username,
          password,
        },
      })
      setPlatformIntegrationId(result.data.createXandrPlatformIntegration.platformIntegrationId)
    }
  }

  const getExistingIntegration = () => {
    setPlatformIntegrationId(platformIntegrationId)
    return getIntegration({
      variables: { clientId: Number(clientId), platformIntegrationId },
      fetchPolicy: "no-cache",
    })
  }

  const formTitle = `${stateIntegrationId ? "Update" : "New"} Integration Details`

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: WizardStep<XandrSetupData, any, any>[] = [
    {
      title: formTitle,
      component: IntegrationDetailsForm,
      props: {
        onSubmit: onIntegrationDetailsSubmit,
        queryDetails: platformIntegrationId ? updateIntegrationQueryDetails : createIntegrationQueryDetails,
      },
    },
    {
      title: "Verify Integration",
      component: IntegrationVerifyBlock,
      props: { getIntegrationState: getExistingIntegration, queryDetails: getIntegrationQueryDetails },
    },
    { title: "Summary", component: IntegrationSetupCompleteBlock },
  ]

  if (stateIntegrationId && called === false) {
    getExistingIntegration().then()
  }

  const defaultWizardData: XandrSetupData = useMemo(() => {
    if (error) {
      logError(error)
    }
    if (data !== undefined) {
      return [
        {
          name: data.getPlatformIntegration.name,
          username: undefined,
          password: undefined,
        },
        {
          success: false,
        },
        {},
      ]
    } else {
      return [
        {
          name: undefined,
          username: undefined,
          password: undefined,
        },
        {
          success: false,
        },
        {},
      ]
    }
  }, [data, error])

  const pageTitle = `${stateIntegrationId ? "Update" : "Setup"} Xandr Integration`

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && (canCreate(permissions, platformIntegrationResourceUri) || canUpdate(permissions, platformIntegrationResourceUri)) && (
        <>
          {((called && !loading) || stateIntegrationId === undefined) && (
            <ErrorBoundary
              fallback={
                <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
              }
              onError={logUnhandledError}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
              <WizardLayout steps={steps} initialData={defaultWizardData} onComplete={() => {}} />
            </ErrorBoundary>
          )}
        </>
      )}
      {!permissionsLoading && !canCreate(permissions, platformIntegrationResourceUri) && !canUpdate(permissions, platformIntegrationResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default IntegrationSetupXandrPage
