import React, { FC, PropsWithChildren } from "react"
import { ApolloError } from "@apollo/client"
import { Box, Grid, GridItem } from "@chakra-ui/react"
import colors from "../../theme/colors"
import { StringKeyObject } from "../../types"

export type TileProps<DC extends object, GS extends object, TD extends object> = {
  dashboardController: DC
  gridState: GS // This will be the custom layout properties
  tileData: TD
  queryDetails: { loading?: boolean; error?: ApolloError }
}

export type TileComponent<DC extends object, GS extends object, TD extends object, T extends TileProps<DC, GS, TD>> = {
  component: FC<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: StringKeyObject<any>
}

export type TilesGridLayoutProps<DC extends object, GS extends object> = {
  tiles: StringKeyObject<TileComponent<DC, GS, object, TileProps<DC, GS, object>>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TilesGridLayout = <DC extends StringKeyObject<any>, GS extends StringKeyObject<any>>({
  tiles,
}: PropsWithChildren<TilesGridLayoutProps<DC, GS>>) => {
  const tileNames = Object.keys(tiles)

  return (
    <Box
      overflowY="scroll"
      overflowX="hidden"
      h="100%"
      sx={{
        paddingRight: "10px",
        width: "fit-content",
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: colors.colors.brand["700"],
          // marginTop: "24px",
        },
        "::-webkit-scrollbar-thumb": {
          background: colors.colors.brand["200"],
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Grid
        width="container.xl"
        maxWidth="container.xl"
        height="1272px"
        columnGap="20px"
        rowGap="20px"
        templateAreas={`
        "trends trends trends trends trends"
        "drillDown metrics metrics metrics metrics"
        "drillDown graphs graphs graphs graphs"
        "tables tables tables tables tables"
      `}
        templateColumns="repeat(5, minmax(0, 1fr))"
        templateRows="224px repeat(16, 1fr)"
      >
        {tileNames.map((tileName) => (
          <GridItem key={tileName} area={tileName}>
            {React.createElement(tiles[tileName].component, {
              dashboardController: tiles[tileName].props?.dashboardController,
              gridState: tiles[tileName].props?.gridState,
              queryDetails: tiles[tileName].props?.queryDetails,
              tileData: tiles[tileName].props?.tileData,
            })}
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}
