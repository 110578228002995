import React, { FC, useEffect, useMemo } from "react"
import { ApolloError, useLazyQuery } from "@apollo/client"
import { Box, Button as ChakraButton, FormLabel, HStack, Text } from "@chakra-ui/react"
import { Action, ResourceActions, ResourceType } from "@fifty9a/utils-api-auth"
import { Control, FormState, UseFormHandleSubmit, UseFormSetValue } from "react-hook-form"
import { z } from "zod"
import Button from "../../../components/Button"
import CheckboxGroupInput from "../../../components/forms/CheckboxGroupInput"
import { CheckboxMetaData } from "../../../components/forms/CheckboxGroupInput/CheckboxGroupInputUI"
import ErrorText from "../../../components/forms/ErrorText"
import SelectInput from "../../../components/forms/SelectInput"
import TextInput from "../../../components/forms/TextInput"
import Loading from "../../../components/Loading"
import { Client as GraphqlClient, ResourceActions as GraphqlResourceActions } from "../../../generated/graphql"
import { GET_CLIENTS } from "../../../graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { CustomGraphQLError, KebabToCamelCase } from "../../../types"
import { kebabToCamelCase, toTitleCase } from "../../../utils/stringUtils"
import { is59AClient } from "../UserManagement"

export type Permissions = { [key in KebabToCamelCase<ResourceType>]: Action[] }

export interface RoleFormValues {
  clientId: number
  roleId?: number
  name?: string
  description?: string
  permissions?: Permissions
}

export const roleFormSchema = z.object({
  clientId: z.number(),
  roleId: z.number().optional(),
  name: z.string({ required_error: "Please provide a name for the role" }).max(50, "Name cannot exceed 50 characters.").trim(),
  description: z
    .string({ required_error: "Please provide a description for the role" })
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description cannot exceed 500 characters.")
    .trim(),
  permissions: z.object({
    client: z.array(z.string()),
    user: z.array(z.string()),
    platformIntegration: z.array(z.string()),
    platformAdvertiser: z.array(z.string()),
    platformCampaign: z.array(z.string()),
  }),
})

type RoleSetupFormProps = {
  onSubmitHandler: (data: RoleFormValues) => void
  error?: ApolloError
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<RoleFormValues, any>
  formState: FormState<RoleFormValues>
  handleSubmit: UseFormHandleSubmit<RoleFormValues>
  submitLoading?: boolean
  roleName?: string
  watchClientId: number
  user: UserProfile
  setValue: UseFormSetValue<RoleFormValues>
  isCreateRole: boolean
}

type PermissionCheckboxData = {
  label: string | null
  name: string
  options: CheckboxMetaData[]
}

const permissionsCheckboxes: PermissionCheckboxData[] = Object.keys(ResourceActions).map((resource) => {
  const actions = ResourceActions[resource as ResourceType]
  const checkboxes: CheckboxMetaData[] = actions.map((action) => ({
    label: toTitleCase(action) ?? "",
    value: action,
  }))

  return {
    label: toTitleCase(resource.replace("-", " ")),
    name: kebabToCamelCase(resource),
    options: checkboxes,
  }
})

const permissionGroupOrder: KebabToCamelCase<ResourceType>[] = ["client", "platformIntegration", "platformAdvertiser", "platformCampaign", "user"]

const putViewPermissionFirst = (options: CheckboxMetaData[]) => {
  const first = GraphqlResourceActions.View
  return options.sort((x, y) => {
    return x.value === first ? -1 : y.value === first ? 1 : 0
  })
}

const RoleSetupForm: FC<RoleSetupFormProps> = (props) => {
  const { error, onSubmitHandler, submitLoading, roleName = "", watchClientId, user, handleSubmit, formState, control, setValue, isCreateRole } = props
  const [getClients, { called: clientsCalled, loading: clientsLoading, error: clientsError, data: clientsData }] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (!clientsCalled && user.is59A) {
      getClients()
    }
  }, [clientsCalled, user, getClients])

  const clientOptions: { label: string; value: string }[] = useMemo(() => {
    if (clientsError) {
      logError(clientsError)
    }
    if (clientsData) {
      return clientsData.getClients.map((client: GraphqlClient) => ({
        label: `${client.clientId} - ${client.name}`,
        value: client.clientId,
      }))
    }
  }, [clientsError, clientsData])

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  const showClientActionsOnlyIf59AUserSuperUser = (item: PermissionCheckboxData) => (item.name === "client" ? user.is59A && is59AClient(watchClientId) : true)

  const submitButtonText = `${isCreateRole ? "Create" : "Update"} Role`
  const is59ASuperUserAndCreateRole = user.is59A && isCreateRole

  return (
    <Box minHeight={is59ASuperUserAndCreateRole ? "61.5rem" : "48rem"}>
      {clientsLoading && <Loading />}`
      {(clientOptions?.length > 0 || !is59ASuperUserAndCreateRole) && (
        <>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {is59ASuperUserAndCreateRole && !clientsLoading && clientOptions?.length > 0 && (
              <>
                <SelectInput label="Client" name="clientId" options={clientOptions} control={control} isInModal={true} zIndex={1} />
              </>
            )}
            <TextInput label="Name" name="name" control={control} tooltip="Role name cannot exceed 50 characters" />
            <TextInput
              label="Description"
              name="description"
              control={control}
              isTextArea={true}
              tooltip="Role description needs to be between 20 and 500 characters in length"
            />
            <HStack align="flex-start">
              <FormLabel margin={0} fontSize={"md"} pb={4}>
                Permissions
              </FormLabel>
              <ChakraButton
                variant="link"
                fontSize="0.95rem"
                paddingLeft="0.5rem"
                paddingTop="0.2rem"
                onClick={() => {
                  setValue("permissions.client", ["VIEW", "CREATE", "UPDATE", "DELETE"], {
                    shouldTouch: true,
                    shouldDirty: true,
                  })
                  setValue("permissions.platformIntegration", ["VIEW", "CREATE", "UPDATE", "DELETE"], {
                    shouldTouch: true,
                    shouldDirty: true,
                  })
                  setValue("permissions.platformAdvertiser", ["VIEW", "UPDATE"], {
                    shouldTouch: true,
                    shouldDirty: true,
                  })
                  setValue("permissions.platformCampaign", ["VIEW", "UPDATE", "REPORTING"], {
                    shouldTouch: true,
                    shouldDirty: true,
                  })
                  setValue("permissions.user", ["MANAGE"], { shouldTouch: true, shouldDirty: true })
                }}
              >
                select all
              </ChakraButton>
              <Text>/</Text>
              <ChakraButton
                variant="link"
                fontSize="0.95rem"
                paddingTop="0.2rem"
                onClick={() => {
                  setValue("permissions.client", [], { shouldTouch: true, shouldDirty: true })
                  setValue("permissions.platformIntegration", [], { shouldTouch: true, shouldDirty: true })
                  setValue("permissions.platformAdvertiser", [], { shouldTouch: true, shouldDirty: true })
                  setValue("permissions.platformCampaign", [], { shouldTouch: true, shouldDirty: true })
                  setValue("permissions.user", [], { shouldTouch: true, shouldDirty: true })
                }}
              >
                clear all
              </ChakraButton>
            </HStack>
            <Box ml={2}>
              {permissionsCheckboxes
                .filter(showClientActionsOnlyIf59AUserSuperUser)
                .sort((a, b) => {
                  return (
                    permissionGroupOrder.indexOf(a.name as KebabToCamelCase<ResourceType>) -
                    permissionGroupOrder.indexOf(b.name as KebabToCamelCase<ResourceType>)
                  )
                })
                .map((permissionsGroup, index) => {
                  return (
                    <CheckboxGroupInput
                      key={`permissions.${permissionsGroup.name}`}
                      control={control}
                      label={permissionsGroup.label ?? ""}
                      name={`permissions.${permissionsGroup.name}`}
                      options={putViewPermissionFirst(permissionsGroup.options)}
                      direction="row"
                    />
                  )
                })}
            </Box>
            <HStack mt={2}>
              <Button type="submit" isDisabled={submitLoading || !formState.isDirty}>
                {submitButtonText}
              </Button>
              <ErrorText
                height={10}
                message={
                  errorType
                    ? mapErrorTypeToMessage({
                        errorType,
                        error,
                        name: roleName,
                      })
                    : ""
                }
                size="small"
              />
            </HStack>
          </form>
        </>
      )}
    </Box>
  )
}

export default RoleSetupForm
