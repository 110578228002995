import { IconBase, IconBaseProps } from "../IconBase"

export const VcrIcon = ({ viewBox = "0 0 270 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M155.877 179.515H113.979C112.633 179.515 111.379 180.29 110.846 181.524C109.703 184.155 111.603 186.695 114.113 186.695H156.025C157.272 186.695 158.464 186.053 159.025 184.943C160.413 182.222 158.471 179.515 155.884 179.515H155.877Z"
        fill="currentColor"
      />
      <path
        d="M246.29 160.621C245.323 159.428 243.97 158.367 242.364 157.453C244.993 155.242 246.704 151.983 246.704 148.299V11.7007C246.704 5.23984 241.439 0 234.948 0H35.0401C28.549 0 23.2916 5.23286 23.2916 11.6937V148.285C23.2916 151.969 25.002 155.235 27.6307 157.439C26.0254 158.36 24.6655 159.414 23.7052 160.614L1.62403 188.041C-0.0863779 190.162 -0.464913 192.625 0.57956 194.795C2.19884 198.151 6.86041 200 13.7021 200H256.293C263.135 200 267.796 198.151 269.416 194.795C270.467 192.625 270.088 190.162 268.371 188.034L246.283 160.607L246.29 160.621ZM30.4907 11.7007C30.4907 9.20286 32.5236 7.17949 35.0331 7.17949H234.611C237.303 7.17949 239.483 9.35636 239.483 12.0356V148.292C239.483 150.972 237.303 153.148 234.611 153.148H35.3696C32.6778 153.148 30.4977 150.972 30.4977 148.299V11.7007H30.4907ZM256.286 192.841H13.6951C10.5686 192.841 8.67597 192.346 7.71562 191.948L29.3201 165.114C30.9324 163.119 37.9212 160.328 45.6461 160.328H224.335C232.06 160.328 239.049 163.119 240.661 165.114L262.251 191.955C261.284 192.353 259.398 192.848 256.286 192.848V192.841Z"
        fill="currentColor"
      />
      <path
        d="M134.869 103.36C106.388 103.36 83.8299 79.7558 82.8835 78.7511L80.8086 76.5463L82.8835 74.3416C83.8299 73.3369 106.388 49.7332 134.869 49.7332C163.35 49.7332 185.908 73.3369 186.854 74.3416L188.929 76.5463L186.854 78.7511C185.908 79.7558 163.35 103.36 134.869 103.36ZM89.8864 76.5463C96.0691 82.2257 114.077 96.9126 134.869 96.9126C155.66 96.9126 173.683 82.2327 179.851 76.5463C173.668 70.8669 155.653 56.18 134.869 56.18C114.028 56.18 96.0621 70.8669 89.8864 76.5463Z"
        fill="currentColor"
      />
      <path
        d="M43.3255 132.259H189.348C190.197 132.259 191.024 132.684 191.43 133.424C192.531 135.427 191.108 137.443 189.208 137.443H43.1783C42.3301 137.443 41.51 137.017 41.1034 136.278C40.0028 134.275 41.4258 132.252 43.3255 132.252V132.259Z"
        fill="currentColor"
      />
      <path
        d="M217.726 132.259H227.421C228.269 132.259 229.096 132.685 229.503 133.424C230.603 135.427 229.18 137.443 227.28 137.443H217.579C216.731 137.443 215.91 137.017 215.504 136.278C214.403 134.275 215.826 132.259 217.726 132.259Z"
        fill="currentColor"
      />
      <path
        d="M200.404 126.838C207.343 124.549 213.701 130.884 211.402 137.785C210.589 140.213 208.647 142.153 206.208 142.955C199.268 145.243 192.91 138.908 195.209 132.008C196.023 129.58 197.964 127.64 200.404 126.838ZM201.441 138.713C205.177 140.331 208.766 136.745 207.126 133.033C206.741 132.168 206.033 131.464 205.163 131.087C201.427 129.468 197.838 133.054 199.478 136.766C199.864 137.631 200.572 138.336 201.441 138.713Z"
        fill="currentColor"
      />
      <path
        d="M139.578 93.1799C126.119 96.8848 114.062 84.8911 117.778 71.488C119.369 65.7667 123.918 61.2316 129.666 59.6547C143.125 55.9568 155.168 67.9506 151.46 81.3397C149.876 87.061 145.326 91.5961 139.578 93.1799ZM134.622 61.4897C124.802 61.4897 117.147 70.9368 120.364 81.1862C121.766 85.6586 125.334 89.2099 129.828 90.6123C140.125 93.8149 149.617 86.2028 149.617 76.4208C149.617 68.1878 142.887 61.4897 134.615 61.4897H134.622Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="5"
        strokeMiterlimit="10"
      />
      <path
        d="M130.508 85.6515C130.298 85.6515 130.088 85.6027 129.898 85.505C129.464 85.2748 129.197 84.8352 129.197 84.3468V68.5854C129.197 68.097 129.471 67.6505 129.905 67.4272C130.34 67.204 130.866 67.2319 131.265 67.511L142.684 75.3882C143.035 75.6324 143.252 76.037 143.252 76.4626C143.252 76.8882 143.042 77.2929 142.684 77.5371L131.265 85.4143C131.041 85.5678 130.774 85.6515 130.515 85.6515H130.508ZM131.826 71.0833V81.842L139.621 76.4626L131.826 71.0833Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </IconBase>
)
