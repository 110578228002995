import { IconBase, IconBaseProps } from "../IconBase"

export const CpcEurIcon = ({ viewBox = "0 0 208 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_415_1004)">
        <path
          d="M91.3121 45.5701C91.3121 70.7336 70.8636 91.1523 45.65 91.1523C20.4365 91.1523 0 70.7336 0 45.5701C0 20.4067 20.4365 0 45.65 0C70.8636 0 91.3121 20.4007 91.3121 45.5701ZM61.3514 27.9047L65.6959 19.273C51.1362 11.499 33.0372 16.9576 25.2496 31.4738C24.2041 33.3873 23.3929 35.4088 22.822 37.5022H16.1701L13.1296 43.5307H21.7765C21.6863 44.8623 21.6743 46.194 21.7765 47.5256H16.1761L13.1356 53.5541H22.786C27.2086 69.4199 43.6851 78.7115 59.5728 74.2967C61.6879 73.7268 63.755 72.905 65.7019 71.8613L61.3574 63.2355C60.7566 63.5655 60.1677 63.8474 59.5728 64.0993C49.3395 68.5082 37.4178 63.7874 33.0132 53.5541H48.4442L51.4667 47.5256H31.4629C31.3307 46.206 31.3307 44.8623 31.4629 43.5307H53.4677L56.4962 37.5022H33.0372C33.3076 36.9384 33.572 36.3685 33.8725 35.8167C39.2685 26.0632 51.5749 22.5241 61.3454 27.9047H61.3514Z"
          fill="currentColor"
        />
        <path d="M172.252 47.7715L41.8574 177.938" stroke="currentColor" stroke-width="15" stroke-miterlimit="10" />
        <path
          d="M157.946 117.125C156.6 117.125 155.506 118.217 155.506 119.561V134.185C155.506 135.529 156.6 136.62 157.946 136.62C159.292 136.62 160.385 135.529 160.385 134.185V119.561C160.385 118.217 159.292 117.125 157.946 117.125ZM136.349 126.039C135.724 126.039 135.112 126.285 134.631 126.765C133.675 127.719 133.675 129.278 134.631 130.232L143.254 138.84C144.209 139.794 145.771 139.794 146.727 138.84C147.682 137.886 147.682 136.326 146.727 135.373L138.104 126.765C137.629 126.291 136.974 126.039 136.349 126.039ZM179.536 126.039C178.911 126.039 178.256 126.285 177.781 126.765L169.158 135.373C168.203 136.326 168.203 137.886 169.158 138.84C170.114 139.794 171.676 139.794 172.631 138.84L181.254 130.232C182.21 129.278 182.21 127.719 181.254 126.765C180.78 126.291 180.161 126.039 179.536 126.039ZM149.587 141.689L171.754 199.994L178.088 180.493L195.369 197.709L205.711 187.385L188.465 170.134L208 163.811L149.593 141.683L149.587 141.689ZM127.42 147.592C126.074 147.592 124.98 148.683 124.98 150.027C124.98 151.371 126.074 152.462 127.42 152.462H142.07C143.416 152.462 144.51 151.371 144.51 150.027C144.51 148.683 143.416 147.592 142.07 147.592H127.42ZM145.008 160.5C144.383 160.5 143.728 160.746 143.254 161.226L134.631 169.834C133.675 170.788 133.675 172.347 134.631 173.301C135.586 174.255 137.149 174.255 138.104 173.301L146.727 164.693C147.682 163.739 147.682 162.18 146.727 161.226C146.252 160.752 145.633 160.5 145.008 160.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1004">
          <rect width="208" height="200" fill="white" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
