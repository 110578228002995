import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import PercentageInputUI, { PercentageInputUIProps } from "./PercentageInputUI"

interface PercentageInputProps extends Omit<PercentageInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const PercentageInput: FC<PercentageInputProps> = ({ max = 100, control, name, isDisabled = false, helperText = "", tooltip = "", label, size = "sm" }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <PercentageInputUI //
      max={max}
      field={field}
      name={name}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
    />
  )
}

export default PercentageInput
