import { FC } from "react"
import { Flex, Text, TextProps, Tooltip } from "@chakra-ui/react"
import colors from "../../theme/colors"
import { middleEllipsis } from "../../utils/stringUtils"

interface ListItemLabelProps extends TextProps {
  isSelected?: boolean
  isDisabled?: boolean
  hasTooltip?: boolean
  label: string
  onClick: VoidFunction
  size?: "small" | "medium" | "large"
  withMiddleEllipsis?: boolean
}

const ListItemLabel: FC<ListItemLabelProps> = ({
  isSelected = false,
  isDisabled = false,
  hasTooltip = true,
  label,
  onClick,
  size = "small",
  withMiddleEllipsis = true,
  ...rest
}) => {
  const textColor = isSelected ? colors.colors.brand["400"] : "inherit"
  const opacity = isDisabled ? 0.7 : 1
  const element = isSelected ? "b" : "p"
  const pointerEvents = isDisabled ? "none" : "auto"
  const cursor = isDisabled ? "not-allowed" : "pointer"
  const readableLabel = withMiddleEllipsis ? middleEllipsis(label) : label

  let fontSize = ""
  let height = ""
  let lineHeight = ""

  switch (size) {
    case "small":
      fontSize = "sm"
      height = "1.4rem"
      lineHeight = "1.5"
      break
    case "medium":
      fontSize = "md"
      height = "1.8rem"
      lineHeight = "1.8"
      break
    case "large":
      fontSize = "lg"
      height = "2.2rem"
      lineHeight = "1.7"
      break
    default:
      fontSize = "sm"
      height = "1.4rem"
      lineHeight = "1.5"
  }

  return (
    <Flex h={height} align="center" cursor={cursor}>
      {hasTooltip ? (
        <Tooltip label={label} placement="top">
          <Text
            lineHeight={lineHeight}
            as={element}
            color={textColor}
            opacity={opacity}
            pointerEvents={pointerEvents}
            fontSize={fontSize}
            overflowX="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            {...rest}
            onClick={onClick}
          >
            {readableLabel}
          </Text>
        </Tooltip>
      ) : (
        <Text
          lineHeight={lineHeight}
          as={element}
          color={textColor}
          opacity={opacity}
          pointerEvents={pointerEvents}
          fontSize={fontSize}
          overflowX="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          {...rest}
          onClick={onClick}
        >
          {readableLabel}
        </Text>
      )}
    </Flex>
  )
}

export default ListItemLabel
