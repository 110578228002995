import React, { FC, useState } from "react"
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputProps, NumberInputStepper } from "@chakra-ui/react"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface IntegerInputUIProps extends Omit<NumberInputProps, "name" | "size" | "variant" | "width" | "zIndex">, Omit<BaseInputProps, "children"> {
  hasStepper?: boolean
  max?: number
}

const IntegerInputUI: FC<IntegerInputUIProps> = ({
  hasStepper = true,
  max = 2147483647,
  name,
  field,
  error,
  isDisabled = false,
  isInvalid,
  isReadOnly,
  label = "",
  placeholder = "",
  helperText = "",
  tooltip = "",
  size = "sm",
  width = 28,
  variant = "outline",
}) => {
  const [value, setValue] = useState(field.value?.toString() || "")
  const paddingRight = hasStepper ? undefined : 0

  return (
    <BaseInput
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
      width={width}
    >
      <NumberInput //
        aria-label={`Number Input for ${label}`}
        onChange={(changedValue) => {
          const valueCopy = changedValue ?? null
          field.onChange(valueCopy)
          setValue(valueCopy)
        }}
        value={value}
        isReadOnly={isReadOnly}
        allowMouseWheel={hasStepper}
        step={1}
        min={0}
        max={max}
        precision={0}
        variant={variant}
        width={width}
      >
        <NumberInputField borderRadius={0} placeholder={placeholder} paddingRight={paddingRight} />
        {hasStepper && (
          <NumberInputStepper>
            <NumberIncrementStepper border="none" paddingTop={2} color={"brand.200"} />
            <NumberDecrementStepper border="none" paddingBottom={2} color={"brand.200"} />
          </NumberInputStepper>
        )}
      </NumberInput>
    </BaseInput>
  )
}

export default IntegerInputUI
