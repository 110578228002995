import { IconBase, IconBaseProps } from "./IconBase"

export const RightArrowIcon = ({ viewBox = "0 0 24 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_172_1033)">
        <path d="M7.33 24L4.5 21.171L13.839 11.996L4.5 2.829L7.33 1.43051e-06L19.5 11.996L7.33 24Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_172_1033">
          <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
