import { gql } from "@apollo/client"

export const CAMPAIGN_AGGREGATED_METRICS_ITEMISED_FRAGMENT = gql`
  fragment CampaignAggregatedMetricsItemisedFragment on CampaignAggregatedMetricsItemised {
    metrics {
      __typename
      ... on CurrencyMetric {
        name
        valueFloat
        currency
        pixels {
          id
          name
        }
      }
      ... on FloatMetric {
        name
        valueFloat
        pixels {
          id
          name
        }
      }
      ... on IntMetric {
        name
        valueInt
        pixels {
          id
          name
        }
      }
    }
  }
`
