import React, { FC, useEffect, useState } from "react"
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import { useLocation, useNavigate } from "react-router-dom"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canManage } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout from "../../layouts/Main"
import { logUnhandledError } from "../../log"
import { StringKeyObject } from "../../types"
import AuthenticatedProps from "../AuthenticatedProps"
import RolesTab from "./tabs/RolesTab"
import UsersTab from "./tabs/UsersTab"

type UsersPageProps = AuthenticatedProps

export const USERS_TAB_HASH = "#users"
export const ROLES_TAB_HASH = "#roles"

export const is59AClient = (clientId: number): boolean => {
  const FIFTY_NINE_A_CLIENT_ID = 1

  return clientId === FIFTY_NINE_A_CLIENT_ID
}

const UserManagement: FC<UsersPageProps> = ({ signOut }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const { user } = useAuth()
  const navigate = useNavigate()
  const { hash } = useLocation()

  const { userResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const fallbackErrorMessage = `There is a problem with User Management.`
  const fallbackErrorEmailSubject = `Unhandled Error in User Management`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in User Management when doing...
`

  const TAB_HASHES: StringKeyObject<number> = {
    [USERS_TAB_HASH]: 0,
    [ROLES_TAB_HASH]: 1,
  }
  const TAB_HASHES_INVERSE = Object.fromEntries(Object.entries(TAB_HASHES).map(([k, v]) => [v, k]))

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
    navigate(TAB_HASHES_INVERSE[index])
  }

  useEffect(() => {
    const tab = TAB_HASHES[hash]
    if (tab !== undefined) {
      handleTabsChange(tab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pageTitle = "User Management"

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canManage(permissions, userResourceUri) && (
        <ErrorBoundary
          fallback={<ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />}
          onError={logUnhandledError}
        >
          <Tabs index={tabIndex} onChange={handleTabsChange} variant="line" pt="4">
            <TabList>
              <Tab fontSize="3xl">Users</Tab>
              <Tab fontSize="3xl">Roles</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px={0}>{user && <UsersTab user={user} />}</TabPanel>
              <TabPanel px={0}>{user && <RolesTab user={user} />}</TabPanel>
            </TabPanels>
          </Tabs>
        </ErrorBoundary>
      )}
      {!permissionsLoading && !canManage(permissions, userResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default UserManagement
