import React, { useState } from "react"
import { Box, Flex, Grid, HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, useDisclosure } from "@chakra-ui/react"
import { Props as DayzedHookProps } from "dayzed"
import FocusLock from "react-focus-lock"
import { CalendarIcon } from "../../icons"
import FormattedDate from "../FormattedDate"
import { CalendarPanel } from "./components/calendarPanel"
import { Month_Names_Short, Weekday_Names_Short } from "./utils/calanderUtils"
import { DatepickerConfigs, DatepickerProps, OnDateSelected, PropsConfigs } from "./utils/commonTypes"

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps
  configs: DatepickerConfigs
  propsConfigs?: PropsConfigs
  selected?: Date | Date[]
}

const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({ dayzedHookProps, configs, propsConfigs, selected }) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null)
  }

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return
    }
    setHoveredDate(date)
  }

  const isInRange = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return false
    }
    const firstSelected = selected[0]
    if (selected.length === 2) {
      const secondSelected = selected[1]
      return firstSelected < date && secondSelected > date
    } else {
      return hoveredDate && ((firstSelected < date && hoveredDate >= date) || (date < firstSelected && date >= hoveredDate))
    }
  }

  return (
    <Flex onMouseLeave={onMouseLeave} direction="column">
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  )
}

export interface RangeDatepickerProps extends DatepickerProps {
  selectedDates: Date[]
  configs?: DatepickerConfigs
  disabled?: boolean
  defaultIsOpen?: boolean
  onDateChange: (date: Date[]) => void
  id?: string
  name?: string
  usePortal?: boolean
}

const DefaultConfigs = {
  dateFormat: "EEE do MMM yyyy",
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
}

const RangeDatePicker: React.FC<RangeDatepickerProps> = ({
  configs = DefaultConfigs,
  propsConfigs = {},
  id,
  name,
  usePortal,
  defaultIsOpen = false,
  ...props
}) => {
  const { selectedDates, minDate, maxDate, onDateChange } = props

  // chakra popover utils
  const [dateInView, setDateInView] = useState(selectedDates[0] || new Date())
  const [offset, setOffset] = useState(0)
  const { onOpen, onClose, isOpen } = useDisclosure({ defaultIsOpen })

  // dayzed utils
  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return
    }
    const newDates = [...selectedDates]
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        const firstTime = selectedDates[0]
        if (firstTime < date) {
          newDates.push(date)
        } else {
          newDates.unshift(date)
        }
        onDateChange(newDates)
        onClose()
      } else if (newDates.length === 2) {
        onDateChange([date])
      }
    } else {
      newDates.push(date)
      onDateChange(newDates)
    }
  }

  const onPopoverClose = () => {
    if (isOpen) {
      onClose()
      setDateInView(selectedDates[0] || new Date())
      setOffset(0)
    }
  }

  const PopoverContentWrapper = usePortal ? Portal : React.Fragment

  return (
    <Popover placement="bottom-start" variant="responsive" isOpen={isOpen} onOpen={onOpen} onClose={onPopoverClose} offset={[-12, -53]}>
      <PopoverTrigger>
        <Grid py={0} w="100%" columnGap="20px" templateColumns="repeat(2, minmax(0, 1fr))">
          <HStack width="100%">
            <CalendarIcon />
            <Flex align="flex-start">
              <Box display="inline">
                <Text as="b" fontSize="0.85rem">
                  FROM:
                </Text>
                {selectedDates[0] ? <FormattedDate date={selectedDates[0]} /> : <></>}
              </Box>
            </Flex>
          </HStack>
          <HStack width="100%">
            <Flex align="flex-start">
              <Box display="inline">
                <Text as="b" fontSize="0.85rem">
                  TO:
                </Text>
                {selectedDates[1] ? <FormattedDate date={selectedDates[1]} /> : <></>}
              </Box>
            </Flex>
          </HStack>
        </Grid>
      </PopoverTrigger>
      <PopoverContentWrapper>
        <PopoverContent bg="brand.700" borderRadius="4px" width="500px" maxWidth="500px" minWidth="500px" position="relative">
          <PopoverBody>
            <FocusLock>
              <Grid py={0} mt={0.5} w="100%" columnGap="20px" templateColumns="repeat(2, minmax(0, 1fr))">
                <HStack width="100%">
                  <CalendarIcon />
                  <Flex align="flex-start">
                    <Box display="inline">
                      <Text as="b" fontSize="0.85rem">
                        FROM:
                      </Text>
                      {selectedDates[0] ? <FormattedDate date={selectedDates[0]} /> : <></>}
                    </Box>
                  </Flex>
                </HStack>
                <HStack width="100%">
                  <Flex align="flex-start">
                    <Box display="inline">
                      <Text as="b" fontSize="0.85rem">
                        TO:
                      </Text>
                      {selectedDates[1] ? <FormattedDate date={selectedDates[1]} /> : <></>}
                    </Box>
                  </Flex>
                </HStack>
              </Grid>
              <RangeCalendarPanel
                dayzedHookProps={{
                  onDateSelected: handleOnDateSelected,
                  selected: selectedDates,
                  monthsToDisplay: 2,
                  firstDayOfWeek: 1,
                  date: dateInView,
                  minDate: minDate,
                  maxDate: maxDate,
                  offset: offset,
                  onOffsetChanged: setOffset,
                }}
                configs={configs}
                propsConfigs={propsConfigs}
                selected={selectedDates}
              />
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </PopoverContentWrapper>
    </Popover>
  )
}

export default RangeDatePicker
