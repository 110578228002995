import React, { FC } from "react"
import { Center, Spinner, Text } from "@chakra-ui/react"

const IntegrationVerify: FC = () => (
  <>
    <Center>
      <Text fontSize="xl"> We&apos;re verifying your credentials.. </Text>
    </Center>
    <Center>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.700" size="xl" />
    </Center>
  </>
)

export default IntegrationVerify
