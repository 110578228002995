import React, { FC } from "react"
import { Center } from "@chakra-ui/react"
import Button from "../../../components/Button"

type IntegrationSetupCompleteProps = {
  onBack: (data: object, stepIndex?: number | undefined) => void
  success: boolean
}

const IntegrationSetupComplete: FC<IntegrationSetupCompleteProps> = ({ onBack, success }) => (
  <div style={{ textAlign: "center", padding: 5 }}>
    {success && (
      <Center flexDirection="column" p={3}>
        <p>Your integration was set up successfully. Your Advertisers and will soon be available.</p>
        <p>Toggle to managed the Advertisers you want to manage campaigns for.</p>
      </Center>
    )}
    {!success && (
      <>
        <Center flexDirection="column" p={3}>
          <p>Your integration was not set up successfully.</p>
          <p>This may be because your credentials were incorrect. Please go back and check your credentials.</p>
        </Center>
        <Center p={3}>
          <Button onClick={() => onBack({}, 0)}>Go Back</Button>
        </Center>
      </>
    )}
  </div>
)

export default IntegrationSetupComplete
