import React, { FC } from "react"
import { InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface PercentageInputUIProps extends Omit<BaseInputProps, "children"> {
  max?: number
}

const PercentageInputUI: FC<PercentageInputUIProps> = ({
  max = 100,
  name,
  field,
  error,
  helperText = "",
  label = "",
  isDisabled = false,
  isInvalid,
  tooltip = "",
  width = 36,
  size = "sm",
}) => {
  const percentageSignBoxWidth = "2rem"

  return (
    <BaseInput
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
    >
      <NumberInput //
        defaultValue={""}
        aria-label={`Percentage Input for ${label}`}
        ref={field.ref}
        allowMouseWheel
        step={1}
        min={0}
        max={max}
        precision={2}
        onChange={(changedValue) => {
          const valueCopy = changedValue ?? ""
          field.onChange(valueCopy)
        }}
        value={field.value}
        width={width}
      >
        <NumberInputField borderRadius={0} />
        <InputRightElement borderRadius={0} bg={"brand.200"} color={"brand.700"} right={percentageSignBoxWidth} w={percentageSignBoxWidth} children="%" />
        <NumberInputStepper>
          <NumberIncrementStepper border="none" paddingTop={2} color={"brand.200"} />
          <NumberDecrementStepper border="none" paddingBottom={2} color={"brand.200"} />
        </NumberInputStepper>
      </NumberInput>
    </BaseInput>
  )
}

export default PercentageInputUI
