import React, { FC } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import { Platform } from "../../generated/graphql"
import { useCampaignParams } from "../../hooks/navigationHooks"
import useAuth from "../../hooks/useAuth"
import { canUpdate, usePermissions } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { logUnhandledError } from "../../log"
import { toTitleCase } from "../../utils/stringUtils"
import AuthenticatedProps from "../AuthenticatedProps"
import CampaignSetup from "./CampaignSetup"

type PlatformCampaignSetupPageProps = AuthenticatedProps

const CampaignSetupPage: FC<PlatformCampaignSetupPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformCampaignResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const navigate = useNavigate()
  const { clientId, integrationId, platform, advertiserId, campaignId } = useCampaignParams()

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integrations", onClick: () => navigate(RoutePaths.integrationsList.resolve(clientId)) },
    { text: integrationId, onClick: () => navigate(RoutePaths.advertisersList.resolve(clientId, integrationId)) },
    { text: "Advertisers", onClick: () => navigate(RoutePaths.advertisersList.resolve(clientId, integrationId)) },
    {
      text: advertiserId,
      onClick: () => navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId)),
    },
    {
      text: "Campaigns",
      onClick: () => navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId)),
    },
    {
      text: campaignId,
    },
    { text: "Manage" },
  ]
  if (user?.is59A) {
    breadcrumbItems.splice(0, 0, {
      text: "Clients",
      onClick: () => navigate(RoutePaths.clientsList.resolve()),
    })
    breadcrumbItems.splice(1, 0, {
      text: clientId,
      onClick: () => navigate(RoutePaths.integrationsList.resolve(clientId)),
    })
  }

  const displayablePlatform = platform === Platform.Dv360 ? platform.toUpperCase() : toTitleCase(platform)
  const heading = `Manage ${displayablePlatform} Campaign`

  const fallbackErrorMessage = `There is a problem with Campaign Management for ${displayablePlatform}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${displayablePlatform} Campaign Management`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in Campaign Management when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in Campaign Management`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in Campaign Management.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={heading} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canUpdate(permissions, platformCampaignResourceUri) && (
        <ErrorBoundary
          fallback={<ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />}
          onError={logUnhandledError}
        >
          <CampaignSetup
            user={user}
            clientId={clientId}
            integrationId={integrationId}
            platform={platform}
            advertiserId={advertiserId}
            campaignId={campaignId}
          />
        </ErrorBoundary>
      )}
      {!permissionsLoading && !canUpdate(permissions, platformCampaignResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default CampaignSetupPage
