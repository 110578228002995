import { gql } from "@apollo/client"

export const GET_ROLES = gql`
  query GetRoles {
    getRoles {
      clientId
      roleId
      name
      description
      isAdmin
      isDeletable
      isEditable
      users {
        userId
        email
      }
    }
  }
`
