import React, { FC } from "react"
import { ButtonGroup, HStack } from "@chakra-ui/react"
import Button, { ButtonProps } from "../Button"

interface WizardNavigationButtonProps extends ButtonProps {
  shown?: boolean
  text?: string
}

interface WizardNavigatePreviousButtonProps {
  previous?: WizardNavigationButtonProps
}

const WizardNavigatePreviousButton: FC<WizardNavigatePreviousButtonProps> = ({ previous = {} }) => {
  const prevText = previous?.text || "Previous"

  const { shown: previousShown, ...previousProps } = previous

  return (
    <>
      {(previousShown ?? true) && (
        <HStack justify={"start"} py={3}>
          <ButtonGroup>
            <Button {...previousProps} isInline={true} isSecondary={true}>
              {prevText}
            </Button>
          </ButtonGroup>
        </HStack>
      )}
    </>
  )
}

export default WizardNavigatePreviousButton
