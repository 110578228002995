import { FC } from "react"
import { Box, Flex, Text, VStack } from "@chakra-ui/react"
import { MetricName } from "../../generated/graphql"
import colors from "../../theme/colors"
import FormattedDate from "../FormattedDate"
import { mapMetricNameToDisplayName } from "./utils"

export type DataDisplayAreaProps = Readonly<{
  top?: number
  right?: number
  date?: Date
  metricNames: MetricName[]
  values?: string[]
}>

const TooltipMetricsDisplayArea: FC<DataDisplayAreaProps> = ({ top = "54px", right = "45px", date = new Date(), metricNames, values }) => (
  <Box position="relative" top={top} right={right}>
    <VStack align="flex-end">
      {metricNames.map((metricName, index) => (
        <Box key={metricName} pb={3}>
          <Text pb={1}>{mapMetricNameToDisplayName[metricName]}</Text>
          <Flex
            borderRadius="4px"
            borderColor={colors.colors.brand["200"]}
            border="2px solid"
            bg={colors.colors.brand["900"]}
            align="center"
            justify="flex-end"
            p={1.5}
            w={24}
            h={8}
          >
            <Text fontSize="0.9rem">{values?.[index]}</Text>
          </Flex>
        </Box>
      ))}
      <Flex
        borderRadius="4px"
        borderColor={colors.colors.brand["200"]}
        border="2px solid"
        bg={colors.colors.brand["900"]}
        justify="center"
        direction="row"
        p={1.5}
        w={40}
        h={9}
      >
        {date && (
          <Box>
            <FormattedDate date={date} />
          </Box>
        )}
      </Flex>
    </VStack>
  </Box>
)

export default TooltipMetricsDisplayArea
