import { FC, useEffect, useMemo, useRef, useState } from "react"
import { ApolloError } from "@apollo/client"
import { InfoIcon } from "@chakra-ui/icons"
import { Box, Flex, HStack, Text, Tooltip, VStack } from "@chakra-ui/react"
import * as d3 from "d3"
import { format } from "date-fns"
import { MetricName } from "../../../generated/graphql"
import { QRTZtmIcon } from "../../../icons"
import colors from "../../../theme/colors"
import Tile from "../../Tile"
import MetricAverage from "../MetricAverage"
import PercentageChange from "../PercentageChange"
import { DataPoint, MetricEntries, MetricDataSet, MetricType, Pixel } from "../types"
import { mapMetricNameToDisplayName } from "../utils"
import TrendGraphMetricsDisplayArea from "./components/TrendGraphMetricsDisplayArea"
import TrendGraphNoData from "./TrendGraphNoData"

const displayPixelsText = (pixels: Pixel[]) => {
  if (pixels.length === 1) {
    return pixels[0].name
  }
  return `${pixels.length} Pixels`
}

const displayPixelsTooltip = (pixels: Pixel[]) => {
  if (pixels.length === 1) {
    // Return "" if we only have the ID, e.g. no performance data where we can get the name from
    if (pixels[0].id === pixels[0].name) return ""
    return pixels[0].id ? `ID: ${pixels[0].id}` : ""
  }
  return pixels
    .map((pixel) => {
      return (
        <Text>
          {pixel.name !== pixel.id ? pixel.name : `${pixel.name} - No Data`}
          {pixel.name !== pixel.id ? `, ID: ${pixel.id}` : ""}
        </Text>
      )
    })
    .filter((element) => element !== undefined)
}

type TrendGraphProps = {
  loading?: boolean
  error?: ApolloError
  metricDataSet: MetricDataSet
  currencySymbol: string
}

interface TooltipData extends DataPoint {
  x: number
  y: number
}

const TrendGraph: FC<TrendGraphProps> = ({ loading, error, metricDataSet, currencySymbol }) => {
  const [tooltip, setTooltip] = useState<TooltipData | null>(null)

  const { name, data: trendGraphData, minimise, pixels, target: metricParamsTarget, isKpi } = metricDataSet

  const metricEntry = MetricEntries.find((entry) => entry.name === name)
  const target = metricParamsTarget && metricEntry?.type === MetricType.Percentage ? metricParamsTarget * 100 : metricParamsTarget

  const graph = useRef(null)
  const cache = useRef(trendGraphData)

  // Constants
  const viewBoxHeight = 100
  const viewBoxWidth = 240
  const spaceBetweenDataPoints = viewBoxWidth / (trendGraphData.length - 1)
  const constraintsDashArray = 4
  const fastAnimationDuration = 300
  const slowAnimationDuration = 1000
  const dataPointSize = 5

  const data = useMemo(() => trendGraphData, [trendGraphData])

  useEffect(() => {
    const groupGraph = d3.select(graph.current)

    // Reset graph between data changes
    groupGraph.selectAll("*").remove()

    if (data.length) {
      // Add Linear Gradient for area
      const defs = groupGraph.append("defs")
      const gradient = defs
        .append("linearGradient") //
        .attr("id", "linear-gradient")
        .attr("x1", "0.5")
        .attr("y1", "0")
        .attr("x2", "0.5")
        .attr("y2", "1")
      gradient.append("stop").attr("offset", "-150%").attr("stop-color", colors.colors.brand["200"])
      gradient.append("stop").attr("offset", "33.3%").attr("stop-color", colors.colors.dataVis.graphGradientMidStop)
      gradient.append("stop").attr("offset", "177%").attr("stop-color", colors.colors.brand["700"])

      // Add scales for data
      const xScale = d3.scaleTime([0, viewBoxWidth])
      const maxYValue = data?.map((dataPoint) => dataPoint.value).reduce((a, b) => (a >= b ? a : b), data[0].value)
      const minYValue = data?.map((dataPoint) => dataPoint.value).reduce((a, b) => (a <= b ? a : b), data[0].value)
      const yAxisPadding = maxYValue * 0.05
      const yScale = d3.scaleLinear([minYValue - yAxisPadding, Number(maxYValue) + yAxisPadding], [viewBoxHeight, 0]).nice()

      // Add Axes
      const xAxis = d3.axisBottom(xScale).tickSize(0)
      const yAxis = d3.axisLeft(yScale).tickSize(0)

      // Add the area
      const initialArea = d3
        .area()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.indexOf(d) * spaceBetweenDataPoints)
        .y1(() => yScale(minYValue - yAxisPadding))
        .y0(() => yScale(minYValue - yAxisPadding))
      const area = d3
        .area()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.indexOf(d) * spaceBetweenDataPoints)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .y1((d: any) => yScale(d.value))
        .y0(() => yScale(minYValue - yAxisPadding))

      groupGraph
        .append("path")
        .datum(data)
        .attr("transform", "translate(0 0)")
        .attr("fill", `url(#linear-gradient)`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", initialArea as any) // initial state (line at the bottom)
        .transition()
        .duration(fastAnimationDuration)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", area as any)

      // Add the x-Axis
      const xAxisGraph = groupGraph.append("g").attr("transform", `translate(0 ${viewBoxHeight})`).call(xAxis)
      xAxisGraph.select("path").style("stroke", colors.colors.brand["700"]).style("stroke-width", 1).style("opacity", "0.5").style("stroke-linecap", "round")
      xAxisGraph.selectAll("text").style("display", "none")

      // Add the y-Axis
      const yAxisGraph = groupGraph.append("g").attr("transform", `translate(0 0)`).call(yAxis)
      yAxisGraph.select("path").style("stroke", colors.colors.brand["700"]).style("stroke-width", 1).style("opacity", "0.5").style("stroke-linecap", "round")
      yAxisGraph.selectAll("text").style("display", "none")

      if (isKpi && target && target >= minYValue * 0.9 && target <= maxYValue * 1.1) {
        // Add the line for the KPI target
        const targetLine = d3
          .line()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .x((d: any) => data.indexOf(d) * spaceBetweenDataPoints)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .y((d: any) => yScale(target))

        groupGraph
          .append("path")
          .datum(data)
          .attr("fill", "none")
          .attr("stroke", colors.colors.white)
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", constraintsDashArray)
          .attr("transform", `translate(0 0)`)
          .transition()
          .duration(fastAnimationDuration)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .attr("d", targetLine as any)

        // Add rect to contain targetLine for tooltip
        groupGraph
          .selectAll()
          .data(data)
          .enter()
          .append("rect")
          .on("mouseover", (d) => {
            setTooltip({
              date: new Date(),
              value: target,
              formattedValue: `Target: ${target}`,
              x: 0,
              y: yScale(target) - viewBoxHeight / 5,
            })
          })
          .on("mouseout", () => setTooltip(null))
          .style("stroke", colors.colors.white)
          .style("stroke-width", 1)
          .attr("stroke", "1")
          .attr("x", 0)
          .attr("y", yScale(Number(target)))
          .attr("height", 3)
          .attr("width", viewBoxWidth)
          .attr("opacity", 0)
      }

      // Add the points for the data points
      groupGraph
        .selectAll()
        .data(data)
        .enter()
        .append("rect")
        .on("mouseover", (d) => {
          const dateAndMonth = format(new Date(d.target.__data__.date), "d MMM")
          setTooltip({
            ...d.target.__data__,
            hover: `${dateAndMonth}: ${d.target.__data__.hover}`,
          })
        })
        .on("mouseout", () => setTooltip(null))
        .attr("fill", colors.colors.white)
        .style("stroke", colors.colors.white)
        .style("stroke-width", 1)
        .attr("stroke", "1")
        .attr("x", (d) => {
          const index = data.indexOf(d)
          if (index === 0) {
            return data.indexOf(d) * spaceBetweenDataPoints
          }
          return data.indexOf(d) * spaceBetweenDataPoints - dataPointSize / 2
        })
        .attr("y", (d) => yScale(Number(d.value)) - dataPointSize / 2)
        .attr("height", dataPointSize)
        .attr("width", dataPointSize)
        .attr("opacity", 0)
        .transition()
        .duration(slowAnimationDuration)
        .attr("opacity", 1)
    }

    cache.current = data
  }, [data, spaceBetweenDataPoints, isKpi, target])

  return (
    <>
      <Tile p={0} loading={loading} loader={<TrendGraphNoData />} error={error}>
        <Box p={0} m={0} width="100%">
          <HStack mb={0} px={4} pt={4} h={12} justifyContent="space-between" alignItems="center" width="100%">
            <HStack m={0} p={0} justifyContent="flex-start">
              <Text as="b" color="brand.400" fontSize="1.25rem">
                {mapMetricNameToDisplayName[name]}
              </Text>
            </HStack>
            <HStack m={0} p={0} justifyContent="flex-end">
              {isKpi && <QRTZtmIcon width="1.7rem" height="1.7rem" color={colors.colors.white} />}
              {target && (
                <Tooltip aria-label={`Target is ${target}`} label={`Target is ${target}`} placement="right-start">
                  <InfoIcon color={"brand.400"} w={4} h={4} display="flex" alignSelf="flex-start" />
                </Tooltip>
              )}
            </HStack>
          </HStack>
          <Box h={4} w="100%">
            {(name === MetricName.Cpa || name === MetricName.Conversions) && pixels && pixels.length > 0 && (
              <Tooltip label={displayPixelsTooltip(pixels)} placement="bottom-end">
                <Text
                  key={`${pixels.map((pixel) => pixel.id).join("_")}`}
                  px={4}
                  as="b"
                  color="brand.400"
                  fontSize="1rem"
                  display="block"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {displayPixelsText(pixels)}
                </Text>
              </Tooltip>
            )}
          </Box>
          {data.length ? (
            <VStack width="100%" position="relative" m={0} p={0} pt="2px">
              <svg
                style={{
                  overflow: "visible",
                  left: "0px",
                  width: `${viewBoxWidth}px`,
                  height: `${viewBoxHeight}px`,
                }}
                viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
              >
                <g ref={graph} />
              </svg>
              <TrendGraphMetricsDisplayArea hidden={false}>
                {tooltip ? (
                  tooltip.formattedValue
                ) : (
                  <Flex>
                    <MetricAverage name={name} data={data} currencySymbol={currencySymbol} />
                    <Box m={0} p={0} pt="2px">
                      <PercentageChange fromValue={data[0].value} toValue={data[data.length - 1].value} minimise={minimise} />
                    </Box>
                  </Flex>
                )}
              </TrendGraphMetricsDisplayArea>
            </VStack>
          ) : (
            <TrendGraphNoData />
          )}
        </Box>
      </Tile>
    </>
  )
}

export default TrendGraph
