import React, { FC } from "react"
import { Checkbox, CheckboxGroup, Flex, Text } from "@chakra-ui/react"
import BaseInput, { BaseInputProps } from "../BaseInput"

export type CheckboxMetaData = {
  label: string
  helperText?: string
  tooltip?: string
  value: string
}

export interface CheckboxGroupInputUIProps extends Omit<BaseInputProps, "children"> {
  options: CheckboxMetaData[]
  direction?: "row" | "column"
}

const CheckboxGroupInputUI: FC<CheckboxGroupInputUIProps> = ({
  options,
  name,
  label,
  field,
  error,
  isDisabled = false,
  isInvalid,
  size = "sm",
  width = 5,
  direction,
}) => {
  const props = {
    defaultValue: field.value,
    value: field.value,
    onChange: (nextValue: (string | number)[]) => {
      const nextValueCopy = nextValue ?? null
      field.onChange(nextValueCopy)
    },
    isDisabled,
  }

  return (
    <>
      <Text pb={2}>{label}</Text>
      <CheckboxGroup {...props}>
        <Flex direction={direction} aria-label={`Checkbox group for ${label}`}>
          {options.map(({ label, helperText, tooltip, value }, index) => (
            <BaseInput //
              name={name}
              key={`${label}-${index}`}
              field={field}
              error={error}
              label={""}
              tooltip={tooltip}
              helperText={helperText}
              isDisabled={isDisabled}
              isInvalid={isInvalid}
              isInline={true}
              size={size}
              width={width}
              styles={{ flexGrow: 1, maxWidth: "25%" }}
            >
              <Checkbox value={value} variant="custom" aria-label={`Checkbox for ${label}`}>
                {label}
              </Checkbox>
            </BaseInput>
          ))}
        </Flex>
      </CheckboxGroup>
    </>
  )
}

export default CheckboxGroupInputUI
