import { IconBase, IconBaseProps } from "../icons/IconBase"

export const ErrorSadFace = ({ viewBox = "0 0 150 138", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M13.1313 49.8464L26.0501 36.9276L38.9688 49.8464C40.9109 51.7885 43.743 52.548 46.3976 51.8372C49.0522 51.1249 51.1247 49.0522 51.8373 46.3976C52.5481 43.7431 51.7885 40.9108 49.8464 38.9688L36.9276 26.05L49.8464 13.1312V13.1297C51.7885 11.1875 52.5481 8.35548 51.8373 5.70087C51.1249 3.04634 49.0522 0.973783 46.3976 0.262828C43.7431 -0.449537 40.9109 0.310005 38.9688 2.25213L26.0501 15.1725L13.1313 2.25374C11.1876 0.310049 8.35548 -0.449497 5.70087 0.262868C3.04635 0.973662 0.973782 3.04634 0.262827 5.70091C-0.449537 8.35544 0.310008 11.1877 2.25214 13.1313L15.1709 26.0501L2.25374 38.9689C0.310051 40.911 -0.449494 43.7431 0.26287 46.3977C0.973664 49.0522 3.04634 51.1247 5.70091 51.8373C8.35544 52.5481 11.1877 51.7886 13.1313 49.8464L13.1313 49.8464Z"
        fill="#F67904"
      />
      <path
        d="M134.828 26.05L147.747 13.1313C149.691 11.1876 150.45 8.35546 149.738 5.70085C149.027 3.04632 146.954 0.973759 144.3 0.262803C141.645 -0.449561 138.813 0.309983 136.869 2.25368L123.951 15.1725L111.032 2.25368C109.09 0.309987 106.258 -0.449561 103.603 0.262803C100.948 0.973597 98.8759 3.04628 98.1633 5.70085C97.4525 8.35538 98.212 11.1876 100.154 13.1313L113.073 26.05L100.154 38.9688C98.212 40.9109 97.4525 43.743 98.1633 46.3976C98.8757 49.0521 100.948 51.1247 103.603 51.8372C106.257 52.548 109.09 51.7885 111.032 49.8464L123.951 36.9276L136.869 49.8464C138.813 51.7885 141.645 52.548 144.3 51.8372C146.954 51.1249 149.027 49.0522 149.739 46.3976C150.45 43.7431 149.691 40.9108 147.748 38.9688L134.828 26.05Z"
        fill="#F67904"
      />
      <path
        d="M74.9999 91.783C61.9226 91.7279 49.0147 94.7457 37.3165 100.594C25.6196 106.444 15.4609 114.96 7.65929 125.455C6.42796 127.086 5.898 129.14 6.18262 131.164C6.46883 133.188 7.54918 135.013 9.18309 136.24C10.817 137.466 12.8724 137.992 14.8949 137.701C16.9172 137.408 18.7414 136.325 19.9634 134.688C28.5371 123.255 40.4587 114.776 54.0727 110.427C67.6863 106.081 82.3144 106.081 95.9289 110.427C109.543 114.776 121.465 123.255 130.038 134.688C131.26 136.325 133.084 137.408 135.107 137.701C137.129 137.992 139.184 137.466 140.819 136.24C142.452 135.013 143.533 133.187 143.819 131.164C144.104 129.14 143.574 127.086 142.342 125.455C134.541 114.96 124.382 106.444 112.685 100.594C100.987 94.7459 88.079 91.7282 75.0016 91.783H74.9999Z"
        fill="#F67904"
      />
    </g>
  </IconBase>
)
