import { gql } from "@apollo/client"
import { PLATFORM_ADVERTISER_FRAGMENT } from "../fragments"

export const GET_PLATFORM_ADVERTISERS_BY_INTEGRATION = gql`
  query GetPlatformAdvertisersByIntegration($clientId: Int!, $platformIntegrationId: Int!) {
    getPlatformAdvertisersByIntegration(clientId: $clientId, platformIntegrationId: $platformIntegrationId) {
      ...PlatformAdvertiserFragment
    }
  }
  ${PLATFORM_ADVERTISER_FRAGMENT}
`
