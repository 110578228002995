import { gql } from "@apollo/client"
import { PLATFORM_ADVERTISER_FRAGMENT } from "../fragments"

export const UPDATE_PLATFORM_ADVERTISER = gql`
  mutation UpdatePlatformAdvertiser($clientId: Int!, $platform: Platform!, $advertiser: PlatformAdvertiserUpdateInput!) {
    updatePlatformAdvertiser(clientId: $clientId, platform: $platform, advertiser: $advertiser) {
      ...PlatformAdvertiserFragment
    }
  }
  ${PLATFORM_ADVERTISER_FRAGMENT}
`
