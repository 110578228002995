import { graphql } from "msw"

export const updateScheduleState = graphql.mutation("UpdateScheduleState", (req, res, ctx) => {
  return res(
    ctx.data({
      updateScheduleState: {
        clientId: 1,
        platformIntegrationId: 1,
        scheduleCronExpression: "0 0 */12 * * *",
        platformDataType: "ADVERTISER",
        format: "JSON",
        outputDestinations: ["SQS"],
        granularity: "ALL",
        __typename: "PlatformIntegrationSchedule",
      },
    })
  )
})
