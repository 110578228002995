import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { ApolloQueryResult, OperationVariables, useMutation } from "@apollo/client"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useSetRecoilState } from "recoil"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { RoleWithUsers } from "../../../generated/graphql"
import { DELETE_ROLE, GET_ROLES } from "../../../graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import useSettings from "../../../hooks/useSettings"
import { UserProfile } from "../../../models/AuthState"
import { CustomGraphQLError } from "../../../types"
import Button from "../../Button"
import ErrorText from "../../forms/ErrorText"
import PageTable from "../PageTable"

export interface RoleTableRowData {
  clientId: number
  roleId: number
  name: string
  description: string
  isAdmin: boolean
  isDeletable: boolean
  isEditable: boolean
}

interface RoleTableProps {
  user: UserProfile
  data?: RoleWithUsers[]
  isLoading: boolean
  onEdit: (role: RoleWithUsers) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchData: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  onManageUsersOpenHandler: (roles: RoleTableRowData) => void
}

const toTableRow = (data: RoleWithUsers[]): RoleTableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
  }))

const columnHelper = createColumnHelper<RoleTableRowData>()

const RolesTable: FC<RoleTableProps> = ({ user, data, isLoading, onEdit, refetchData, onManageUsersOpenHandler }) => {
  const [roleToBeDeleted, setRoleToBeDeleted] = useState<
    | {
        clientId: number
        roleId: number
        name: string
      }
    | undefined
  >(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.roles)

  useEffect(() => {
    if (pageIndex !== paginationSettings.roles.pageIndex || pageSize !== paginationSettings.roles.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        roles: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [deleteRole, { error, loading, reset: resetDeleteRole }] = useMutation(DELETE_ROLE, {
    refetchQueries: [GET_ROLES],
  })

  const resetAndClose = () => {
    resetDeleteRole()
    onClose()
  }

  const onDeleteRole = async () => {
    await deleteRole({
      variables: {
        clientId: roleToBeDeleted?.clientId,
        roleId: roleToBeDeleted?.roleId,
      },
    })
    resetAndClose()
  }

  const onCloseDeleteDialog = async () => {
    await refetchData()
    resetAndClose()
  }

  const tableColumns = useMemo(() => {
    const onStartDeleteRole = (clientId: number, roleId: number, name: string) => {
      setRoleToBeDeleted({ clientId, roleId, name })
      onOpen()
    }

    return [
      columnHelper.accessor("clientId", {
        id: "clientId",
        cell: (info) => info.getValue(),
        header: () => <span>Client ID</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("roleId", {
        id: "roleId",
        cell: (info) => info.getValue(),
        header: () => <span>Role ID</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("description", {
        id: "description",
        cell: (info) => info.getValue(),
        header: () => <span>Description</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) =>
          row.original.isAdmin && !user.roles.some((role) => role.isAdmin) ? (
            <></>
          ) : (
            <HStack>
              <Button isDisabled={!row.original.isEditable} isInline={true} onClick={() => onEdit(row.original)}>
                Edit
              </Button>
              <Button isInline={true} onClick={() => onManageUsersOpenHandler(row.original)}>
                Manage Users
              </Button>
              <Button
                isDisabled={!row.original.isDeletable}
                isInline={true}
                onClick={() => onStartDeleteRole(row.original.clientId, row.original.roleId, row.original.name)}
              >
                Delete
              </Button>
            </HStack>
          ),
      }),
    ] as ColumnDef<RoleTableRowData, unknown>[]
  }, [onEdit, onManageUsersOpenHandler, onOpen, user.roles])

  const columnVisibility = user.is59A ? undefined : { clientId: false }

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as RoleTableRowData[]
  }, [data])

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <>
      <PageTable
        pagination={{ pageIndex, pageSize }}
        onPaginationChange={setPagination}
        loading={isLoading}
        defaultSort={[
          { desc: false, id: "clientId" },
          { desc: false, id: "roleId" },
        ]}
        hasGlobalFilter={true}
        heightOtherElementsRem={25}
        tableColumns={tableColumns}
        columnVisibility={columnVisibility}
        tableRows={tableRows}
      />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCloseDeleteDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Role
            </AlertDialogHeader>
            <AlertDialogBody>
              You are about to delete role <b>{roleToBeDeleted?.name}</b>
            </AlertDialogBody>
            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
            <ErrorText
              pl={6}
              pr={6}
              height={10}
              message={
                errorType
                  ? mapErrorTypeToMessage({
                      errorType,
                      name: roleToBeDeleted?.name,
                    })
                  : ""
              }
              size="small"
            />
            <AlertDialogFooter>
              <VStack>
                <Button colorScheme="red" onClick={onDeleteRole} isDisabled={loading}>
                  Delete
                </Button>
                <Button buttonRef={cancelRef} isInline={true} isSecondary={true} onClick={onCloseDeleteDialog}>
                  Cancel
                </Button>
              </VStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default RolesTable
