import React, { FC } from "react"
import { Center, Flex, Image, Text } from "@chakra-ui/react"

type MaintenanceModeProps = {
  maintenanceHours: number
}

const MaintenanceMode: FC<MaintenanceModeProps> = ({ maintenanceHours }) => {
  const hoursText = maintenanceHours === 1 ? "hour" : "hours"

  return (
    <Center>
      <Flex direction="column" align="center" mt={40}>
        <Image w={64} src="/59A-logo.svg" />
        <Text fontSize="5xl">The site is currently under maintenance</Text>
        <Text fontSize="5xl">{`Please check back in ${maintenanceHours} ${hoursText}`}</Text>
      </Flex>
    </Center>
  )
}

export default MaintenanceMode
