import { FC, ReactNode } from "react"
import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  Text,
} from "@chakra-ui/react"
import Button from "../Button"

interface ModalProps extends ChakraModalProps {
  children: ReactNode
  hasForm?: boolean
  isOpen: boolean
  onClose: VoidFunction
  onSubmit?: VoidFunction
  title: string
  subTitle?: string
  width: number | string
}

const Modal: FC<ModalProps> = ({ children, hasForm = false, isCentered, isOpen, onClose, onSubmit, title, subTitle, width }) => {
  const paddingBottom = hasForm ? 4 : 0
  return (
    <ChakraModal onClose={onClose} isCentered={isCentered} isOpen={isOpen} variant="custom">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent maxWidth="container.xl" pb={paddingBottom} width={width}>
        <ModalHeader py={5}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            {subTitle && (
              <Box mb={4}>
                <Text fontSize="1.2rem">{subTitle}</Text>
              </Box>
            )}
            {children}
          </>
        </ModalBody>
        {!hasForm && (
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={onSubmit}>Submit</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
