import React, { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { FormLabel, HStack, Tooltip } from "@chakra-ui/react"

interface FormItemLabelWithToolTipProps {
  fieldName: string
  size?: "sm" | "md" | "lg"
  label: string
  tooltip: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormItemLabelWithToolTip: FC<FormItemLabelWithToolTipProps> = ({ fieldName, size = "sm", label, tooltip }) => {
  const fontSize = size === "sm" ? "16px" : "27px"
  const paddingTop = size === "sm" ? 1 : "7px"
  const paddingBottom = size === "sm" ? 1 : 2

  return (
    <HStack paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      <FormLabel margin={0} fontSize={fontSize} htmlFor={fieldName}>
        {label}
      </FormLabel>
      {tooltip && (
        <Tooltip label={tooltip} placement="right-start">
          <InfoIcon color={"brand.400"} w={4} h={4} />
        </Tooltip>
      )}
    </HStack>
  )
}

export default FormItemLabelWithToolTip
