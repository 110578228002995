import React, { FC } from "react"
import { InputLeftAddon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react"
import colors from "../../../theme/colors"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface CurrencyInputUIProps extends Omit<BaseInputProps, "children"> {
  symbol?: string
}

const CurrencyInputUI: FC<CurrencyInputUIProps> = ({
  symbol = "£",
  name,
  field,
  error,
  helperText = "",
  label = "",
  isInvalid,
  isDisabled = false,
  tooltip = "",
  size = "sm",
  width = 28,
}) => {
  return (
    <BaseInput
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
    >
      <InputLeftAddon borderLeftRadius={0} children={symbol} bg={colors.colors.brand["200"]} color={colors.colors.brand["700"]} />
      <NumberInput //
        defaultValue={""}
        aria-label={`Currency Input for ${label}`}
        ref={field.ref}
        allowMouseWheel
        step={0.01}
        min={0}
        isDisabled={isDisabled}
        onChange={(changedValue) => {
          const valueCopy = changedValue ?? ""
          // send data to react hook form
          field.onChange(valueCopy)
        }}
        value={field.value}
        width={width}
      >
        <NumberInputField borderRadius={0} />
        <NumberInputStepper>
          <NumberIncrementStepper border="none" paddingTop={2} color={"brand.200"} />
          <NumberDecrementStepper border="none" paddingBottom={2} color={"brand.200"} />
        </NumberInputStepper>
      </NumberInput>
    </BaseInput>
  )
}

export default CurrencyInputUI
