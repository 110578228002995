import { IconBase, IconBaseProps } from "../IconBase"

export const CpcGbpIcon = ({ viewBox = "0 0 208 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <g clipPath="url(#clip0_415_1012)">
        <path d="M172.252 47.7715L41.8574 177.938" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
        <path
          d="M157.946 117.125C156.6 117.125 155.506 118.217 155.506 119.561V134.185C155.506 135.529 156.6 136.62 157.946 136.62C159.292 136.62 160.385 135.529 160.385 134.185V119.561C160.385 118.217 159.292 117.125 157.946 117.125ZM136.349 126.039C135.724 126.039 135.112 126.285 134.631 126.765C133.675 127.719 133.675 129.278 134.631 130.232L143.254 138.84C144.209 139.794 145.771 139.794 146.727 138.84C147.682 137.886 147.682 136.326 146.727 135.373L138.104 126.765C137.629 126.291 136.974 126.039 136.349 126.039ZM179.536 126.039C178.911 126.039 178.256 126.285 177.781 126.765L169.158 135.373C168.203 136.326 168.203 137.886 169.158 138.84C170.114 139.794 171.676 139.794 172.631 138.84L181.254 130.232C182.21 129.278 182.21 127.719 181.254 126.765C180.78 126.291 180.161 126.039 179.536 126.039V126.039ZM149.587 141.689L171.754 199.994L178.088 180.493L195.369 197.709L205.711 187.385L188.465 170.134L208 163.811L149.593 141.683L149.587 141.689ZM127.42 147.592C126.074 147.592 124.98 148.683 124.98 150.027C124.98 151.371 126.074 152.462 127.42 152.462H142.07C143.416 152.462 144.51 151.371 144.51 150.027C144.51 148.683 143.416 147.592 142.07 147.592H127.42ZM145.008 160.5C144.383 160.5 143.728 160.746 143.254 161.226L134.631 169.834C133.675 170.788 133.675 172.347 134.631 173.301C135.586 174.255 137.149 174.255 138.104 173.301L146.727 164.693C147.682 163.739 147.682 162.18 146.727 161.226C146.252 160.752 145.633 160.5 145.008 160.5Z"
          fill="currentColor"
        />
        <path
          d="M45.6549 0C20.4407 0 0 20.4049 0 45.575C0 70.7451 20.4407 91.15 45.6549 91.15C70.8691 91.15 91.3098 70.7451 91.3098 45.575C91.3098 20.4049 70.8691 0 45.6549 0ZM62.3964 69.3885H25.0189L26.3949 59.0101C33.1221 58.7048 34.4981 51.9131 34.4981 47.1055H28.8412V36.4218H34.651C34.651 22.991 43.3658 13.6047 57.5082 13.452L58.9606 24.6699C50.6281 25.433 47.4174 29.0959 47.4174 36.4218H58.6549V47.1055H46.7294C46.7294 50.3106 46.7294 57.1786 42.7542 59.0864C42.7542 59.239 42.8307 59.3153 42.8307 59.3916C45.6591 58.6285 48.9463 58.3233 54.1446 58.3233H62.4007V69.3885H62.3964Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1012">
          <rect width="208" height="200" fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  </IconBase>
)
