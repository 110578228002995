import React from "react"
import jss from "jss"
import preset from "jss-preset-default"
import { createRoot } from "react-dom/client"
import { configureAmplify } from "./Amplify"
import AppContainer from "./AppContainer"
import Env, { EnvKey } from "./Env"
import { worker } from "./mocks/browser"
import reportWebVitals from "./reportWebVitals"

jss.setup(preset())

configureAmplify()

if (Env.requireString(EnvKey.Stage) === "test") {
  worker.start() // {onUnhandledRequest: "bypass",}
}

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
