import { graphql } from "msw"

export const getPlatformIntegrationMock = graphql.query("GetPlatformIntegration", (req, res, ctx) => {
  return res(
    ctx.data({
      getPlatformIntegration: {
        clientId: 1,
        name: "Dv360 C1-1",
        platform: "DV360",
        platformIntegrationId: 1,
        state: "Active",
        __typename: "PlatformIntegration",
        platformParams: {
          dv360: {
            partnerId: 6144431,
            __typename: "Dv360PlatformIntegrationParams",
          },
          __typename: "PlatformIntegrationParams",
        },
      },
    })
  )
})
