import { gql } from "@apollo/client"

export const GET_PLATFORM_INTEGRATIONS = gql`
  query GetPlatformIntegrations($clientId: Int!) {
    getPlatformIntegrations(clientId: $clientId) {
      clientId
      name
      platform
      platformIntegrationId
      state
    }
  }
`
