import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import IntegerInputUI, { IntegerInputUIProps } from "./IntegerInputUI"

interface IntegerInputProps extends Omit<IntegerInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const IntegerInput: FC<IntegerInputProps> = ({
  max = 2147483647,
  hasStepper = true,
  size = "sm",
  control,
  name,
  isDisabled = false,
  isReadOnly,
  label,
  placeholder,
  helperText = "",
  tooltip = "",
  width,
  variant,
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <IntegerInputUI //
      hasStepper={hasStepper}
      max={max}
      field={field}
      name={name}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      isReadOnly={isReadOnly}
      label={label}
      placeholder={placeholder}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
      width={width}
      variant={variant}
    />
  )
}

export default IntegerInput
