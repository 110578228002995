import React, { FC } from "react"
import IntegrationSetupComplete from "../../../components/integrations/IntergrationSetupComplete"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import DV360SetupData, { IntegrationVerifyBlockStepData } from "./DV360SetupData"

type IntegrationSetupCompleteBlockProps = WizardStepProps<DV360SetupData, object>

const IntegrationSetupCompleteBlock: FC<IntegrationSetupCompleteBlockProps> = ({ stepIndex, wizardData, onBack }) => {
  const prevStepData = wizardData[stepIndex - 1] as IntegrationVerifyBlockStepData
  const success = prevStepData?.success || false

  return <IntegrationSetupComplete onBack={onBack} success={success} />
}

export default IntegrationSetupCompleteBlock
