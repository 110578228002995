export const numberOrThrow = (value: null | undefined | number): never | number => {
  if (value === null) throw Error(`Value is null`)
  if (value === undefined) throw Error(`Value is undefined`)

  return value
}

export const stringOrThrow = (value: null | undefined | string): never | string => {
  if (value === null) throw Error(`Value is null`)
  if (value === undefined) throw Error(`Value is undefined`)

  return value
}
