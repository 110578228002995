import { FC } from "react"
import { Box, BoxProps, Text } from "@chakra-ui/react"
import colors from "../../../theme/colors"

interface Props extends BoxProps {
  message?: string
  size?: "small" | "medium"
}

const ErrorText: FC<Props> = ({ message, size = "medium", ...rest }) => {
  const fontSize = size === "medium" ? "1.2rem" : "1rem"
  return (
    <Box {...rest}>
      <Text fontSize={fontSize} as="b" color={colors.colors.alerts.warning}>
        {message}
      </Text>
    </Box>
  )
}

export default ErrorText
