import { gql } from "@apollo/client"

export const UPDATE_PLATFORM_CAMPAIGN = gql`
  mutation UpdatePlatformCampaign(
    $clientId: Int!
    $platform: Platform!
    $platformIntegrationId: Int!
    $platformCampaignId: ID!
    $updateInput: PlatformCampaignUpdateInput!
  ) {
    updatePlatformCampaign(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformCampaignId: $platformCampaignId
      updateInput: $updateInput
    ) {
      platformCampaignId {
        id
      }
    }
  }
`
