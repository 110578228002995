import { useQuery } from "@apollo/client"
import { GET_PLATFORM_INTEGRATION } from "../../graphql"
import { useAdvertiserParams } from "../navigationHooks"

export const useCurrentPlatformIntegration = () => {
  const { integrationId, clientId } = useAdvertiserParams()
  const { loading, error, data } = useQuery(GET_PLATFORM_INTEGRATION, {
    variables: {
      clientId: Number(clientId),
      platformIntegrationId: Number(integrationId),
    },
    fetchPolicy: "no-cache",
  })

  return {
    loading,
    error,
    platformIntegration: data?.getPlatformIntegration,
  }
}
