import { FC, ReactNode } from "react"
import { Flex } from "@chakra-ui/react"
import colors from "../../../../theme/colors"

export type DataDisplayAreaProps = Readonly<{
  children: ReactNode
  hidden: boolean
  textSize?: "small" | "regular"
}>

const TrendGraphMetricsDisplayArea: FC<DataDisplayAreaProps> = ({ children, hidden = false, textSize = "regular" }) => {
  const fontSize = textSize === "small" ? "0.75rem" : "1rem"

  return (
    <Flex
      borderRadius="0px 0px 4px 4px"
      borderColor={colors.colors.brand["200"]}
      border="2px solid"
      bg={colors.colors.brand["900"]}
      fontSize={fontSize}
      justify="center"
      direction="row"
      w="100%"
      m={0}
      height={`calc(${fontSize} + 0.75rem)`}
      visibility={hidden ? "hidden" : "inherit"}
    >
      {children}
    </Flex>
  )
}

export default TrendGraphMetricsDisplayArea
