import { Auth } from "@aws-amplify/auth"
import { useRecoilState } from "recoil"
import authState from "../atoms/authState"

// TODO: migrate prop drilled components using AuthenticatedProps to useAuth hook. drilled props don't get the full
//  user profile

const useAuth = () => {
  const [state] = useRecoilState(authState)

  return {
    ...state,
    signOut: () => Auth.signOut(),
  }
}

export default useAuth
