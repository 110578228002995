import { chakraComponents, ChakraStylesConfig, OptionBase } from "chakra-react-select"
import { DropdownIndicatorProps } from "react-select"
import { DropDownIcon } from "../../../icons"
import colors from "../../../theme/colors"

export interface Option extends OptionBase {
  label: string
  value: string
}

export const selectComponents = {
  DropdownIndicator: (props: DropdownIndicatorProps) => (
    <chakraComponents.DropdownIndicator {...props}>
      <DropDownIcon />
    </chakraComponents.DropdownIndicator>
  ),
}

type GetSelectChakraStylesProps = {
  isInModal?: boolean
  width: number | string
}

export const getSelectChakraStyles = ({ isInModal = false, width }: GetSelectChakraStylesProps): ChakraStylesConfig => ({
  container: (provided, state) => ({
    ...provided,
    borderColor: isInModal ? colors.colors.brand["700"] : "inherit",
    borderRadius: "0px",
    width: width,
    "> div": {
      width: width,
      borderRadius: "0px",
      marginBottom: "0px",
      ":hover": {
        borderColor: isInModal ? colors.colors.brand["700"] : colors.colors.brand["200"],
      },
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    bg: isInModal ? colors.colors.brand["200"] : colors.colors.brand["700"],
    borderColor: isInModal ? colors.colors.brand["200"] : colors.colors.brand["700"],
    "> svg": {
      height: "12px",
      width: "12px",
      transform: `rotate(${state.selectProps.menuIsOpen ? -180 : 0}deg)`,
      transition: "transform 0.3s",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    borderColor: isInModal ? colors.colors.brand["700"] : colors.colors.brand["200"],
    borderRadius: "0px",
  }),
  inputContainer: (provided, state) => ({
    ...provided,
    borderColor: isInModal ? colors.colors.brand["700"] : colors.colors.brand["200"],
    borderRadius: "0px",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    marginTop: "-3px",
    width: width,
    "> div": {
      width: width,
      minWidth: width,
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    bg: isInModal ? colors.colors.brand["200"] : colors.colors.brand["700"],
    borderColor: isInModal ? colors.colors.brand["700"] : colors.colors.brand["200"],
    borderRadius: "0px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: colors.colors.brand["700"],
    },
    "::-webkit-scrollbar-thumb": {
      background: colors.colors.brand["200"],
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    bg: state.isFocused ? "white" : isInModal ? colors.colors.brand["200"] : colors.colors.brand["700"],
    color: state.isFocused ? colors.colors.brand["700"] : isInModal ? colors.colors.brand["700"] : colors.colors.brand["200"],
    width: width,
  }),
})
