import { useEffect, useState } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import { Auth } from "@aws-amplify/auth"
import { Hub } from "aws-amplify"
import { useRecoilState, useResetRecoilState } from "recoil"
import authState from "../atoms/authState"
import permissionsState from "../atoms/permissionsState"
import { log, logError } from "../log"
import { AuthStage } from "../models/AuthState"
import { StringKeyObject } from "../types"

const GET_USER_PROFILE = gql`
  query GetCurrentUserProfile {
    getCurrentUserProfile {
      name {
        first
        last
      }
      roles {
        roleId
        name
        isAdmin
      }
    }
  }
`

// TODO: introduce error lifecycle stage and show error page when login fails
enum LifecycleStage {
  NotRegistered = "NotRegistered",
  Registered = "Registered",
  UserSignedIn = "UserSignedIn",
  UserProfileLoading = "UserProfileLoading",
  UserAuthenticated = "UserAuthenticated",
  UserSignedOut = "UserSignedOut",
}

const useAuthLifecycle = () => {
  const [stage, setStage] = useState(LifecycleStage.NotRegistered)
  const [state, setState] = useRecoilState(authState)
  const resetAuthState = useResetRecoilState(authState)
  const resetPermissionsState = useResetRecoilState(permissionsState)

  const [loadUserProfileQuery] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: "network-only",
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUserSignedIn = async (user: StringKeyObject<any>) => {
    log("id token", user?.signInUserSession?.idToken?.jwtToken)

    // On first sign in the user attributes are at user.challengeParam.userAttributes
    const attributes = user?.challengeParam?.userAttributes ? user.challengeParam.userAttributes : user.attributes

    const clientId = attributes["custom:clientId"]

    const identity = (await Auth.currentSession()).getIdToken().payload
    const groups = new Set(identity["cognito:groups"] || [])

    setState({
      authStage: AuthStage.LoadingProfile,
      user: {
        clientId: clientId,
        uid: attributes["custom:uid"],
        sub: user.username,
        email: attributes.email,
        is59A: groups.has("59A"),
        // Loaded from profile attributes:
        name: { first: "", last: "" },
        roles: [],
      },
    })
    setStage(LifecycleStage.UserSignedIn)
  }

  const getCurrentAuthStatus = () => {
    // Get the current state of authentication (in case the user is already logged in from a previous session)
    Auth.currentAuthenticatedUser()
      .then((data) => onUserSignedIn(data))
      .catch(() => undefined)
  }

  const loadUserProfile = () => {
    if (stage === LifecycleStage.UserSignedIn) {
      setStage(LifecycleStage.UserProfileLoading)

      loadUserProfileQuery()
        .then(({ data }) => {
          const { name, roles } = data.getCurrentUserProfile
          setState({
            ...state,
            authStage: AuthStage.Authenticated,
            user: {
              // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
              ...state.user!,
              name,
              roles,
            },
          })
          setStage(LifecycleStage.UserAuthenticated)
        })
        .catch(logError)
    }
  }

  const onUserSignedOut = () => {
    resetPermissionsState()
    resetAuthState()
  }

  const registerLifecycleListener = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const authListener = (data: StringKeyObject<any>) => {
      switch (data.payload.event) {
        case "signIn":
          log("user signed in")
          onUserSignedIn(data.payload.data).then((r) => Promise.resolve())
          break
        case "signOut":
          onUserSignedOut()
          break
      }
    }

    // Register a listener for future auth events
    log("Starting auth event listener.. ")
    Hub.listen("auth", authListener)
    setStage(LifecycleStage.Registered)
  }

  useEffect(() => {
    log("Lifecycle stage updated", stage)
    switch (stage) {
      case LifecycleStage.NotRegistered:
        registerLifecycleListener()
        break
      case LifecycleStage.Registered:
        getCurrentAuthStatus()
        break
      case LifecycleStage.UserSignedIn:
        loadUserProfile()
        break
      case LifecycleStage.UserSignedOut:
        onUserSignedOut()
        break
    }
    // eslint-disable-next-line
  }, [stage])
}

export default useAuthLifecycle
