import React, { FC } from "react"
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react"
import colors from "../../theme/colors"

export interface ButtonUIProps extends ButtonProps {
  isFullWidth?: boolean
  isInline?: boolean
  isSecondary?: boolean
  size?: "sm" | "md" | "lg"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonRef?: any
}

const ButtonUI: FC<ButtonUIProps> = ({
  onClick,
  size = "md",
  children,
  isFullWidth = false,
  isInline = false,
  isSecondary = false,
  variant,
  buttonRef = null,
  ...rest
}) => {
  const height = isInline ? 6 : undefined
  const width = isFullWidth ? "100%" : undefined

  const bgColor = isSecondary ? colors.colors.brand["900"] : colors.colors.brand["400"]
  const color = isSecondary ? "white" : "white"
  const border = isSecondary ? `solid 1px ${colors.colors.brand["200"]}` : "none"
  const hoverColor = isSecondary ? colors.colors.brand["400"] : colors.colors.brand["700"]
  return (
    <ChakraButton
      ref={buttonRef}
      onClick={onClick}
      size={size}
      bg={bgColor}
      border={border}
      borderRadius={0}
      color={color}
      sx={{ fontSize: "1.1rem", _hover: { color: hoverColor } }}
      height={height}
      width={width}
      variant={variant}
      {...rest}
    >
      {children}
    </ChakraButton>
  )
}

export default ButtonUI
