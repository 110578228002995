import { IconBase, IconBaseProps } from "../IconBase"

export const CpaGbpIcon = ({ viewBox = "0 0 268 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M154.722 179.515H113.26C111.284 179.515 109.684 181.12 109.684 183.101C109.684 185.083 111.284 186.688 113.26 186.688H154.722C156.698 186.688 158.299 185.083 158.299 183.101C158.299 181.12 156.698 179.515 154.722 179.515Z"
        fill="currentColor"
      />
      <path
        d="M244.466 160.614C243.505 159.421 242.162 158.36 240.569 157.446C243.178 155.235 244.876 151.976 244.876 148.292V12.0286C244.876 5.39334 239.491 0 232.881 0H35.1146C28.4976 0 23.119 5.40031 23.119 12.0286V148.285C23.119 151.969 24.8168 155.235 27.426 157.439C25.8326 158.36 24.4828 159.414 23.5296 160.614L1.612 188.041C-0.0857435 190.162 -0.461466 192.625 0.575269 194.795C2.18256 198.151 6.80958 200 13.6005 200H254.395C261.186 200 265.813 198.151 267.42 194.795C268.464 192.625 268.088 190.162 266.383 188.034L244.459 160.607L244.466 160.614ZM30.2718 12.0356C30.2718 9.35636 32.4427 7.17949 35.1076 7.17949H232.874C235.545 7.17949 237.709 9.35636 237.709 12.0356V148.292C237.709 150.972 235.545 153.148 232.874 153.148H35.1006C32.4288 153.148 30.2649 150.972 30.2649 148.299V12.0356H30.2718ZM254.395 192.841H13.6005C10.4973 192.841 8.61867 192.346 7.66543 191.948L29.1099 165.114C30.7102 163.119 37.6473 160.328 45.3149 160.328H222.68C230.348 160.328 237.285 163.119 238.885 165.114L260.316 191.955C259.356 192.353 257.484 192.848 254.395 192.848V192.841Z"
        fill="currentColor"
      />
      <path
        d="M133.655 35C108.441 35 88 55.4049 88 80.575C88 105.745 108.441 126.15 133.655 126.15C158.869 126.15 179.31 105.745 179.31 80.575C179.31 55.4049 158.869 35 133.655 35ZM150.396 104.388H113.019L114.395 94.0101C121.122 93.7048 122.498 86.9131 122.498 82.1055H116.841V71.4218H122.651C122.651 57.991 131.366 48.6047 145.508 48.452L146.961 59.6699C138.628 60.433 135.417 64.0959 135.417 71.4218H146.655V82.1055H134.729C134.729 85.3106 134.729 92.1786 130.754 94.0864C130.754 94.239 130.831 94.3153 130.831 94.3916C133.659 93.6285 136.946 93.3233 142.145 93.3233H150.401V104.388H150.396Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
)
