import { graphql } from "msw"

export const getPermissionsMock = graphql.query("GetPermissions", (req, res, ctx) => {
  return res(
    ctx.data({
      getPermissions: [
        {
          resourceUri: "rn:cm::client::1",
          actions: ["CREATE", "UPDATE", "DELETE", "VIEW"],
          __typename: "PermissionActions",
        },
        {
          resourceUri: "rn:cm::user::client/1/user/*",
          actions: ["MANAGE"],
          __typename: "PermissionActions",
        },
        {
          resourceUri: "rn:cm::platform-integration::client/1/integration/*",
          actions: ["CREATE", "UPDATE", "DELETE", "VIEW"],
          __typename: "PermissionActions",
        },
        {
          resourceUri: "rn:cm::platform-advertiser::client/1/integration/*/platform/*/advertiser/*",
          actions: ["UPDATE", "VIEW"],
          __typename: "PermissionActions",
        },
        {
          resourceUri: "rn:cm::platform-campaign::client/1/integration/*/platform/*/advertiser/*/campaign/*",
          actions: ["UPDATE", "VIEW", "REPORTING"],
          __typename: "PermissionActions",
        },
      ],
    })
  )
})
