import { graphql } from "msw"

export const getCurrentUserProfileMock = graphql.query("GetCurrentUserProfile", (req, res, ctx) => {
  return res(
    ctx.data({
      getCurrentUserProfile: {
        __typename: "UserProfileWithRoles",
        name: {
          first: "Test",
          last: "User",
        },
        roles: [
          {
            __typename: "UserProfileRole",
            roleId: 1,
            name: "Administrator",
            isAdmin: true,
          },
        ],
      },
    })
  )
})
