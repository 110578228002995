import React from "react"
import { Button, ButtonProps } from "@chakra-ui/react"
import { Calendar, GetBackForwardPropsOptions } from "dayzed"
import { LeftArrowIcon, RightArrowIcon } from "../../../icons"
import { DatepickerProps } from "../utils/commonTypes"

export interface DatepickerBackBtnsProps extends DatepickerProps {
  calendars: Calendar[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>
}

const DefaultBtnStyle: ButtonProps = {
  variant: "ghost",
  size: "sm",
}

export const DatepickerBackBtns: React.FC<DatepickerBackBtnsProps> = (props) => {
  const { calendars, getBackProps } = props
  const customBtnProps = props.propsConfigs?.dateNavBtnProps
  return (
    <>
      <Button {...getBackProps({ calendars })} {...DefaultBtnStyle} {...customBtnProps}>
        <LeftArrowIcon />
      </Button>
    </>
  )
}

export interface DatepickerForwardBtnsProps extends DatepickerProps {
  calendars: Calendar[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>
}

export const DatepickerForwardBtns: React.FC<DatepickerForwardBtnsProps> = (props) => {
  const { calendars, getForwardProps } = props
  const customBtnProps = props.propsConfigs?.dateNavBtnProps
  return (
    <>
      <Button {...getForwardProps({ calendars })} {...DefaultBtnStyle} {...customBtnProps}>
        <RightArrowIcon />
      </Button>
    </>
  )
}
