import { IconBase, IconBaseProps } from "../IconBase"

export const CpvGbpIcon = ({ viewBox = "0 0 220 181", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_415_1067)">
        <path d="M171.108 47.7407L41.5801 177.823" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
        <path
          d="M173.913 176.672C149.637 176.672 130.405 156.374 129.605 155.505L127.838 153.611L129.605 151.716C130.411 150.853 149.637 130.556 173.913 130.556C198.189 130.556 217.421 150.853 218.227 151.716L219.994 153.611L218.227 155.505C217.421 156.368 198.195 176.672 173.913 176.672ZM135.568 153.611C140.838 158.496 156.191 171.133 173.913 171.133C191.635 171.133 206.993 158.502 212.258 153.617C206.987 148.731 191.635 136.095 173.913 136.095C156.149 136.095 140.832 148.725 135.568 153.611Z"
          fill="currentColor"
        />
        <path
          d="M169.376 167.296C169.065 167.296 168.755 167.224 168.469 167.075C167.824 166.733 167.418 166.067 167.418 165.33V141.676C167.418 140.944 167.824 140.273 168.469 139.937C169.113 139.601 169.895 139.649 170.492 140.063L187.48 151.89C188.005 152.256 188.321 152.861 188.321 153.503C188.321 154.144 188.005 154.75 187.48 155.115L170.492 166.943C170.158 167.176 169.764 167.296 169.376 167.296ZM171.334 145.428V161.578L182.931 153.497L171.334 145.422V145.428Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M45.575 0C20.4049 0 0 20.4049 0 45.575C0 70.7451 20.4049 91.15 45.575 91.15C70.7451 91.15 91.15 70.7451 91.15 45.575C91.15 20.4049 70.7451 0 45.575 0ZM62.2872 69.3885H24.9751L26.3487 59.0101C33.0641 58.7048 34.4377 51.9131 34.4377 47.1055H28.7907V36.4218H34.5904C34.5904 22.991 43.2899 13.6047 57.4075 13.452L58.8575 24.6699C50.5395 25.433 47.3344 29.0959 47.3344 36.4218H58.5522V47.1055H46.6476C46.6476 50.3106 46.6476 57.1786 42.6794 59.0864C42.6794 59.239 42.7557 59.3153 42.7557 59.3916C45.5792 58.6285 48.8606 58.3233 54.0498 58.3233H62.2915V69.3885H62.2872Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1067">
          <rect width="220" height="181" fill="white" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
