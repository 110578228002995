import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { Auth } from "aws-amplify"
import Env, { EnvKey } from "./Env"

const apiLink = createHttpLink({
  uri: Env.requireString(EnvKey.GraphqlApiUrl),
  credentials: "same-origin",
})

const authMiddleware = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  }
})

export default new ApolloClient({
  link: from([authMiddleware, apiLink]),
  cache: new InMemoryCache(),
})
