import { gql } from "@apollo/client"
import { PLATFORM_CAMPAIGN_FRAGMENT } from "../fragments"

export const SET_PLATFORM_CAMPAIGN_MANAGED = gql`
  mutation SetPlatformCampaignManaged($clientId: Int!, $platform: Platform!, $platformIntegrationId: Int!, $platformCampaignId: ID!, $managed: Boolean!) {
    setPlatformCampaignManaged(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformCampaignId: $platformCampaignId
      managed: $managed
    ) {
      ...PlatformCampaignFragment
    }
  }
  ${PLATFORM_CAMPAIGN_FRAGMENT}
`
