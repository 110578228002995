import { gql } from "@apollo/client"

export const GET_PLATFORM_INTEGRATION = gql`
  query GetPlatformIntegration($clientId: Int!, $platformIntegrationId: Int!) {
    getPlatformIntegration(clientId: $clientId, platformIntegrationId: $platformIntegrationId) {
      name
      platformIntegrationId
      state
      platform
      platformParams {
        dv360 {
          partnerId
        }
        facebook {
          adAccountId
        }
      }
    }
  }
`
