import { IconBase, IconBaseProps } from "../IconBase"

export const ConversionsEurIcon = ({ viewBox = "0 0 160 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M56.1906 50.5237C54.1813 51.9368 51.7227 52.7719 49.0717 52.7719C46.4207 52.7719 43.898 51.9173 41.8739 50.4703H41.785C31.7682 52.3543 31.4918 62.6629 31.4918 62.6629C31.4918 62.6629 36.3348 72.0926 48.6274 72.5781C60.9246 73.0686 65.7632 62.6677 65.7632 62.6677C65.368 53.4565 57.686 50.9462 56.1955 50.5237H56.1906Z"
        fill="currentColor"
      />
      <path
        d="M58.7954 40.7103C58.7954 45.9933 54.4366 50.2808 49.0654 50.2808C43.6942 50.2808 39.3351 45.9933 39.3351 40.7103C39.3351 35.4274 43.6893 31.1398 49.0654 31.1398C54.4416 31.1398 58.7954 35.4225 58.7954 40.7103Z"
        fill="currentColor"
      />
      <path
        d="M118.159 51.5229C116.149 52.9359 113.691 53.771 111.04 53.771C108.389 53.771 105.866 52.9165 103.842 51.4695H103.753C93.7362 53.3535 93.4598 63.6621 93.4598 63.6621C93.4598 63.6621 98.3027 73.0918 110.595 73.5773C122.893 74.0677 127.731 63.6669 127.731 63.6669C127.336 54.4557 119.654 51.9454 118.163 51.5229H118.159Z"
        fill="currentColor"
      />
      <path
        d="M120.763 41.7095C120.763 46.9925 116.405 51.28 111.033 51.28C105.662 51.28 101.303 46.9925 101.303 41.7095C101.303 36.4265 105.657 32.139 111.033 32.139C116.409 32.139 120.763 36.4217 120.763 41.7095Z"
        fill="currentColor"
      />
      <path
        d="M24.6988 19.9995C22.6895 21.3794 20.2309 22.1949 17.5799 22.1949C14.9289 22.1949 12.4062 21.3603 10.3821 19.9473H10.2932C0.276418 21.7871 0 31.854 0 31.854C0 31.854 4.84294 41.0627 17.1355 41.5368C29.4328 42.0158 34.2714 31.8587 34.2714 31.8587C33.8762 22.8635 26.1942 20.412 24.7036 19.9995H24.6988Z"
        fill="currentColor"
      />
      <path
        d="M27.3035 10.4162C27.3035 15.5753 22.9448 19.7623 17.5736 19.7623C12.2024 19.7623 7.8432 15.5753 7.8432 10.4162C7.8432 5.25706 12.1974 1.07004 17.5736 1.07004C22.9497 1.07004 27.3035 5.25232 27.3035 10.4162Z"
        fill="currentColor"
      />
      <path
        d="M87.0564 20.0668C85.0471 21.4467 82.5885 22.2622 79.9375 22.2622C77.2865 22.2622 74.7638 21.4276 72.7397 20.0146H72.6508C62.634 21.8544 62.3576 31.9213 62.3576 31.9213C62.3576 31.9213 67.2005 41.1299 79.4931 41.6041C91.7904 42.0831 96.629 31.926 96.629 31.926C96.2338 22.9308 88.5518 20.4793 87.0612 20.0668H87.0564Z"
        fill="currentColor"
      />
      <path
        d="M89.6611 10.4835C89.6611 15.6426 85.3023 19.8295 79.9311 19.8295C74.56 19.8295 70.2008 15.6426 70.2008 10.4835C70.2008 5.32435 74.555 1.13733 79.9311 1.13733C85.3073 1.13733 89.6611 5.31961 89.6611 10.4835Z"
        fill="currentColor"
      />
      <path
        d="M150.428 19.2195C148.394 20.5655 145.921 21.3397 143.27 21.2953C140.62 21.2508 138.112 20.3741 136.114 18.9273L136.025 18.9258C125.977 20.5975 125.518 30.6583 125.518 30.6583C125.518 30.6583 130.192 39.9467 142.474 40.6268C154.761 41.3118 159.784 31.2374 159.784 31.2374C159.552 22.2369 151.916 19.6571 150.433 19.2196L150.428 19.2195Z"
        fill="currentColor"
      />
      <path
        d="M152.837 9.6712C152.743 14.8296 148.309 18.9427 142.939 18.8526C137.569 18.7625 133.286 14.5031 133.38 9.34471C133.473 4.18639 137.903 0.0730508 143.278 0.163247C148.654 0.253443 152.931 4.50813 152.837 9.6712Z"
        fill="currentColor"
      />
      <path
        d="M86.6666 81.4982C84.6573 82.9112 82.1987 83.7463 79.5477 83.7463C76.8967 83.7463 74.374 82.8917 72.3499 81.4447H72.261C62.2443 83.3287 61.9678 93.6373 61.9678 93.6373C61.9678 93.6373 66.8108 103.067 79.1034 103.553C91.4006 104.043 96.2392 93.6421 96.2392 93.6421C95.844 84.4309 88.162 81.9206 86.6715 81.4982H86.6666Z"
        fill="currentColor"
      />
      <path
        d="M89.2714 71.6848C89.2714 76.9678 84.9126 81.2552 79.5414 81.2552C74.1702 81.2552 69.811 76.9678 69.811 71.6848C69.811 66.4018 74.1652 62.1143 79.5414 62.1143C84.9176 62.1143 89.2714 66.3969 89.2714 71.6848Z"
        fill="currentColor"
      />
      <path
        d="M125 154.494C125 179.615 104.621 200 79.494 200C54.3666 200 34 179.615 34 154.494C34 129.373 54.3666 109 79.494 109C104.621 109 125 129.367 125 154.494ZM95.1417 136.858L99.4713 128.241C84.9614 120.48 66.9243 125.929 59.1633 140.421C58.1213 142.332 57.3129 144.35 56.744 146.44H50.1148L47.0847 152.458H55.702C55.6122 153.787 55.6002 155.117 55.702 156.446H50.1208L47.0907 162.465H56.7081C61.1155 178.304 77.5357 187.58 93.3691 183.172C95.4771 182.604 97.537 181.783 99.4773 180.741L95.1477 172.13C94.5489 172.459 93.962 172.741 93.3691 172.992C83.1709 177.394 71.2899 172.681 66.9004 162.465H82.2786L85.2908 156.446H65.3554C65.2236 155.129 65.2236 153.787 65.3554 152.458H87.2849L90.3031 146.44H66.9243C67.1938 145.877 67.4573 145.308 67.7567 144.757C73.1343 135.02 85.3986 131.486 95.1358 136.858H95.1417Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
)
