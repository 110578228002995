import React, { FC, useEffect } from "react"
import { ApolloError } from "@apollo/client"
import { Box, FormErrorMessage, Text } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import Button from "../../../components/Button"
import PasswordInput from "../../../components/forms/PasswordInput"
import TextInput from "../../../components/forms/TextInput"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { Optional } from "../../../types"
import XandrSetupData, { IntegrationDetailsFormStepData } from "./XandrSetupData"

export interface FormValues {
  name?: string
  username?: string
  password?: string
}

export interface IntegrationDetailsFormProps extends WizardStepProps<XandrSetupData, IntegrationDetailsFormStepData> {
  onSubmit: (values: FormValues) => Promise<void>
  queryDetails?: { data?: unknown; loading?: boolean; error?: ApolloError }
}

const schema = z.object({
  name: z
    .string({
      required_error: "Integration name is required",
      invalid_type_error: "Integration name must be a string",
    })
    .trim(),
  username: z
    .string({
      required_error: "Username is required",
      invalid_type_error: "Username must be a string",
    })
    .trim(),
  password: z
    .string({
      required_error: "Password is required",
      invalid_type_error: "Password must be a string",
    })
    .trim(),
})

const IntegrationDetailsForm: FC<IntegrationDetailsFormProps> = ({ onNext, stepIndex, wizardData, onSubmit, queryDetails = {} }) => {
  const navigate = useNavigate()
  const { loading, error } = queryDetails
  const stepData = wizardData[stepIndex] as Optional<IntegrationDetailsFormStepData>

  const initialValues = getInitialFormValues(stepData)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    // TODO: notify user?
    if (error) logError("Apollo Error", error)
  }, [error])

  const submitAndNext = (data: FormValues) =>
    onSubmit(data) //
      .then(() => onNext(buildStepData(data)))

  return (
    <Box>
      <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <Text fontSize="xl" paddingBottom={6}>
        Please enter your Xandr username and password to allow 59A to connect to the Xandr platform.
      </Text>
      <form onSubmit={handleSubmit(submitAndNext)}>
        <TextInput
          width={80}
          placeholder="Name for this Xandr integration"
          name="name"
          label="Xandr Integration Name"
          helperText="This is a name to help you identify this Xandr integration"
          tooltip="This is a name to help you identify this Xandr integration"
          control={control}
          variant="flushed"
        />
        <TextInput //
          placeholder="Please enter username"
          name="username"
          label="Username"
          helperText="This is your Xandr username, it should be an email."
          tooltip="This is your Xandr username, it should be an email."
          control={control}
        />

        <PasswordInput //
          name="password"
          label="Password"
          placeholder="Please enter password"
          helperText="This is the password for your Xandr account."
          tooltip="This is the password for your Xandr account."
          control={control}
        />
        {error && <FormErrorMessage>Something went wrong, please try again.</FormErrorMessage>}
        <Box py={3}>
          <Button aria-label="Submit Xandr Integration Details" type="submit" isLoading={loading} isDisabled={loading}>
            Connect
          </Button>
          <Button aria-label="Navigate Back" type="button" onClick={() => navigate(-1)} isSecondary={true} ml={3}>
            Back
          </Button>
        </Box>
      </form>
    </Box>
  )
}

const getInitialFormValues = (data: Optional<IntegrationDetailsFormStepData>): FormValues => {
  return {
    name: data?.name,
    username: data?.username,
    password: data?.password,
  }
}

const buildStepData = (values: FormValues): IntegrationDetailsFormStepData => ({
  name: values.name,
  username: values.username,
  password: values.password,
})

export default IntegrationDetailsForm
