import { IconBase, IconBaseProps } from "../IconBase"

export const FrequencyIcon = ({ viewBox = "0 0 300 150", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <g clipPath="url(#clip0_415_1079)">
        <path
          d="M149.992 150C70.9697 150 8.37887 83.9712 5.75301 81.1605L0 74.996L5.75301 68.8315C8.37887 66.0208 70.9697 0 149.992 0C229.014 0 291.605 66.0208 294.239 68.8315L300 74.996L294.239 81.1605C291.613 83.9792 229.022 150 149.992 150V150ZM25.1843 74.988C42.3399 90.8784 92.3108 131.97 149.992 131.97C207.673 131.97 257.676 90.8943 274.808 75.004C257.652 59.1137 207.673 18.0224 149.992 18.0224C92.1755 18.0224 42.3161 59.0977 25.1843 74.988V74.988Z"
          fill="currentColor"
        />
        <path
          d="M149.857 24.9534C121.593 24.9534 98.8281 48.6132 100.157 77.2717C101.343 102.848 122.039 123.601 147.534 124.767C176.076 126.069 199.613 103.239 199.613 74.8921C199.613 47.3196 177.341 24.9614 149.857 24.9614V24.9534ZM108.862 71.4586H119.31C121.013 56.0314 134.03 44.0298 149.857 44.0298C159.056 44.0298 167.291 48.1101 172.933 54.5381L168.684 60.1357C164.307 54.5142 157.504 50.889 149.857 50.889C137.834 50.889 127.88 59.8482 126.217 71.4586H136.195L122.532 89.457L108.87 71.4586H108.862ZM180.413 78.2459C178.742 93.705 165.7 105.747 149.857 105.747C140.643 105.747 132.391 101.65 126.75 95.2062L131.007 89.6087C135.391 95.2461 142.194 98.8953 149.857 98.8953C161.904 98.8953 171.875 89.8961 173.506 78.2539H163.52L177.182 60.2555L190.844 78.2539H180.405L180.413 78.2459Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1079">
          <rect width="300" height="150" fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  </IconBase>
)
