import { atom } from "recoil"
import { resourceActions } from "../../hooks/usePermissionsLifecycle"

type DefaultState = {
  permissions: resourceActions
  loading: boolean
}

const defaultState: DefaultState = {
  permissions: {} as resourceActions,
  loading: true,
}

const permissionsStateAtom = atom({
  key: "permissionsStateAtom",
  default: defaultState,
})

export default permissionsStateAtom
