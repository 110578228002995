import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import SelectInputUI, { SelectInputUIProps } from "./SelectInputUI"

interface SelectInputProps extends Omit<SelectInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const SelectInput: FC<SelectInputProps> = ({
  size = "sm",
  options,
  control,
  name,
  isReadOnly = false,
  isDisabled = false,
  isInModal = false,
  isMulti = false,
  helperText = "",
  tooltip = "",
  label,
  placeholder,
  ...rest
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <SelectInputUI //
      options={options}
      field={field}
      name={name}
      error={error}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      isInvalid={invalid}
      isMulti={isMulti}
      isInModal={isInModal}
      placeholder={placeholder}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
      {...rest}
    />
  )
}

export default SelectInput
