import { FC, useEffect, useMemo, useRef, useState } from "react"
import { ApolloError } from "@apollo/client"
import { Box, Text } from "@chakra-ui/react"
import * as d3 from "d3"
import colors from "../../../theme/colors"
import { StringKeyObject } from "../../../types"
import Tile from "../../Tile"
import TooltipMetricsDisplayArea from "../TooltipMetricsDisplayArea"
import { HoverData, MetricDataSet } from "../types"
import { mapMetricNameToDisplayName } from "../utils"
import StackedBarsWithLinesGraphLoader from "./StackedBarsWithLinesGraphLoader"
import StackedBarsWithLinesGraphNoData from "./StackedBarsWithLinesGraphNoData"

type StackedBarsWithLinesGraphProps = {
  loading?: boolean
  error?: ApolloError
  graphDataSets: StringKeyObject<MetricDataSet>
}

const StackedBarsWithLinesGraph: FC<StackedBarsWithLinesGraphProps> = ({ loading, error, graphDataSets }) => {
  const [tooltip, setTooltip] = useState<HoverData | null>(null)
  const { BARS, LINE_ONE, LINE_TWO } = graphDataSets
  const metricNames = [BARS.name, LINE_ONE.name, LINE_TWO.name]

  const graph = useRef(null)
  const cache = useRef(graphDataSets)

  const data = useMemo(() => graphDataSets, [graphDataSets])

  // Dimensions
  const viewBoxHeight = 333
  const viewBoxWidth = 650
  const graphAreaWidth = 546
  const graphAreaHeight = 272
  const yAxisLegendTextBottom = 130
  const yAxisLegendTextLeftLeft = -45
  const yAxisLegendTextLeftRight = 561
  const numDataPoints = BARS.data.length

  const dataPointSize = 5

  // Constants
  const xAxisLegendAreaGutter = 16
  const yAxisLegendAreaWidth = 45
  const yAxisLegendAreaGutter = 7
  const barsGutter = 4
  const spaceBetweenBarDataPoints = (graphAreaWidth + barsGutter) / numDataPoints // Add the + barsGutter because there is none at the end
  const barWidth = spaceBetweenBarDataPoints - barsGutter
  const graphXOrigin = yAxisLegendAreaWidth + yAxisLegendAreaGutter
  const fastAnimationTime = 300
  const slowAnimationTime = 1000

  const isLineOneDataPresent = LINE_ONE.data.length > 0
  const isLineTwoDataPresent = LINE_TWO.data.length > 0
  const isLineDataPresent = isLineOneDataPresent && isLineTwoDataPresent

  useEffect(() => {
    const groupGraph = d3.select(graph.current)

    const xAxisValues = data?.BARS.data.map((datapoint) => datapoint.date?.toString() ?? "")

    // Reset graph between data changes
    groupGraph.selectAll("*").remove()

    const defs = groupGraph.append("defs")
    const lineOneGradient = defs.append("linearGradient").attr("id", "line-one-gradient").attr("x1", "50%").attr("y1", "0").attr("x2", "50%").attr("y2", "100%")
    lineOneGradient.append("stop").attr("offset", "0%").attr("stop-color", colors.colors.brand["700"])
    lineOneGradient.append("stop").attr("offset", "100%").attr("stop-color", colors.colors.dataVis["600"])
    const lineTwoGradient = defs.append("linearGradient").attr("id", "line-two-gradient").attr("x1", "50%").attr("y1", "0").attr("x2", "50%").attr("y2", "100%")
    lineTwoGradient.append("stop").attr("offset", "0%").attr("stop-color", colors.colors.brand["700"])
    lineTwoGradient.append("stop").attr("offset", "100%").attr("stop-color", colors.colors.dataVis["300"])

    //////////////////
    // Scales for data
    // X Axis Time Scale
    const xScale = d3.scaleBand(xAxisValues, [0, graphAreaWidth])
    // Bar scale
    const maxBarValue = data?.BARS.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a >= b ? a : b), data.BARS.data[0].value)
    const minBarValue = data?.BARS.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a <= b ? a : b), data.BARS.data[0].value)
    const barScale =
      minBarValue === 0 && maxBarValue === 0
        ? d3.scaleLinear([0, 1], [graphAreaHeight, 0]).nice()
        : d3.scaleLinear([minBarValue - minBarValue * 0.01, Number(maxBarValue)], [graphAreaHeight, 0]).nice()

    if (isLineOneDataPresent) {
      // Line One scale
      const maxLineOneValue = data?.LINE_ONE.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a >= b ? a : b), data.LINE_ONE.data[0].value)
      const minLineOneValue = data?.LINE_ONE.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a <= b ? a : b), data.LINE_ONE.data[0].value)
      const lineOneScale =
        maxLineOneValue === 0 && minLineOneValue === 0
          ? d3.scaleLinear([0, 1], [graphAreaHeight, 0]).nice()
          : d3.scaleLinear([minLineOneValue - minLineOneValue * 0.01, Number(maxLineOneValue)], [graphAreaHeight, 0]).nice()

      const yLineOneAxis = d3.axisLeft(lineOneScale).tickSize(0)

      // Add the yLineOneAxis
      const yLineOneAxisGraph = groupGraph.append("g").attr("transform", `translate(${graphXOrigin} 0)`).call(yLineOneAxis)
      yLineOneAxisGraph
        .select("path")
        .style("stroke", colors.colors.brand["700"])
        .style("stroke-width", 1)
        .style("opacity", "0.5")
        .style("stroke-linecap", "round")
      yLineOneAxisGraph.selectAll("text").style("display", "none")

      // Draw Line One
      const initialLineOne = d3
        .line()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.LINE_ONE.data.indexOf(d) * spaceBetweenBarDataPoints + barWidth / 2 + graphXOrigin)
        .y(() => lineOneScale(minLineOneValue))
      const lineOne = d3
        .line()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.LINE_ONE.data.indexOf(d) * spaceBetweenBarDataPoints + barWidth / 2 + graphXOrigin)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .y((d: any) => {
          if (d.value === 0) {
            return lineOneScale(minLineOneValue)
          } else {
            return lineOneScale(d.value)
          }
        })

      // Add Line One
      groupGraph
        .append("path")
        .datum(data.LINE_ONE.data)
        .attr("fill", "none")
        .attr("stroke", colors.colors.dataVis["600"])
        .attr("stroke-width", 3)
        .attr("transform", `translate(0 0)`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", initialLineOne as any) // initial state (line at the bottom)
        .transition()
        .duration(fastAnimationTime)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", lineOne as any)

      // Add the data points for Line One
      groupGraph
        .selectAll()
        .data(data.LINE_ONE.data)
        .enter()
        .append("rect")
        .attr("fill", colors.colors.white)
        .style("stroke", colors.colors.white)
        .style("stroke-width", 1)
        .attr("stroke", "1")
        .attr("x", (d) => data.LINE_ONE.data.indexOf(d) * spaceBetweenBarDataPoints + spaceBetweenBarDataPoints / 2 + graphXOrigin - dataPointSize)
        .attr("y", (d) => lineOneScale(d.value) - dataPointSize / 2)
        .attr("height", dataPointSize)
        .attr("width", dataPointSize)
        .attr("opacity", 0)
        .transition()
        .duration(slowAnimationTime)
        .attr("opacity", 1)
    }

    if (isLineTwoDataPresent) {
      // Line Two scale
      const maxLineTwoValue = data?.LINE_TWO.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a >= b ? a : b), data.LINE_TWO.data[0].value)
      const minLineTwoValue = data?.LINE_TWO.data.map((dataPoint) => dataPoint.value).reduce((a, b) => (a <= b ? a : b), data.LINE_TWO.data[0].value)
      const lineTwoScale =
        maxLineTwoValue === 0 && minLineTwoValue === 0
          ? d3.scaleLinear([0, 1], [graphAreaHeight, 0]).nice()
          : d3.scaleLinear([minLineTwoValue - minLineTwoValue * 0.01, Number(maxLineTwoValue)], [graphAreaHeight, 0]).nice()
      const yLineTwoAxis = d3.axisRight(lineTwoScale).tickSize(0)

      // Add the yLineTwoAxis
      const yLineTwoAxisGraph = groupGraph
        .append("g")
        .attr("transform", `translate(${graphXOrigin + graphAreaWidth} 0)`)
        .call(yLineTwoAxis)
      yLineTwoAxisGraph
        .select("path")
        .style("stroke", colors.colors.brand["700"])
        .style("stroke-width", 1)
        .style("opacity", "0.5")
        .style("stroke-linecap", "round")
      yLineTwoAxisGraph.selectAll("text").style("display", "none")

      // Draw Line Two
      const initialLineTwo = d3
        .line()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.LINE_TWO.data.indexOf(d) * spaceBetweenBarDataPoints + barWidth / 2 + graphXOrigin)
        .y(() => lineTwoScale(minLineTwoValue))
      const lineTwo = d3
        .line()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .x((d: any) => data.LINE_TWO.data.indexOf(d) * spaceBetweenBarDataPoints + barWidth / 2 + graphXOrigin)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .y((d: any) => {
          if (d.value === 0) {
            return lineTwoScale(minLineTwoValue)
          } else {
            return lineTwoScale(d.value)
          }
        })

      // Add Line Two
      groupGraph
        .append("path")
        .datum(data.LINE_TWO.data)
        .attr("fill", "none")
        .attr("stroke", colors.colors.dataVis["300"])
        .attr("stroke-width", 3)
        .attr("transform", `translate(0 0)`)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", initialLineTwo as any) // initial state (line at the bottom)
        .transition()
        .duration(fastAnimationTime)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .attr("d", lineTwo as any)

      // Add the data points for Line Two
      groupGraph
        .selectAll()
        .data(data.LINE_TWO.data)
        .enter()
        .append("rect")
        .attr("fill", colors.colors.white)
        .style("stroke", colors.colors.white)
        .style("stroke-width", 1)
        .attr("stroke", "1")
        .attr("x", (d) => data.LINE_TWO.data.indexOf(d) * spaceBetweenBarDataPoints + spaceBetweenBarDataPoints / 2 + graphXOrigin - dataPointSize)
        .attr("y", (d) => lineTwoScale(d.value) - dataPointSize / 2)
        .attr("height", dataPointSize)
        .attr("width", dataPointSize)
        .attr("opacity", 0)
        .transition()
        .duration(slowAnimationTime)
        .attr("opacity", 1)
    }

    // End scales for data

    // Create the X Axes
    const shortWeekDayFormat = d3.timeFormat("%a")
    const dateFormat = d3.timeFormat("%_d")
    const everyThirdDate = xAxisValues.filter((element, index) => index % 3 === 0)
    const xAxisForDays = d3
      .axisBottom(xScale)
      .tickSize(0)
      .tickPadding(13)
      .tickValues(everyThirdDate)
      .tickFormat((domainValue) => {
        return shortWeekDayFormat(new Date(domainValue)).substring(0, 2)
      })
    const xAxisForDates = d3
      .axisBottom(xScale)
      .tickSize(0)
      .tickPadding(20)
      .tickValues(everyThirdDate)
      .tickFormat((domainValue) => {
        return dateFormat(new Date(domainValue))
      })
    const yBarAxis = d3.axisLeft(barScale).tickSize(0)

    // Add the X Axes
    const xAxisForDaysGraph = groupGraph
      .append("g")
      .attr("transform", `translate(${graphXOrigin} ${graphAreaHeight + xAxisLegendAreaGutter})`)
      .call(xAxisForDays)
    xAxisForDaysGraph.select("path").style("stroke", colors.colors.brand["200"]).style("stroke-width", 1).style("stroke-linecap", "round")
    xAxisForDaysGraph.selectAll("text").style("font-size", "1rem")
    const xAxisForDatesGraph = groupGraph
      .append("g")
      .attr("transform", `translate(${graphXOrigin} ${graphAreaHeight + xAxisLegendAreaGutter * 2})`)
      .call(xAxisForDates)
    xAxisForDatesGraph.select("path").style("display", "none")
    xAxisForDatesGraph.selectAll("text").style("font-size", "0.9rem")

    // Add the yBarAxis
    const yBarAxisGraph = groupGraph.append("g").attr("transform", `translate(${graphXOrigin} 0)`).call(yBarAxis)
    yBarAxisGraph.select("path").style("stroke", colors.colors.brand["700"]).style("stroke-width", 1).style("opacity", "0.5").style("stroke-linecap", "round")
    yBarAxisGraph.selectAll("text").style("display", "none")

    // Draw the key lines for the dates
    const xAxisDateKeyLines = groupGraph
      .selectAll()
      .data(everyThirdDate)
      .enter()
      .append("rect")
      .attr("x", (d) => {
        const index = everyThirdDate.indexOf(d)
        if (index > everyThirdDate.length / 2) {
          return everyThirdDate.indexOf(d) * 3 * spaceBetweenBarDataPoints + graphXOrigin - 3
        }
        return everyThirdDate.indexOf(d) * 3 * spaceBetweenBarDataPoints + graphXOrigin
      })
      .attr("y", (d) => viewBoxHeight - xAxisLegendAreaGutter)
      .attr("width", spaceBetweenBarDataPoints)
      .attr("height", 1)
      .attr("fill", colors.colors.brand["200"])

    // Add X Axis Key Lines
    groupGraph
      .append("g")
      .datum(data.BARS.data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .attr("d", xAxisDateKeyLines as any)

    // Add yLineOneAxis Gradient
    groupGraph
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", yAxisLegendAreaWidth)
      .attr("height", (d) => graphAreaHeight)
      .attr("fill", `url(#line-one-gradient)`)

    // Add yLineTwoAxis Gradient
    groupGraph
      .append("rect")
      .attr("x", graphXOrigin + graphAreaWidth + yAxisLegendAreaGutter)
      .attr("y", 0)
      .attr("width", yAxisLegendAreaWidth)
      .attr("height", (d) => graphAreaHeight)
      .attr("fill", `url(#line-two-gradient)`)

    if (isLineDataPresent) {
      // Add the Bars
      groupGraph
        .selectAll()
        .data(data.BARS.data)
        .enter()
        .append("rect")
        .attr("x", (d) => data.BARS.data.indexOf(d) * spaceBetweenBarDataPoints + graphXOrigin)
        .attr("y", (d) => barScale(d.value))
        .attr("width", barWidth)
        .attr("height", (d) => graphAreaHeight - barScale(d.value))
        .attr("fill", colors.colors.brand["200"])
        .attr("opacity", 0.3)

      // Draw the hover zones
      groupGraph
        .selectAll()
        .data(data.BARS.data)
        .enter()
        .append("rect")
        .on("mouseover", (d) => {
          const index = data.BARS.data.indexOf(d.target.__data__)
          const lineOneData = data.LINE_ONE.data[index]
          const lineOneDataHover = lineOneData ? lineOneData.formattedValue : ""
          const lineTwoData = data.LINE_TWO.data[index]
          const lineTwoDataHover = lineTwoData ? lineTwoData.formattedValue : ""
          setTooltip({
            date: d.target.__data__.date,
            values: [d.target.__data__.formattedValue, lineOneDataHover, lineTwoDataHover],
          })
        })
        .on("mouseout", () => setTooltip(null))
        .attr("x", (d) => data.BARS.data.indexOf(d) * spaceBetweenBarDataPoints + graphXOrigin)
        .attr("y", 0)
        .attr("width", spaceBetweenBarDataPoints)
        .attr("height", (d) => graphAreaHeight)
        .attr("opacity", 0)
    }

    cache.current = data
  }, [data, numDataPoints, barWidth, graphXOrigin, isLineOneDataPresent, isLineTwoDataPresent, isLineDataPresent, spaceBetweenBarDataPoints])

  //const indexOfHovered = xAxisValues.indexOf(tooltip?.date.toString() ?? "")

  return (
    <Tile background="transparent" p={0} loading={loading} loader={<StackedBarsWithLinesGraphLoader />} error={error}>
      <Box display="block" position="relative" m={0} p={0} w={`${viewBoxWidth}px`} h={`${viewBoxHeight}px`}>
        <svg
          style={{
            overflow: "visible",
            left: "0px",
            position: "relative",
            bottom: "0px",
            width: "100%",
            height: "100%",
          }}
          viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        >
          <g ref={graph} />
        </svg>
        {/*<Box*/}
        {/*  opacity={0.3}*/}
        {/*  bg={colors.colors.black}*/}
        {/*  position="absolute"*/}
        {/*  top={0}*/}
        {/*  left={`${yAxisLegendAreaWidth + yAxisLegendAreaGutter}px`}*/}
        {/*  w={`${graphAreaWidth}px`}*/}
        {/*  h={`${graphAreaHeight}px`}*/}
        {/*/>*/}
        {/*<Box*/}
        {/*  opacity={0.3}*/}
        {/*  bg={colors.colors.black}*/}
        {/*  position="absolute"*/}
        {/*  top={0}*/}
        {/*  left={`${yAxisLegendAreaWidth + yAxisLegendAreaGutter}px`}*/}
        {/*  w={`${graphAreaWidth}px`}*/}
        {/*  h={`${graphAreaHeight}px`}*/}
        {/*/>*/}
        <Text
          fontSize="1.3rem"
          as="b"
          color={colors.colors.brand["900"]}
          w={graphAreaHeight / 2}
          style={{ rotate: "270deg" }}
          position="absolute"
          left={yAxisLegendTextLeftLeft}
          bottom={yAxisLegendTextBottom}
          zIndex={100}
        >
          {mapMetricNameToDisplayName[metricNames[1]]}
        </Text>
        <Text
          fontSize="1.3rem"
          as="b"
          color={colors.colors.brand["900"]}
          w={graphAreaHeight / 2}
          style={{ rotate: "270deg" }}
          position="absolute"
          left={yAxisLegendTextLeftRight}
          bottom={yAxisLegendTextBottom}
          zIndex={100}
        >
          {mapMetricNameToDisplayName[metricNames[2]]}
        </Text>
      </Box>
      {!isLineDataPresent && (
        <Box position="absolute" left={`${yAxisLegendAreaWidth + yAxisLegendAreaGutter}px`} w={graphAreaWidth} h={graphAreaHeight}>
          <StackedBarsWithLinesGraphNoData />
        </Box>
      )}
      <TooltipMetricsDisplayArea date={tooltip?.date} metricNames={metricNames} values={tooltip?.values} />
    </Tile>
  )
}

export default StackedBarsWithLinesGraph
