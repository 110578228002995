import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { ApolloQueryResult, OperationVariables, useMutation } from "@apollo/client"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  HStack,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useSetRecoilState } from "recoil"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { UserProfileWithRoles, UserProfileRole } from "../../../generated/graphql"
import { DELETE_USER, GET_USER_PROFILES } from "../../../graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import useSettings from "../../../hooks/useSettings"
import { UserProfile } from "../../../models/AuthState"
import { CustomGraphQLError } from "../../../types"
import Button from "../../Button"
import ErrorText from "../../forms/ErrorText"
import PageTable from "../PageTable"

export interface UserTableRowData {
  clientId: number
  email: string
  firstName: string
  lastName: string
  userId: string
  roles: UserProfileRole[]
}

const toTableRow = (data: UserProfileWithRoles[]): UserTableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    firstName: v.name.first ?? "",
    lastName: v.name.last ?? "",
    roles: v.roles ?? [],
  }))

const columnHelper = createColumnHelper<UserTableRowData>()

interface UsersTableProps {
  user: UserProfile
  data?: UserProfileWithRoles[]
  isLoading: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchGetUserProfiles: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
  onManageRolesOpenHandler: (roles: UserTableRowData) => void
}

const UsersTable: FC<UsersTableProps> = ({ user, data, isLoading, refetchGetUserProfiles, onManageRolesOpenHandler }) => {
  const [userToBeDeleted, setUserToBeDeleted] = useState<
    | {
        clientId: number
        userId: string
        email: string
      }
    | undefined
  >(undefined)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.users)

  useEffect(() => {
    if (pageIndex !== paginationSettings.users.pageIndex || pageSize !== paginationSettings.users.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        users: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const [deleteUser, { error, loading: deleteLoading, reset: resetDeleteUser }] = useMutation(DELETE_USER, {
    refetchQueries: [GET_USER_PROFILES],
  })

  const resetAndClose = () => {
    resetDeleteUser()
    onClose()
  }

  const onDeleteUser = async () => {
    await deleteUser({
      variables: {
        clientId: userToBeDeleted?.clientId,
        userId: userToBeDeleted?.userId,
      },
    })
    resetAndClose()
  }

  const onCloseDeleteDialog = async () => {
    await refetchGetUserProfiles()
    resetAndClose()
  }

  const tableColumns = useMemo(() => {
    const onStartDeleteUser = (clientId: number, userId: string, email: string) => {
      if (userId !== user.uid) {
        setUserToBeDeleted({ clientId, userId, email })
        onOpen()
      }
    }

    return [
      columnHelper.accessor("clientId", {
        id: "clientId",
        cell: (info) => info.getValue(),
        header: () => <span>Client ID</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("email", {
        id: "email",
        cell: (info) => info.getValue(),
        header: () => <span>Email</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("firstName", {
        id: "firstName",
        cell: (info) => info.getValue(),
        header: () => <span>First Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("lastName", {
        id: "lastName",
        cell: (info) => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("roles", {
        id: "roles",
        cell: (info) => {
          const roles = info
            .getValue()
            .map((role) => role.name)
            .join(", ")
          return (
            <Tooltip label={roles} aria-label="User roles">
              <Box noOfLines={1}>{roles}</Box>
            </Tooltip>
          )
        },
        header: () => <span>Roles</span>,
        footer: (props) => props.column.id,
      }),
      columnHelper.display({
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) =>
          row.original.roles.some((role) => role.isAdmin) && !user.roles.some((role) => role.isAdmin) ? (
            <></>
          ) : (
            <HStack>
              <Button isInline={true} onClick={() => onManageRolesOpenHandler(row.original)}>
                Manage Roles
              </Button>
              <Button
                isDisabled={row.original.userId === user.uid}
                isInline={true}
                onClick={() => onStartDeleteUser(row.original.clientId, row.original.userId, row.original.email)}
              >
                Delete
              </Button>
            </HStack>
          ),
      }),
    ] as ColumnDef<UserTableRowData, unknown>[]
  }, [onManageRolesOpenHandler, onOpen, user.uid, user.roles])

  const columnVisibility = user.is59A ? undefined : { clientId: false }

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as UserTableRowData[]
  }, [data])

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <>
      <PageTable
        pagination={{ pageIndex, pageSize }}
        onPaginationChange={setPagination}
        loading={isLoading}
        defaultSort={[{ desc: false, id: "firstName" }]}
        hasGlobalFilter={true}
        heightOtherElementsRem={25}
        tableColumns={tableColumns}
        columnVisibility={columnVisibility}
        tableRows={tableRows}
      />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onCloseDeleteDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              You are about to delete user with email: <b>{userToBeDeleted?.email}</b>
            </AlertDialogBody>
            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
            <ErrorText
              pl={6}
              pr={6}
              height={10}
              message={
                errorType
                  ? mapErrorTypeToMessage({
                      errorType,
                      email: userToBeDeleted?.email,
                    })
                  : ""
              }
              size="small"
            />
            <AlertDialogFooter>
              <VStack>
                <Button colorScheme="red" onClick={onDeleteUser} isDisabled={deleteLoading}>
                  Delete
                </Button>
                <Button buttonRef={cancelRef} isInline={true} isSecondary={true} onClick={onCloseDeleteDialog}>
                  Cancel
                </Button>
              </VStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default UsersTable
