import { FC } from "react"
import { Flex, HStack, Text } from "@chakra-ui/react"
import DSPLogoMapper from "../../../components/DSPLogoMapper"
import Tile from "../../../components/Tile"
import { Platform } from "../../../generated/graphql"

type CampaignInfoProps = {
  campaignName: string
  platform: Platform
}

const CampaignInfo: FC<CampaignInfoProps> = ({ campaignName, platform }) => (
  <Tile py={3}>
    <HStack w="100%" justifyContent="space-between">
      <Flex>
        <DSPLogoMapper platform={platform} />
        <Text overflowWrap="anywhere" ml={2}>
          {campaignName}
        </Text>
      </Flex>
    </HStack>
  </Tile>
)

export default CampaignInfo
