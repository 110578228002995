import React, { FC, useState } from "react"
import { Input, Textarea } from "@chakra-ui/react"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface TextInputUIProps extends Omit<BaseInputProps, "children"> {
  placeholder?: string
  isTextArea?: boolean
}

const TextInputUI: FC<TextInputUIProps> = ({
  placeholder = "Please enter text",
  name,
  field,
  error,
  helperText = "",
  label = "",
  isInvalid,
  isDisabled = false,
  tooltip = "",
  size = "sm",
  width = 80,
  variant = "flushed",
  isTextArea,
}) => {
  const [value, setValue] = useState(field?.value || "")

  const inputProps = {
    ref: field?.ref,
    placeholder: placeholder,
    onChange: (changedValue: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const valueCopy = changedValue.target.value ?? null
      field?.onChange(valueCopy)
      setValue(valueCopy)
    },
    value: value,
    borderRadius: 0,
    variant: variant,
  }

  return (
    <BaseInput
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
      width={width}
    >
      {isTextArea ? (
        <Textarea {...inputProps} aria-label={`Text Input area for ${label}`} resize={"none"} />
      ) : (
        <Input {...inputProps} aria-label={`Text Input for ${label}`} />
      )}
    </BaseInput>
  )
}

export default TextInputUI
