import { graphql } from "msw"

export const triggerSchedules = graphql.mutation("TriggerSchedules", (req, res, ctx) => {
  return res(
    ctx.data({
      triggerSchedules: {
        success: true,
        __typename: "OperationResult",
      },
    })
  )
})
