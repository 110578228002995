import React, { FC, ReactElement, useEffect, useMemo, useState, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import getSymbolFromCurrency from "currency-symbol-map"
import { useNavigate } from "react-router-dom"
import { RoutePaths } from "../../../AppRoutes"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import { getKpiByName, KpiEntries, KpiTargetType } from "../../../components/forms/KpiSelector/KPI"
import { Option } from "../../../components/forms/SelectInput/SelectBaseUI"
import {
  CampaignKpi,
  CampaignType,
  Fee,
  FeeType,
  KpiType,
  Maybe,
  Platform,
  PlatformCampaignUpdateInput,
  PlatformConversionPixel,
} from "../../../generated/graphql"
import {
  GET_PLATFORM_CAMPAIGN,
  GET_PLATFORM_CAMPAIGNS,
  GET_PLATFORM_CONVERSION_PIXELS,
  SET_PLATFORM_CAMPAIGN_MANAGED,
  UPDATE_PLATFORM_CAMPAIGN,
} from "../../../graphql"
import WizardLayout, { WizardStep } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { UserProfile } from "../../../models/AuthState"
import { convertDecimalPercentageToPercentage, convertPercentageToDecimalPercentage } from "../../../utils/numberUtils"
import { middleEllipsis } from "../../../utils/stringUtils"
import ManageCampaignData, { Step1Data, Step2Data } from "./ManageCampaignData"
import Step1 from "./Step1"
import Step2 from "./Step2"

const DEFAULT_MARGIN: Fee = {
  min: 0,
  max: 0.2,
  amount: 0.1,
  feeType: FeeType.MarginCostPlus,
}

type CampaignDetails = {
  isManaged: boolean
  name: string
  id: string
  platform: Platform
}

type ConfirmationOpts = {
  renderText1: () => ReactElement
  renderText2: () => ReactElement
  buttonText: string
  onConfirm: () => void
}

interface CampaignSetupProps {
  user?: UserProfile
  clientId: string
  integrationId: string
  platform: string
  advertiserId: string
  campaignId: string
}

const CampaignSetup: FC<CampaignSetupProps> = ({ user, clientId, integrationId, platform, advertiserId, campaignId }) => {
  const navigate = useNavigate()
  const {
    loading: dataLoading,
    error: dataError,
    data: campaignData,
    called,
  } = useQuery(GET_PLATFORM_CAMPAIGN, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId),
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: advertiserId,
      platformCampaignId: campaignId,
    },
  })
  const {
    loading: pixelsLoading,
    error: pixelsError,
    data: pixelsData,
    called: pixelsCalled,
  } = useQuery(GET_PLATFORM_CONVERSION_PIXELS, {
    fetchPolicy: "network-only",
    variables: {
      clientId: Number(clientId),
      platform,
      platformIntegrationId: Number(integrationId),
      platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
    },
  })
  const [updatePlatformCampaign, { error: updateError, called: updateCalled, loading: updateLoading, reset: updateReset }] = useMutation(
    UPDATE_PLATFORM_CAMPAIGN,
    {
      refetchQueries: [
        {
          query: GET_PLATFORM_CAMPAIGNS,
          variables: {
            clientId: Number(clientId),
            platform,
            platformIntegrationId: Number(integrationId),
            platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
          },
        },
      ],
    }
  )
  const [setPlatformCampaignManaged, { error: setManagedError, called: setManagedCalled, loading: setManagedLoading, reset: setManagedReset }] = useMutation(
    SET_PLATFORM_CAMPAIGN_MANAGED,
    {
      refetchQueries: [
        {
          query: GET_PLATFORM_CAMPAIGNS,
          variables: {
            clientId: Number(clientId),
            platform,
            platformIntegrationId: Number(integrationId),
            platformAdvertiserId: platform === Platform.Facebook ? undefined : advertiserId,
          },
        },
      ],
    }
  )

  const [dataFinalised, setDataFinalised] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState<string>("£")
  const [pixels, setPixels] = useState<Option[]>([])

  const defaultWizardData: ManageCampaignData = [
    {
      campaignType: CampaignType.Display,
      margin: `${DEFAULT_MARGIN.amount * 100}`,
      minMargin: `${(DEFAULT_MARGIN.min || 0) * 100}`,
      maxMargin: `${(DEFAULT_MARGIN.max || 0) * 100}`,
    },
    {
      kpis: [],
    },
  ]

  const [initialData, setInitialData] = useState<ManageCampaignData>(defaultWizardData)
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails | undefined>(undefined)
  const [confirmationOpts, setConfirmationOpts] = useState<ConfirmationOpts | undefined>(undefined)

  useEffect(() => {
    if (called && !dataLoading && pixelsCalled && !pixelsLoading) {
      setDataFinalised(true)
    }
    if (dataError) {
      // TODO: error state
      logError("ERROR", dataError)
    }
    if (pixelsError) {
      logError("ERROR", pixelsError)
    }
    if (campaignData && campaignData.getPlatformCampaign) {
      setCurrencySymbol(getSymbolFromCurrency(campaignData.getPlatformCampaign.currency) ?? "£")
      setCampaignDetails({
        isManaged: campaignData.getPlatformCampaign.managed,
        name: campaignData.getPlatformCampaign.name,
        id: `(${campaignData.getPlatformCampaign.platformCampaignId.id})`,
        platform: campaignData.getPlatformCampaign.platform,
      })
      const fees = campaignData.getPlatformCampaign.fees as Maybe<Array<Fee>>
      const marginFee = fees?.find((it) => it.feeType === FeeType.MarginCostPlus) ?? {
        ...DEFAULT_MARGIN,
      }
      setInitialData(
        mapExistingData({
          campaignType: (campaignData.getPlatformCampaign.campaignType as CampaignType) ?? CampaignType.Display,
          kpis: (campaignData.getPlatformCampaign.kpis as CampaignKpi[]) ?? [],
          marginFee,
        })
      )
    }
    if (pixelsData && pixelsData.getPlatformConversionPixels) {
      setPixels(
        pixelsData.getPlatformConversionPixels.map((pcp: PlatformConversionPixel) => ({
          label: middleEllipsis(`${pcp.name} - ${pcp.platformConversionPixelId.id}`),
          value: pcp.platformConversionPixelId.id,
        }))
      )
    }
  }, [campaignData, dataError, called, dataLoading, pixelsData, pixelsError, pixelsCalled, pixelsLoading, platform])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: WizardStep<ManageCampaignData, any, any>[] = useMemo(
    () => [
      { title: "Budget Type", component: Step1, props: { dataFinalised, user } }, //
      {
        title: "KPIs",
        component: Step2,
        props: {
          isManaged: campaignDetails?.isManaged,
          currencySymbol,
          updateError,
          updateLoading,
          pixels,
          platform,
        },
      },
    ],
    [dataFinalised, user, campaignDetails, currencySymbol, updateError, updateLoading, pixels, platform]
  )

  const cancelConfirmRef = useRef(null)
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure()

  const onComplete = async (manageCampaignData: ManageCampaignData) => {
    if (campaignDetails?.isManaged) {
      setConfirmationOpts({
        renderText1: () => (
          <Text>
            You are about to make changes to parameters for the managed campaign <b>{campaignDetails?.name}</b>.
          </Text>
        ),
        renderText2: () => <Text mt={4}>Please confirm you wish to apply these changes.</Text>,
        buttonText: "Yes, apply these changes",
        onConfirm: confirmManageCampaign(manageCampaignData),
      })
    } else {
      setConfirmationOpts({
        renderText1: () => (
          <Text>
            You are about to manage the campaign <b>{campaignDetails?.name}</b>.
          </Text>
        ),
        renderText2: () => (
          <Text mt={4}>
            59A will now take control of this campaign and changes will occur immediately, please confirm you understand that changes will be made in{" "}
            {campaignDetails?.platform}.
          </Text>
        ),
        buttonText: "Yes, manage campaign",
        onConfirm: confirmManageCampaign(manageCampaignData),
      })
    }
    onConfirmationOpen()
  }

  const onSaveProgress = async (manageCampaignData: ManageCampaignData) => {
    setConfirmationOpts({
      renderText1: () => (
        <Text>
          You are about to save your settings for <b>{campaignDetails?.name}</b> but not begin 59A management yet.
        </Text>
      ),
      renderText2: () => (
        <Text mt={4}>Please confirm that you understand that these changes will not be applied to the campaign until you choose to manage.</Text>
      ),
      buttonText: "Yes, save settings but do not manage campaign",
      onConfirm: confirmSaveCampaign(manageCampaignData),
    })

    onConfirmationOpen()
  }

  const confirmSaveCampaign = (manageCampaignData: ManageCampaignData) => async () => {
    try {
      await updatePlatformCampaign({
        variables: {
          clientId: Number(clientId),
          platform,
          platformIntegrationId: Number(integrationId),
          platformCampaignId: campaignId,
          updateInput: buildMutationPayload(manageCampaignData),
        },
      })

      onConfirmationClose()
      navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId))
    } catch (error) {
      logError(error)
    }
  }

  const confirmManageCampaign = (manageCampaignData: ManageCampaignData) => async () => {
    try {
      await updatePlatformCampaign({
        variables: {
          clientId: Number(clientId),
          platform,
          platformIntegrationId: Number(integrationId),
          platformCampaignId: campaignId,
          updateInput: buildMutationPayload(manageCampaignData),
        },
      })
    } catch (error) {
      logError(error)
    }
  }

  const onCancelConfirmation = () => {
    updateReset()
    setManagedReset()
    onConfirmationClose()
  }

  useEffect(() => {
    const setManaged = async () => {
      try {
        await setPlatformCampaignManaged({
          variables: {
            clientId: Number(clientId),
            platform,
            platformIntegrationId: Number(integrationId),
            platformCampaignId: campaignId,
            managed: true,
          },
        })
      } catch (error) {
        logError(error)
      }
    }

    const handleFinished = () => {
      onConfirmationClose()
      navigate(RoutePaths.campaignsList.resolve(clientId, integrationId, platform, advertiserId))
    }

    if (!setManagedCalled && updateCalled && !updateLoading && !updateError) {
      setManaged().then()
    }

    if (setManagedCalled && !setManagedLoading && !setManagedError) {
      handleFinished()
    }
  }, [
    updateCalled,
    updateError,
    updateLoading,
    setPlatformCampaignManaged,
    clientId,
    platform,
    integrationId,
    campaignId,
    advertiserId,
    onConfirmationClose,
    navigate,
    setManagedCalled,
    setManagedError,
    setManagedLoading,
  ])

  return (
    <>
      {dataFinalised && (
        <>
          <WizardLayout
            title={campaignDetails?.name}
            subTitle={campaignDetails?.id}
            steps={steps}
            onComplete={onComplete}
            onSaveProgress={onSaveProgress}
            initialData={initialData}
          />
          <AlertDialog isOpen={isConfirmationOpen} leastDestructiveRef={cancelConfirmRef} onClose={onCancelConfirmation} size={"2xl"}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Confirmation required
                </AlertDialogHeader>
                <AlertDialogBody>
                  {confirmationOpts?.renderText1()}
                  {confirmationOpts?.renderText2()}
                  <VStack h={8} align="flex-start">
                    {updateError && updateError.graphQLErrors.map((updateError) => <ErrorText message={updateError.message} />)}
                    {setManagedError && setManagedError.graphQLErrors.map((setManagedError) => <ErrorText message={setManagedError.message} />)}
                  </VStack>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <HStack>
                    <Button isLoading={updateLoading || setManagedLoading} onClick={confirmationOpts?.onConfirm}>
                      {confirmationOpts?.buttonText}
                    </Button>
                    <Button buttonRef={cancelConfirmRef} isSecondary={true} onClick={onCancelConfirmation}>
                      Cancel
                    </Button>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildMutationPayload = (data: ManageCampaignData): PlatformCampaignUpdateInput => {
  const step1Data = data["0"] as Step1Data
  const step2Data = data["1"] as Step2Data

  return {
    campaignType: step1Data.campaignType,
    fees: [
      {
        feeType: FeeType.MarginCostPlus,
        amount: Number(convertPercentageToDecimalPercentage(step1Data.margin)),
        min: step1Data.minMargin ? Number(convertPercentageToDecimalPercentage(step1Data.minMargin)) : undefined,
        max: step1Data.maxMargin ? Number(convertPercentageToDecimalPercentage(step1Data.maxMargin)) : undefined,
      },
    ],
    kpis: step2Data.kpis.map((kpi) => {
      let target = Number(kpi.target)
      let min = Number(kpi.minTarget)
      let max = Number(kpi.maxTarget)

      if (kpi.kpi.targetType === KpiTargetType.Percentage) {
        target = Number(convertPercentageToDecimalPercentage(kpi.target))
        min = Number(convertPercentageToDecimalPercentage(kpi.minTarget))
        max = Number(convertPercentageToDecimalPercentage(kpi.maxTarget))
      }

      return {
        type: kpi.type as KpiType,
        name: kpi.kpi.name,
        target,
        min,
        max,
        weight: Number(kpi.weight),
        pixelIds: kpi.pixelIds ?? undefined,
        minimise: kpi.kpi.minimise,
      }
    }),
  }
}

type MapExistingDataOpts = {
  campaignType: CampaignType
  kpis: CampaignKpi[]
  marginFee: Fee
}

const mapExistingData = (opts: MapExistingDataOpts): ManageCampaignData => {
  const { campaignType, kpis, marginFee } = opts
  return [
    // Step 1 Data
    {
      campaignType: campaignType,
      margin: convertDecimalPercentageToPercentage({ value: marginFee.amount }) ?? "",
      minMargin: convertDecimalPercentageToPercentage({ value: marginFee.min ?? undefined }) ?? "",
      maxMargin: convertDecimalPercentageToPercentage({ value: marginFee.max ?? undefined }) ?? "",
    },
    // Step 2 Data
    {
      kpis: kpis.map((kpi) => {
        const kpiEntry = getKpiByName(KpiEntries, kpi.name)

        let target = kpi.target.toString()
        let minTarget = kpi.min.toString()
        let maxTarget = kpi.max.toString()

        if (kpiEntry.targetType === KpiTargetType.Percentage) {
          target = convertDecimalPercentageToPercentage({ value: kpi.target }) ?? ""
          minTarget = convertDecimalPercentageToPercentage({ value: kpi.min }) ?? ""
          maxTarget = convertDecimalPercentageToPercentage({ value: kpi.max }) ?? ""
        }

        return {
          type: kpi.type,
          kpi: kpiEntry,
          target,
          minTarget,
          maxTarget,
          pixelIds: kpi.pixelIds ?? undefined,
          weight: kpi.weight.toString(),
        }
      }),
    },
  ]
}

export default CampaignSetup
