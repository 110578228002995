import { graphql } from "msw"

export const getPlatformAdvertisersByIntegration = graphql.query("GetPlatformAdvertisersByIntegration", (req, res, ctx) => {
  return res(
    ctx.data({
      getPlatformAdvertisersByIntegration: [
        {
          clientId: 1,
          platformAdvertiserId: {
            id: "1012768263",
            platform: "DV360",
            __typename: "PlatformAdvertiserId",
          },
          name: "Goodstuff - Subaru",
          platform: "DV360",
          managed: false,
          __typename: "PlatformAdvertiser",
        },
        {
          clientId: 1,
          platformAdvertiserId: {
            id: "1033527983",
            platform: "DV360",
            __typename: "PlatformAdvertiserId",
          },
          name: "Two Circles - W&S Open",
          platform: "DV360",
          managed: true,
          __typename: "PlatformAdvertiser",
        },
      ],
    })
  )
})
