import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import SwitchInputUI, { SwitchInputUIProps } from "./SwitchInputUI"

interface SwitchInputProps extends Omit<SwitchInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const SwitchInput: FC<SwitchInputProps> = ({ size = "md", control, name, helperText = "", tooltip = "", label, isDisabled = false, variant }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <SwitchInputUI //
      field={field}
      name={name}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
      variant={variant}
    />
  )
}

export default SwitchInput
