import { atom } from "recoil"
import AuthState, { AuthStage } from "../../models/AuthState"

const defaultState: AuthState = {
  authStage: AuthStage.Unauthenticated,
}

const authStateAtom = atom({
  key: "authStateAtom",
  default: defaultState,
})

export const authStateInternalAtom = atom({
  key: "authStateInternalAtom",
  default: {
    hubListenerStarted: false,
  },
})

export default authStateAtom
