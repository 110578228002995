import React, { FC } from "react"
import { Center, HStack, IconButton, Image } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canCreate } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import AuthenticatedProps from "../AuthenticatedProps"

type IntegrationSetupIndexPageProps = AuthenticatedProps

const IntegrationSetupIndexPage: FC<IntegrationSetupIndexPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformIntegrationResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()
  const navigate = useNavigate()
  const { clientId } = useParams()
  if (!clientId) throw Error("Expected clientId in parameters")

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integration Setup", onClick: () => navigate(RoutePaths.integrationSetupIndex.resolve(clientId)) }, //
  ]

  const imageWidth = user?.is59A ? "xs" : "sm"

  const pageTitle = "Integration Setup"

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canCreate(permissions, platformIntegrationResourceUri) && (
        <Center py={4}>
          <HStack spacing={4} height={40}>
            {user?.is59A && (
              <>
                <IconButton
                  aria-label="Setup Facebook Integration"
                  bg="white"
                  size="lg"
                  height={"100%"}
                  variant="ghost"
                  p={8}
                  onClick={() => navigate(RoutePaths.integrationSetupFacebook.resolve(clientId))}
                  icon={<Image width={imageWidth} src={"/facebook-ads.svg"} />}
                />
              </>
            )}
            <IconButton
              aria-label="Setup DV360 Integration"
              bg="white"
              size="lg"
              height={"100%"}
              onClick={() => navigate(RoutePaths.integrationSetupDV360.resolve(clientId))}
              variant="ghost"
              p={8}
              icon={<Image width={imageWidth} src={"/dv360-logo.svg"} />}
            />
            <IconButton
              aria-label="Setup Xandr Integration"
              bg="white"
              size="lg"
              height={"100%"}
              onClick={() => navigate(RoutePaths.integrationSetupXandr.resolve(clientId))}
              variant="ghost"
              p={8}
              icon={<Image width={imageWidth} src={"/xandr-logo.svg"} />}
            />
          </HStack>
        </Center>
      )}
      {!permissionsLoading && !canCreate(permissions, platformIntegrationResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default IntegrationSetupIndexPage
