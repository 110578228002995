import { AtomEffect } from "recoil"
import useLocalStorage from "../../hooks/useLocalStorage"

export const localStorageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const { getItem, removeItem, setItem } = useLocalStorage()
    const savedValue = getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }
    onSet((newValue, _, isReset) => {
      isReset ? removeItem(key) : setItem(key, JSON.stringify(newValue))
    })
  }
