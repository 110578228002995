import { IconBase, IconBaseProps } from "./IconBase"

export const CalendarIcon = ({ viewBox = "0 0 24 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M22.7252 2.19849H18.4117C18.18 2.19849 17.9774 2.39815 17.9774 2.62647C17.9774 2.85479 18.18 3.05445 18.4117 3.05445H22.7252C22.8411 3.05445 22.9278 3.11153 23.0148 3.16861C23.1016 3.25411 23.1306 3.33985 23.1306 3.45402L23.1304 6.36417H0.839622V3.45402C0.839622 3.33985 0.897535 3.25436 0.955451 3.16861C1.0422 3.08311 1.1292 3.05445 1.24503 3.05445H5.18203C5.41369 3.05445 5.61627 2.85479 5.61627 2.62647C5.61627 2.39815 5.41369 2.19849 5.18203 2.19849H1.27387C0.550058 2.19849 0 2.76905 0 3.454V20.7445C0 21.4579 0.578904 22 1.27387 22H22.7253C23.4491 22 23.9992 21.4294 23.9992 20.7445L23.999 3.454C24.028 2.76929 23.4489 2.19849 22.7253 2.19849H22.7252ZM23.0146 21.0297C22.9278 21.1152 22.8408 21.1439 22.725 21.1439H1.27354C1.15771 21.1439 1.07096 21.0868 0.983963 21.0297C0.897214 20.9442 0.868132 20.8585 0.868132 20.7443V7.22006H23.1589V20.7443C23.1591 20.8585 23.1012 20.9726 23.0142 21.0297H23.0146Z"
        fill="currentColor"
      />
      <path
        d="M9.66898 3.05445H14.214C14.4456 3.05445 14.6482 2.85479 14.6482 2.62647C14.6482 2.39815 14.4456 2.19849 14.214 2.19849H9.66898C9.43732 2.19849 9.23474 2.39815 9.23474 2.62647C9.23474 2.85479 9.40849 3.05445 9.66898 3.05445Z"
        fill="currentColor"
      />
      <path
        d="M7.26627 4.22417C7.49793 4.22417 7.70051 4.02451 7.70051 3.79619V1.42798C7.70051 1.19966 7.49793 1 7.26627 1C7.03461 1 6.83203 1.19966 6.83203 1.42798V3.79619C6.83203 4.05317 7.00578 4.22417 7.26627 4.22417Z"
        fill="currentColor"
      />
      <path
        d="M16.5014 4.22417C16.733 4.22417 16.9356 4.02451 16.9356 3.79619V1.42798C16.9356 1.19966 16.733 1 16.5014 1C16.2697 1 16.0671 1.19966 16.0671 1.42798V3.79619C16.0671 4.05317 16.27 4.22417 16.5014 4.22417Z"
        fill="currentColor"
      />
      <path
        d="M5.26861 9.67377H3.12626C2.8946 9.67377 2.69202 9.87343 2.69202 10.1017C2.69202 10.3301 2.8946 10.5297 3.12626 10.5297H5.26861C5.50027 10.5297 5.70285 10.3301 5.70285 10.1017C5.70285 9.84501 5.50027 9.67377 5.26861 9.67377Z"
        fill="currentColor"
      />
      <path
        d="M10.3351 9.67377H8.19278C7.96112 9.67377 7.75854 9.87343 7.75854 10.1017C7.75854 10.3301 7.96112 10.5297 8.19278 10.5297H10.3351C10.5668 10.5297 10.7694 10.3301 10.7694 10.1017C10.7694 9.84501 10.5668 9.67377 10.3351 9.67377Z"
        fill="currentColor"
      />
      <path
        d="M20.4096 9.67377H18.2672C18.0356 9.67377 17.833 9.87343 17.833 10.1017C17.833 10.3301 18.0356 10.5297 18.2672 10.5297H20.4096C20.6413 10.5297 20.8438 10.3301 20.8438 10.1017C20.8438 9.84501 20.6701 9.67377 20.4096 9.67377Z"
        fill="currentColor"
      />
      <path
        d="M14.4171 8.27588C13.404 8.27588 12.5643 9.10341 12.5643 10.102C12.5643 11.1005 13.404 11.928 14.4171 11.928C15.4303 11.928 16.2699 11.1005 16.2699 10.102C16.2699 9.07474 15.4303 8.27588 14.4171 8.27588ZM15.1407 10.8151C14.9381 11.0148 14.7064 11.1289 14.4169 11.1289C14.1273 11.1289 13.8668 11.0148 13.693 10.8151C13.4905 10.6154 13.3746 10.3871 13.3746 10.1017C13.3746 9.81631 13.4905 9.55957 13.693 9.38833C13.8956 9.18867 14.1273 9.07451 14.4169 9.07451C14.7064 9.07451 14.9669 9.18867 15.1407 9.38833C15.3433 9.58799 15.4591 9.81631 15.4591 10.1017C15.4591 10.3871 15.3435 10.6439 15.1407 10.8151Z"
        fill="currentColor"
      />
      <path
        d="M5.26861 13.7252H3.12626C2.8946 13.7252 2.69202 13.9249 2.69202 14.1532C2.69202 14.3815 2.8946 14.5812 3.12626 14.5812H5.26861C5.50027 14.5812 5.70285 14.3815 5.70285 14.1532C5.70285 13.8965 5.50027 13.7252 5.26861 13.7252Z"
        fill="currentColor"
      />
      <path
        d="M10.3351 13.7252H8.19278C7.96112 13.7252 7.75854 13.9249 7.75854 14.1532C7.75854 14.3815 7.96112 14.5812 8.19278 14.5812H10.3351C10.5668 14.5812 10.7694 14.3815 10.7694 14.1532C10.7694 13.8965 10.5668 13.7252 10.3351 13.7252Z"
        fill="currentColor"
      />
      <path
        d="M15.3724 13.7252H13.23C12.9984 13.7252 12.7958 13.9249 12.7958 14.1532C12.7958 14.3815 12.9984 14.5812 13.23 14.5812H15.3724C15.604 14.5812 15.8066 14.3815 15.8066 14.1532C15.8066 13.8965 15.604 13.7252 15.3724 13.7252V13.7252Z"
        fill="currentColor"
      />
      <path
        d="M20.4096 13.7252H18.2672C18.0356 13.7252 17.833 13.9249 17.833 14.1532C17.833 14.3815 18.0356 14.5812 18.2672 14.5812H20.4096C20.6413 14.5812 20.8438 14.3815 20.8438 14.1532C20.8438 13.8965 20.6701 13.7252 20.4096 13.7252Z"
        fill="currentColor"
      />
      <path
        d="M5.26861 17.7204H3.12626C2.8946 17.7204 2.69202 17.9201 2.69202 18.1484C2.69202 18.3767 2.8946 18.5764 3.12626 18.5764H5.26861C5.50027 18.5764 5.70285 18.3767 5.70285 18.1484C5.70285 17.9201 5.50027 17.7204 5.26861 17.7204Z"
        fill="currentColor"
      />
      <path
        d="M10.3351 17.7204H8.19278C7.96112 17.7204 7.75854 17.9201 7.75854 18.1484C7.75854 18.3767 7.96112 18.5764 8.19278 18.5764H10.3351C10.5668 18.5764 10.7694 18.3767 10.7694 18.1484C10.7694 17.9201 10.5668 17.7204 10.3351 17.7204Z"
        fill="currentColor"
      />
      <path
        d="M15.3724 17.7204H13.23C12.9984 17.7204 12.7958 17.9201 12.7958 18.1484C12.7958 18.3767 12.9984 18.5764 13.23 18.5764H15.3724C15.604 18.5764 15.8066 18.3767 15.8066 18.1484C15.8066 17.9201 15.604 17.7204 15.3724 17.7204V17.7204Z"
        fill="currentColor"
      />
      <path
        d="M20.4096 17.7204H18.2672C18.0356 17.7204 17.833 17.9201 17.833 18.1484C17.833 18.3767 18.0356 18.5764 18.2672 18.5764H20.4096C20.6413 18.5764 20.8438 18.3767 20.8438 18.1484C20.8438 17.9201 20.6701 17.7204 20.4096 17.7204Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
)
