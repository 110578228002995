import { FC } from "react"
import { ApolloError } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import ErrorText from "../../../components/forms/ErrorText"
import TextInput from "../../../components/forms/TextInput"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import { Client } from "../../../generated/graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { CustomGraphQLError } from "../../../types"

export interface FormValues {
  clientId: number
  name: string
  clientContact: string
  billingContact: string
}

const schema = z
  .object({
    clientId: z.number(),
    name: z.string().trim(),
    clientContact: z.union([z.literal(""), z.string().email().trim()]),
    billingContact: z.union([z.literal(""), z.string().email().trim()]),
  })
  .required()

type ClientUpdateFormProps = {
  error?: ApolloError
  data?: Client
  onSubmitHandler: (data: FormValues) => void
}

const ClientUpdateForm: FC<ClientUpdateFormProps> = ({ error, data, onSubmitHandler }) => {
  const initialValues = getInitialFormValues(data)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(schema),
  })

  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <TextInput label="Name" name="name" control={control} />
        <TextInput label="Client Contact" name="clientContact" control={control} tooltip="This must be a valid email" />
        <TextInput label="Billing Contact" name="billingContact" control={control} tooltip="This must be a valid email" />
        <ErrorText
          height={10}
          message={
            errorType
              ? mapErrorTypeToMessage({
                  errorType,
                  error,
                })
              : ""
          }
          size="small"
        />
        <WizardNavigateNextButton next={{ text: "Submit", type: "submit" }} />
      </form>
    </>
  )
}

const getInitialFormValues = (data?: Client): FormValues => {
  if (!data) {
    return {
      clientId: 0,
      name: "",
      clientContact: "",
      billingContact: "",
    }
  }
  const { clientId, name, clientContact, billingContact } = data

  return {
    clientId,
    name,
    clientContact: clientContact ?? "",
    billingContact: billingContact ?? "",
  }
}

export default ClientUpdateForm
