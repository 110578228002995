import React, { FC } from "react"
import { Box, FormControl, FormErrorMessage, FormHelperText, HStack, InputGroup } from "@chakra-ui/react"
import { ControllerRenderProps, FieldError } from "react-hook-form"
import colors from "../../../theme/colors"
import FormItemLabelWithToolTip from "../FormItemLabelWithToolTip"

export interface BaseInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: any
  error?: FieldError
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<any, any>
  children: React.ReactNode
  isDisabled?: boolean
  isInvalid?: boolean
  isInline?: boolean
  label: string
  placeholder?: string
  helperText?: string
  tooltip?: string
  size?: "sm" | "md" | "lg"
  width?: number | string
  variant?: "outline" | "unstyled" | "flushed" | "filled"
  zIndex?: string | number
  styles?: object
}

const BaseInput: FC<BaseInputProps> = ({
  name,
  field,
  error,
  children,
  helperText = "",
  label = "",
  isDisabled = false,
  isInvalid,
  isInline = false,
  tooltip = "",
  size = "sm",
  width = "auto",
  zIndex = "auto",
  styles = {},
}) => {
  return (
    <FormControl isDisabled={isDisabled} isInvalid={isInvalid || Boolean(error)} width={"unset"} zIndex={zIndex} sx={styles}>
      {isInline ? (
        <HStack>
          <InputGroup ref={field.ref} width={width}>
            {children}
          </InputGroup>
          <FormItemLabelWithToolTip //
            fieldName={name}
            size={size}
            label={label}
            tooltip={tooltip}
          />
        </HStack>
      ) : (
        <>
          <FormItemLabelWithToolTip //
            fieldName={name}
            size={size}
            label={label}
            tooltip={tooltip}
          />
          <InputGroup ref={field.ref} width={width}>
            {children}
          </InputGroup>
        </>
      )}

      <Box height={10} p={1}>
        {error && (
          <FormErrorMessage color={colors.colors.alerts.warning} height={3}>
            {error.message}
          </FormErrorMessage>
        )}
        {helperText && <FormHelperText height={3}>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

export default BaseInput
