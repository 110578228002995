import { FC } from "react"
import { Text } from "@chakra-ui/react"
import { format } from "date-fns"

type FormattedDateProps = {
  date: string | Date
  fontSizeRem?: number
  hasNiceDate?: boolean
  hasTime?: boolean
}

// TODO: Barns - Make this a reusable function
const FormattedDate: FC<FormattedDateProps> = ({ date, fontSizeRem = 1, hasNiceDate = true, hasTime = false }) => {
  const dateOrdinalLetters = date ? format(new Date(date), "do").slice(format(new Date(date), "do").length - 2) : ""
  return (
    <>
      {hasTime && (
        <Text display="inline" fontSize={`${fontSizeRem}rem`} ml={1}>
          {format(new Date(date), "HH:mm")}
        </Text>
      )}
      {hasNiceDate ? (
        <>
          <Text display="inline" fontSize={`${fontSizeRem}rem`} ml={1}>{`${format(new Date(date), "EEE")} ${format(new Date(date), "d")}`}</Text>
          <Text display="inline" fontSize={`${fontSizeRem / 1.4}rem`} top={`-${fontSizeRem / 2}rem`} as="sup">
            {dateOrdinalLetters}
          </Text>
          <Text display="inline" fontSize={`${fontSizeRem}rem`} ml={1}>
            {format(new Date(date), "MMM yy")}
          </Text>
        </>
      ) : (
        <Text display="inline" fontSize={`${fontSizeRem}rem`} ml={1}>
          {format(new Date(date), "yyyy-MM-dd")}
        </Text>
      )}
    </>
  )
}

export default FormattedDate
