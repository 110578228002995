import React, { FC, useEffect } from "react"
import { ApolloError } from "@apollo/client"
import { Box, Text, VStack } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import Button from "../../../components/Button"
import IntegerInput from "../../../components/forms/IntegerInput"
import TextInput from "../../../components/forms/TextInput"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { Optional } from "../../../types"
import DV360SetupData, { IntegrationDetailsFormStepData } from "./DV360SetupData"

export interface FormValues {
  name?: string
  partnerId?: string
}

export interface IntegrationDetailsFormProps extends WizardStepProps<DV360SetupData, IntegrationDetailsFormStepData> {
  onSubmit: (values: FormValues) => Promise<void>
  queryDetails?: { data?: unknown; loading?: boolean; error?: ApolloError }
}

const schema = z.object({
  name: z
    .string({
      required_error: "Integration name is required",
      invalid_type_error: "Integration name must be a string",
    })
    .trim(),
  partnerId: z
    .string({
      required_error: "Partner Id is required",
      invalid_type_error: "Partner Id must be a number",
    })
    .trim(),
})

const IntegrationDetailsForm: FC<IntegrationDetailsFormProps> = ({ onNext, onBack, stepIndex, wizardData, onSubmit, queryDetails = {} }) => {
  const { loading, error } = queryDetails
  const stepData = wizardData[stepIndex] as Optional<IntegrationDetailsFormStepData>

  const initialValues = getInitialFormValues(stepData)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    // TODO: notify user?
    if (error) logError("Apollo Error", error)
  }, [error])

  const submitAndNext = (data: FormValues) => onSubmit(data).then(() => onNext(buildStepData(data)))

  return (
    <Box>
      <Button onClick={() => onBack({})} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <Text fontSize="xl" paddingBottom={6}>
        Please enter your DV360 partner id and upload your credentials file to allow 59A to connect to the DV360 platform.
      </Text>
      <form onSubmit={handleSubmit(submitAndNext)}>
        <VStack align="flex-start">
          <TextInput
            width={80}
            placeholder="Name for this DV360 integration"
            name="name"
            label="DV360 Integration Name"
            helperText="This is a name to help you identify this DV360 integration"
            tooltip="This is a name to help you identify this DV360 integration"
            control={control}
            variant="flushed"
          />
          <IntegerInput
            hasStepper={false}
            width={80}
            name="partnerId"
            label="DV360 Partner Id"
            placeholder="Your DV360 Partner Id"
            helperText="This is your DV360 partner id."
            tooltip="This should be a number."
            control={control}
            variant="flushed"
          />
          <Button aria-label="Submit DV360 Integration Details" type="submit" isLoading={loading} isDisabled={loading}>
            Connect
          </Button>
        </VStack>
      </form>
    </Box>
  )
}

const getInitialFormValues = (data: Optional<IntegrationDetailsFormStepData>): FormValues => ({
  name: data?.name,
  partnerId: data?.partnerId?.toString(),
})

const buildStepData = (values: FormValues): IntegrationDetailsFormStepData => ({
  name: values.name,
  partnerId: Number(values.partnerId),
})

export default IntegrationDetailsForm
