import { ApolloError } from "@apollo/client"
import { ErrorType } from "../generated/graphql"

type MapErrorTypeToMessageProps = {
  error?: ApolloError
  errorType?: ErrorType
  clientId?: number
  email?: string
  name?: string
}

const mapErrorTypeToMessage = ({ error, errorType, clientId, email, name }: MapErrorTypeToMessageProps) => {
  switch (errorType) {
    case ErrorType.PlatformCampaignDoesNotExist:
      return `Campaign does not exist.`
    case ErrorType.ClientExists:
      return `Client with name "${name}" already exists in the system.`
    case ErrorType.UserExists:
      return `User with email "${email}" already exists in the system.`
    case ErrorType.UserDoesNotExist:
      return `User with email "${email}" does not exist in the system.`
    case ErrorType.MaxUserLimitExceeded:
      return `User limit reached for Client Id "${clientId}".`
    case ErrorType.RoleExists:
      return `Role with name "${name}" already exists in the system.`
    case ErrorType.AdministratorRoleDoesNotExist:
      return `Administrator role does not exist for client.`
    case ErrorType.PlatformIntegrationDoesNotExist:
      return `Platform integration does not exist.`
    case ErrorType.RoleUpdateNotAllowedError:
      return `Role update not allowed.`
    case ErrorType.RoleDoesNotExist:
      return `Role with name "${name}" does not exist in the system.`
    case ErrorType.RoleIsNotDeletable:
      return `"${name}" role is not deletable.`
    case ErrorType.RoleIsNotEditable:
      return `"${name}" role is not editable.`
    case ErrorType.ValidationError:
      return error?.message
    default:
      return "Uh-oh! Something went wrong, please contact support."
  }
}

export default mapErrorTypeToMessage
