import { IconBase, IconBaseProps } from "../IconBase"

export const ClicksIcon = ({ viewBox = "0 0 200 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <g clipPath="url(#clip0_415_849)">
        <path
          d="M79.4194 0C76.1708 0 73.5393 2.63149 73.5393 5.88016V41.1747C73.5393 44.4233 76.1708 47.0548 79.4194 47.0548C82.6681 47.0548 85.2996 44.4233 85.2996 41.1747V5.88016C85.2996 2.63149 82.6613 0 79.4194 0ZM27.3933 21.5063C25.8876 21.5063 24.4091 22.1032 23.2561 23.2493C20.9637 25.5485 20.9637 29.3126 23.2561 31.6118L44.03 52.3856C46.3291 54.6848 50.0933 54.678 52.3924 52.3856C54.6916 50.0865 54.6848 46.3224 52.3924 44.0232L31.6186 23.2493C30.4724 22.1032 28.8989 21.5063 27.3933 21.5063ZM131.439 21.5063C129.933 21.5063 128.36 22.1032 127.214 23.2493L106.44 44.0232C104.147 46.3224 104.141 50.0865 106.44 52.3856C108.739 54.678 112.503 54.6848 114.802 52.3856L135.576 31.6118C137.868 29.3126 137.868 25.5485 135.576 23.2493C134.43 22.1032 132.944 21.5063 131.439 21.5063ZM59.2831 59.2831L112.686 200L127.946 152.938L169.582 194.486L194.493 169.575L152.945 127.939L200 112.679L59.2831 59.2831ZM5.88016 73.5257C2.63149 73.5257 0 76.1572 0 79.4059C0 82.6545 2.63149 85.286 5.88016 85.286H41.1747C44.4233 85.286 47.0548 82.6545 47.0548 79.4059C47.0548 76.1572 44.4233 73.5257 41.1747 73.5257H5.88016ZM48.2485 104.683C46.7429 104.683 45.1694 105.28 44.0232 106.426L23.2493 127.2C20.957 129.499 20.957 133.263 23.2493 135.562C25.5485 137.862 29.3126 137.862 31.6118 135.562L52.3856 114.789C54.678 112.489 54.6848 108.725 52.3856 106.426C51.2394 105.28 49.7541 104.683 48.2485 104.683Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_849">
          <rect width="200" height="200" fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  </IconBase>
)
