import React, { FC } from "react"
import { ApolloError } from "@apollo/client"
import { HStack } from "@chakra-ui/react"
import { UseFormHandleSubmit } from "react-hook-form"
import { z } from "zod"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import TransferLists from "../../../components/forms/TransferLists"
import { RoleTableRowData } from "../../../components/tables/RolesTable/RolesTable"
import { MutationUpdateRoleUsersArgs } from "../../../generated/graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { CustomGraphQLError } from "../../../types"

export const manageRoleUsersFormSchema = z.object({
  clientId: z.number(),
  roleId: z.number(),
  users: z.array(
    z.object({
      clientId: z.number(),
      userId: z.string(),
    })
  ),
})

type ManageRoleUsersFormProps = {
  onSubmitHandler: (data: MutationUpdateRoleUsersArgs) => void
  error?: ApolloError
  selectedRole?: RoleTableRowData
  handleSubmit: UseFormHandleSubmit<MutationUpdateRoleUsersArgs>
  caller: string
}

const ManageRoleUsersForm: FC<ManageRoleUsersFormProps> = ({ caller, error, onSubmitHandler, handleSubmit, selectedRole }) => {
  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <TransferLists caller={caller} />
      <HStack>
        <Button type="submit">Save</Button>
        <ErrorText
          height={12}
          message={
            errorType
              ? mapErrorTypeToMessage({
                  errorType,
                  name: selectedRole?.name,
                })
              : ""
          }
          size="small"
        />
      </HStack>
    </form>
  )
}

export default ManageRoleUsersForm
