/* eslint-disable */

type LogFunc = (...msg: any) => void

const NoOp = () => {}

export const log: LogFunc = process.env.NODE_ENV === "production" ? NoOp : (...msg: any) => console.log(...msg)

export const logError: LogFunc = process.env.NODE_ENV === "production" ? NoOp : (...msg: any) => console.error(...msg)

export const logUnhandledError = (error: Error, info: { componentStack: string }) => {
  // Do something with the error, e.g. log to an external API
  logError(error)
  log(info)
}
