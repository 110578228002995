import { IconBase, IconBaseProps } from "../IconBase"

export const CpaEurIcon = ({ viewBox = "0 0 268 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M154.722 179.515H113.26C111.284 179.515 109.684 181.12 109.684 183.101C109.684 185.083 111.284 186.688 113.26 186.688H154.722C156.698 186.688 158.299 185.083 158.299 183.101C158.299 181.12 156.698 179.515 154.722 179.515Z"
        fill="currentColor"
      />
      <path
        d="M244.466 160.614C243.505 159.421 242.162 158.36 240.569 157.446C243.178 155.235 244.876 151.976 244.876 148.292V12.0286C244.876 5.39334 239.491 0 232.881 0H35.1146C28.4976 0 23.119 5.40031 23.119 12.0286V148.285C23.119 151.969 24.8168 155.235 27.426 157.439C25.8326 158.36 24.4828 159.414 23.5296 160.614L1.612 188.041C-0.0857435 190.162 -0.461466 192.625 0.575269 194.795C2.18256 198.151 6.80958 200 13.6005 200H254.395C261.186 200 265.813 198.151 267.42 194.795C268.464 192.625 268.088 190.162 266.383 188.034L244.459 160.607L244.466 160.614ZM30.2718 12.0356C30.2718 9.35636 32.4427 7.17949 35.1076 7.17949H232.874C235.545 7.17949 237.709 9.35636 237.709 12.0356V148.292C237.709 150.972 235.545 153.148 232.874 153.148H35.1006C32.4288 153.148 30.2649 150.972 30.2649 148.299V12.0356H30.2718ZM254.395 192.841H13.6005C10.4973 192.841 8.61867 192.346 7.66543 191.948L29.1099 165.114C30.7102 163.119 37.6473 160.328 45.3149 160.328H222.68C230.348 160.328 237.285 163.119 238.885 165.114L260.316 191.955C259.356 192.353 257.484 192.848 254.395 192.848V192.841Z"
        fill="currentColor"
      />
      <path
        d="M179.312 77.5701C179.312 102.734 158.864 123.152 133.65 123.152C108.436 123.152 88 102.734 88 77.5701C88 52.4067 108.436 32 133.65 32C158.864 32 179.312 52.4007 179.312 77.5701ZM149.351 59.9047L153.696 51.273C139.136 43.499 121.037 48.9576 113.25 63.4738C112.204 65.3873 111.393 67.4088 110.822 69.5022H104.17L101.13 75.5307H109.776C109.686 76.8623 109.674 78.194 109.776 79.5256H104.176L101.136 85.5541H110.786C115.209 101.42 131.685 110.712 147.573 106.297C149.688 105.727 151.755 104.905 153.702 103.861L149.357 95.2355C148.757 95.5655 148.168 95.8474 147.573 96.0993C137.34 100.508 125.418 95.7874 121.013 85.5541H136.444L139.467 79.5256H119.463C119.331 78.206 119.331 76.8623 119.463 75.5307H141.468L144.496 69.5022H121.037C121.308 68.9384 121.572 68.3685 121.872 67.8167C127.269 58.0632 139.575 54.5241 149.345 59.9047H149.351Z"
        fill="currentColor"
      />
    </g>
  </IconBase>
)
