import React, { useMemo } from "react"
import { Button } from "@chakra-ui/react"
import { DateObj, RenderProps } from "dayzed"
import { DatepickerProps, DayOfMonthBtnStyleProps } from "../utils/commonTypes"

interface DayOfMonthProps extends DatepickerProps {
  renderProps: RenderProps
  isInRange?: boolean | null
  dateObj: DateObj
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const halfGap = 0.125 //default Chakra-gap-space-1 is 0.25rem

export const DayOfMonth: React.FC<DayOfMonthProps> = ({ dateObj, propsConfigs, isInRange, renderProps, onMouseEnter }) => {
  const { date, selected, selectable, today } = dateObj
  const { getDateProps } = renderProps
  const { defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps } = propsConfigs?.dayOfMonthBtnProps || {}

  const styleBtnProps: DayOfMonthBtnStyleProps = useMemo(
    () => ({
      defaultBtnProps: {
        size: "sm",
        minWidth: 4,
        variant: "outline",
        background: "transparent",
        borderColor: "transparent",
        borderRadius: "0px",
        paddingX: 1.5,
        // this intends to fill the visual gap from Grid to improve the UX
        // so the button active area is actually larger than what it's seen
        _after: {
          content: "''",
          position: "absolute",
          top: `-${halfGap}rem`,
          left: `-${halfGap}rem`,
          bottom: `-${halfGap}rem`,
          right: `-${halfGap}rem`,
          borderWidth: `${halfGap}rem`,
          borderColor: "transparent",
        },
        ...defaultBtnProps,
        _hover: selectable
          ? {
              bg: "brand.200",
              borderColor: "brand.900",
              color: "brand.900",
              ...defaultBtnProps?._hover,
            }
          : undefined,
      },
      isInRangeBtnProps: {
        background: "brand.200",
        color: "brand.900",
        ...isInRangeBtnProps,
      },
      selectedBtnProps: {
        background: "brand.200",
        color: "brand.900",
        ...selectedBtnProps,
      },
      todayBtnProps: {
        bg: "brand.400",
        borderColor: "brand.400",
        color: "brand.900",
        ...todayBtnProps,
      },
    }),
    [defaultBtnProps, isInRangeBtnProps, selectedBtnProps, todayBtnProps, selectable]
  )

  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !selectable,
        onMouseEnter: onMouseEnter,
      })}
      disabled={!selectable}
      {...styleBtnProps.defaultBtnProps}
      {...(isInRange && selectable && styleBtnProps.isInRangeBtnProps)}
      {...(selected && selectable && styleBtnProps.selectedBtnProps)}
      {...(today && styleBtnProps.todayBtnProps)}
    >
      {date.getDate()}
    </Button>
  )
}
