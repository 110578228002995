import { IconBase, IconBaseProps } from "../IconBase"

export const CpvEurIcon = ({ viewBox = "0 0 220 181", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_415_1055)">
        <path
          d="M90.7052 45.5407C90.7052 70.6879 70.3926 91.0935 45.3466 91.0935C20.3006 91.0935 0 70.6879 0 45.5407C0 20.3935 20.3006 0 45.3466 0C70.3926 0 90.7052 20.3875 90.7052 45.5407ZM60.9436 27.8867L65.2592 19.2605C50.7963 11.4916 32.8176 16.9466 25.0818 31.4535C24.0432 33.3658 23.2374 35.3859 22.6703 37.478H16.0626L13.0423 43.5026H21.6317C21.5422 44.8334 21.5302 46.1642 21.6317 47.495H16.0686L13.0483 53.5195H22.6345C27.0277 69.3751 43.3947 78.6607 59.1768 74.2487C61.2779 73.6792 63.3312 72.858 65.2652 71.8149L60.9496 63.1947C60.3527 63.5244 59.7677 63.8062 59.1768 64.058C49.0116 68.464 37.1691 63.7462 32.7938 53.5195H48.1222L51.1246 47.495H31.2538C31.1224 46.1762 31.1224 44.8334 31.2538 43.5026H53.1123L56.1207 37.478H32.8176C33.0863 36.9146 33.3489 36.3451 33.6473 35.7936C39.0075 26.0464 51.2321 22.5096 60.9377 27.8867H60.9436Z"
          fill="currentColor"
        />
        <path d="M171.108 47.7407L41.5801 177.823" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
        <path
          d="M173.913 176.672C149.637 176.672 130.405 156.374 129.605 155.505L127.838 153.611L129.605 151.716C130.411 150.853 149.637 130.556 173.913 130.556C198.189 130.556 217.421 150.853 218.227 151.716L219.994 153.611L218.227 155.505C217.421 156.368 198.195 176.672 173.913 176.672ZM135.568 153.611C140.838 158.496 156.191 171.133 173.913 171.133C191.635 171.133 206.993 158.502 212.258 153.617C206.987 148.731 191.635 136.095 173.913 136.095C156.149 136.095 140.832 148.725 135.568 153.611Z"
          fill="currentColor"
        />
        <path
          d="M169.376 167.296C169.065 167.296 168.755 167.224 168.469 167.075C167.824 166.733 167.418 166.067 167.418 165.33V141.676C167.418 140.944 167.824 140.273 168.469 139.937C169.113 139.601 169.895 139.649 170.492 140.063L187.48 151.89C188.005 152.256 188.321 152.861 188.321 153.503C188.321 154.144 188.005 154.75 187.48 155.115L170.492 166.943C170.158 167.176 169.764 167.296 169.376 167.296ZM171.334 145.428V161.578L182.931 153.497L171.334 145.422V145.428Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1055">
          <rect width="220" height="181" fill="white" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
