import { gql } from "@apollo/client"

export const PLATFORM_ADVERTISER_FRAGMENT = gql`
  fragment PlatformAdvertiserFragment on PlatformAdvertiser {
    clientId
    platformAdvertiserId {
      id
      platform
    }
    name
    platform
    managed
  }
`
