import { IconBase, IconBaseProps } from "./IconBase"

export const DropDownIcon = ({ viewBox = "0 0 10 7", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <path
      d="M5.18769 6.40385L5.18717 6.40452C5.14077 6.46465 5.06956 6.5 4.991 6.5C4.91592 6.5 4.84313 6.46482 4.79583 6.40352C3.79396 5.10469 1.75263 2.45956 0.553519 0.906413C0.514213 0.854865 0.5 0.803461 0.5 0.755C0.5 0.613126 0.610292 0.5 0.749001 0.5L9.25 0.5C9.3903 0.5 9.5 0.613694 9.5 0.755C9.5 0.802694 9.48589 0.853846 9.44542 0.906197C8.62676 1.96372 7.41644 3.52632 6.4096 4.82622C5.93692 5.43647 5.50909 5.98883 5.18769 6.40385Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </IconBase>
)
