import { FC } from "react"
import { Center, HStack, Text, VStack } from "@chakra-ui/react"
import Tile from "../../../components/Tile"
import { NoData } from "../../../images"
import colors from "../../../theme/colors"

const CampaignDashboardNoData: FC = () => (
  <Tile w="container.xl" h="container.sm">
    <Center w="100%">
      <HStack w="65%" justify="space-between">
        <NoData color={colors.colors.brand["400"]} width="2xs" height="2xs" />
        <VStack alignItems="flex-start">
          <Text as="b" fontSize="6xl">
            No Data!
          </Text>
          <Text fontSize="3xl">There is no data for the dates selected,</Text>
          <Text fontSize="3xl">please chose different dates.</Text>
        </VStack>
      </HStack>
    </Center>
  </Tile>
)

export default CampaignDashboardNoData
