import React, { FC, useState } from "react"
import { Switch as ChakraSwitch } from "@chakra-ui/react"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface SwitchInputUIProps extends Omit<BaseInputProps, "children" | "variant"> {
  variant?: string
}

const SwitchInputUI: FC<SwitchInputUIProps> = ({
  name,
  field,
  error,
  helperText = "",
  label = "",
  isInvalid,
  isDisabled = false,
  tooltip = "",
  size = "md",
  variant = "custom",
}) => {
  const [value, setValue] = useState(field.value ?? false)

  return (
    <BaseInput name={name} field={field} error={error} label={label} tooltip={tooltip} helperText={helperText} isDisabled={isDisabled} isInvalid={isInvalid}>
      <ChakraSwitch //
        aria-label={`Switch for ${label}`}
        ref={field.ref}
        isChecked={value}
        onChange={(changedValue) => {
          const valueCopy = changedValue.target.checked ?? null
          // send data to react hook form
          field.onChange(valueCopy)
          // update local state
          setValue(valueCopy)
        }}
        isDisabled={isDisabled}
        size={size}
        variant={variant}
      />
    </BaseInput>
  )
}

export default SwitchInputUI
