import React, { FC } from "react"
import { Avatar as ChakraAvatar, AvatarProps as ChakraAvatarProps } from "@chakra-ui/react"
import colors from "../../theme/colors"

export interface AvatarProps extends ChakraAvatarProps {
  size?: "sm" | "md" | "lg" | "xl"
}

const Avatar: FC<AvatarProps> = ({ onClick, size = "md", children, variant, ...rest }) => {
  const bgColor = colors.colors.brand["400"]
  const color = colors.colors.brand["900"]
  const border = `solid 1px ${colors.colors.brand["200"]}`

  const chakraSize = size === "xl" ? "2xl" : size

  return (
    <ChakraAvatar size={chakraSize} bg={bgColor} border={border} color={color} sx={{ fontSize: "1.1rem" }} variant={variant} {...rest}>
      {children}
    </ChakraAvatar>
  )
}

export default Avatar
