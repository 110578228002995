import { extendTheme } from "@chakra-ui/react"
import colors from "./colors"
import { WizardStepsStyleConfig } from "./components"

export const theme = extendTheme({
  ...colors,
  fonts: {
    heading: `'DIN Pro', sans-serif`,
    body: `'DIN Pro', sans-serif`,
  },
  styles: {
    global: () => ({
      body: {
        bg: colors.colors.brand["900"],
        color: colors.colors.brand["200"],
      },
    }),
  },
  components: {
    Checkbox: {
      variants: {
        custom: {
          control: {
            height: 4,
            width: 4,
            _checked: {
              bg: colors.colors.brand["400"],
              borderColor: colors.colors.brand["400"],
              color: colors.colors.brand["700"],
              _hover: {
                bg: colors.colors.brand["200"],
                borderColor: colors.colors.brand["200"],
              },
            },
            _indeterminate: {
              bg: colors.colors.brand["200"],
              borderColor: colors.colors.brand["200"],
              color: colors.colors.brand["700"],
              _hover: {
                bg: colors.colors.brand["400"],
                borderColor: colors.colors.brand["400"],
              },
            },
            _hover: {
              bg: colors.colors.brand["200"],
              borderColor: colors.colors.brand["200"],
            },
          },
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
    Menu: {
      variants: {
        custom: {
          list: {
            bg: colors.colors.brand["700"],
            _hover: {
              bg: colors.colors.brand["700"],
            },
          },
          item: {
            bg: colors.colors.brand["700"],
            _focus: {
              bg: colors.colors.brand["400"],
              color: colors.colors.white,
            },
            _hover: {
              bg: colors.colors.brand["400"],
              color: colors.colors.white,
            },
          },
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
    Modal: {
      variants: {
        custom: {
          header: {
            color: colors.colors.brand["700"],
          },
          body: {
            color: colors.colors.brand["700"],
          },
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
    Steps: WizardStepsStyleConfig,
    Switch: {
      variants: {
        custom: {
          thumb: {
            bg: colors.colors.brand["200"],
            _checked: {
              bg: colors.colors.alerts["affirmative"],
            },
          },
          track: {
            bg: colors.colors.brand["700"],
            border: "1px solid",
            borderColor: colors.colors.brand["200"],
            _checked: {
              bg: colors.colors.brand["700"],
            },
          },
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
    Tabs: {
      variants: {
        custom: {
          root: {
            position: "relative",
            overflowX: "hidden",
            width: "fit-content",
          },
          tab: {
            color: colors.colors.brand["200"],
            paddingX: 0,
            paddingY: 0,
            _selected: {
              color: colors.colors.brand["400"],
            },
          },
          tablist: {
            overflowX: "visible",
            position: "relative",
            whiteSpace: "nowrap",
          },
          tabpanel: {},
        },
        line: {
          root: {},
          tab: {
            color: colors.colors.brand["200"],
            fontSize: "3xl",
            _selected: {
              color: colors.colors.brand["400"],
            },
          },
          tablist: {
            overflowX: "visible",
            position: "relative",
            whiteSpace: "nowrap",
            borderColor: colors.colors.brand["200"],
          },
          tabpanel: {},
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
    Tooltip: {
      variants: {
        custom: {
          bg: colors.colors.tooltip.bg,
          color: colors.colors.white,
          fontSize: "sm",
          maxWidth: "fit-content",
          width: "fit-content",
        },
      },
      defaultProps: {
        variant: "custom",
      },
    },
  },
})
