import React, { FC, useState } from "react"
import { ApolloError } from "@apollo/client"
import { useInterval } from "@chakra-ui/react"
import IntegrationVerify from "../../../components/integrations/IntegrationVerify"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import DV360SetupData, { IntegrationVerifyBlockStepData } from "./DV360SetupData"

interface IntegrationVerifyBlockProps extends WizardStepProps<DV360SetupData, IntegrationVerifyBlockStepData> {
  getIntegrationState: () => Promise<void>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryDetails: { data?: any; called: boolean; loading: boolean; error?: ApolloError; refetch: () => Promise<void> }
}

const IntegrationVerifyBlock: FC<IntegrationVerifyBlockProps> = ({ onNext, getIntegrationState, queryDetails }) => {
  // We can't trust the 'called' prop of query details as this may have been called once already on a previous mount
  // We need to call the query at least once *each* time the component is mounted. This flag is reset to false on each
  // mount, so is a reliable way to determine if the query has been run
  const [queryCalledOnMount, setQueryCalledOnMount] = useState(false)

  useInterval(() => {
    if (queryDetails) {
      const { data, called, loading, error, refetch } = queryDetails

      if (queryCalledOnMount) {
        // If the query is still loading, do nothing
        if (!loading) {
          // Query has finished. If the query errored, notify the user
          if (error) {
            // TODO: user visible error message
            logError("Error in polling query")
          } else {
            // The query succeeded. Check what state the integration is in
            switch (data.getPlatformIntegration.state) {
              case "Active":
                // The integration is enabled. Setup must have completed successfully
                onNext(createStepData(true))
                break
              case "CredentialsInvalid":
                // The integration failed verification and is stuck. The credentials are wrong
                onNext(createStepData(false))
                break
              default:
                // The integration is still in a pending state. Setup hasn't finished yet. Re-run the query
                refetch()
            }
          }
        }
      } else {
        // Start polling. If the query has never been called, call it. Otherwise, refetch it
        const result = called ? refetch() : getIntegrationState()
        // Once the first query has completed, set the flag to indicate the results are now valid for this mount
        result.then(() => setQueryCalledOnMount(true))
      }
    }
  }, 2000)

  return <IntegrationVerify />
}

const createStepData = (success: boolean): IntegrationVerifyBlockStepData => ({ success })

export default IntegrationVerifyBlock
