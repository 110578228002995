import { PaginationState } from "@tanstack/react-table"
import { atom } from "recoil"
import { StringKeyObject } from "../../types"
import { localStorageEffect } from "../effects/localStorageEffect"

export type PaginationSettings = StringKeyObject<PaginationState>

type DefaultState = {
  paginationSettings: PaginationSettings
}

const defaultPaginationValue = {
  pageIndex: 0,
  pageSize: 20,
}

const defaultState: DefaultState = {
  paginationSettings: {
    clients: defaultPaginationValue,
    integrations: defaultPaginationValue,
    integrationSchedules: defaultPaginationValue,
    advertisers: defaultPaginationValue,
    campaigns: defaultPaginationValue,
    optimisationApprovals: defaultPaginationValue,
    roles: defaultPaginationValue,
    users: defaultPaginationValue,
  } as PaginationSettings,
}

const settingsStateAtom = atom({
  key: "settingsStateAtom",
  default: defaultState,
  effects: [localStorageEffect("paginationSettings")],
})

export default settingsStateAtom
