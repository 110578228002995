import { graphql } from "msw"

export const getPlatformIntegrationsMock = graphql.query("GetPlatformIntegrations", (req, res, ctx) => {
  return res(
    ctx.data({
      getPlatformIntegrations: [
        {
          clientId: 1,
          name: "Dv360 C1-1",
          platform: "DV360",
          platformIntegrationId: 1,
          state: "Active",
          __typename: "PlatformIntegration",
        },
        {
          clientId: 1,
          name: "Facebook C1-1",
          platform: "FACEBOOK",
          platformIntegrationId: 2,
          state: "Active",
          __typename: "PlatformIntegration",
        },
        {
          clientId: 1,
          name: "Xandr C1-1",
          platform: "XANDR",
          platformIntegrationId: 3,
          state: "Active",
          __typename: "PlatformIntegration",
        },
      ],
    })
  )
})
