import React, { ReactElement } from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export interface IconBaseProps extends IconProps {
  children?: ReactElement
  viewBox?: string
}

export const IconBase = ({ children, viewBox, height = 6, width = 6, ...rest }: IconBaseProps) => (
  <Icon viewBox={viewBox} height={height} width={width} {...rest}>
    {children}
  </Icon>
)
