import React, { FC, useEffect } from "react"
import { ApolloError } from "@apollo/client"
import { Box, FormErrorMessage, Text } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import Button from "../../../components/Button"
import PasswordInput from "../../../components/forms/PasswordInput"
import TextInput from "../../../components/forms/TextInput"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { logError } from "../../../log"
import { Optional } from "../../../types"
import FacebookSetupData, { IntegrationDetailsFormStepData } from "./FacebookSetupData"

export interface FormValues {
  name?: string
  adAccountId?: string
  token?: string
}

export interface IntegrationDetailsFormProps extends WizardStepProps<FacebookSetupData, IntegrationDetailsFormStepData> {
  onSubmit: (values: FormValues) => Promise<void>
  queryDetails?: { data?: unknown; loading?: boolean; error?: ApolloError }
}

const schema = z.object({
  name: z
    .string({
      required_error: "Integration name is required",
      invalid_type_error: "Integration name must be a string",
    })
    .trim(),
  adAccountId: z
    .string({
      required_error: "Ad Account Id is required",
      invalid_type_error: "Ad Account Id must be a string",
    })
    .trim(),
  token: z
    .string({
      required_error: "Token is required",
      invalid_type_error: "Token must be a string",
    })
    .trim(),
})

const IntegrationDetailsForm: FC<IntegrationDetailsFormProps> = ({ onNext, stepIndex, wizardData, onSubmit, queryDetails = {} }) => {
  const navigate = useNavigate()
  const { loading, error } = queryDetails
  const stepData = wizardData[stepIndex] as Optional<IntegrationDetailsFormStepData>

  const initialValues = getInitialFormValues(stepData)

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    // TODO: notify user?
    if (error) logError("Apollo Error", error)
  }, [error])

  const submitAndNext = (data: FormValues) => onSubmit(data).then(() => onNext(buildStepData(data)))

  return (
    <Box>
      <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <Text fontSize="xl" paddingBottom={6}>
        Please enter your Facebook Ads token to allow 59A to connect to the Facebook Ads platform.
      </Text>
      <form onSubmit={handleSubmit(submitAndNext)}>
        <TextInput
          width={80}
          placeholder="Name for this Facebook integration"
          name="name"
          label="Facebook Integration Name"
          helperText="This is a name to help you identify this Facebook integration"
          tooltip="This is a name to help you identify this Facebook integration"
          control={control}
          variant="flushed"
        />
        <TextInput //
          placeholder="Please enter username"
          name="adAccountId"
          label="Facebook Ads Account Id"
          helperText="This is your Facebook Ads account Id."
          tooltip="This is your Facebook Ads account Id."
          control={control}
        />
        <PasswordInput //
          name="token"
          placeholder="Please enter Facebook Ads token"
          label="Token"
          helperText="This is your Facebook Ads token."
          tooltip="This is your Facebook Ads token."
          control={control}
          width="100%"
        />
        {error && <FormErrorMessage>Something went wrong, please try again.</FormErrorMessage>}
        <Button aria-label="Submit Facebook Integration Details" type="submit" isLoading={loading} isDisabled={loading}>
          Connect
        </Button>
      </form>
    </Box>
  )
}

const getInitialFormValues = (data: Optional<IntegrationDetailsFormStepData>): FormValues => ({
  name: data?.name,
  adAccountId: data?.adAccountId,
  token: data?.token,
})

const buildStepData = (values: FormValues): IntegrationDetailsFormStepData => ({
  name: values.name,
  adAccountId: values.adAccountId,
  token: values.token,
})

export default IntegrationDetailsForm
