import { IconBase, IconBaseProps } from "../icons/IconBase"

export const FacebookLogo = ({ viewBox = "0 0 96 96", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M93.0735 17.8733V78.1275C93.0735 86.3843 86.3775 93.0803 78.1208 93.0803H17.8793C9.62251 93.0803 2.92651 86.3843 2.92651 78.1275V17.8733C2.92651 9.6165 9.62251 2.9205 17.8793 2.9205H78.12C86.3775 2.91975 93.0735 9.6165 93.0735 17.8733Z"
        fill="url(#paint0_linear_29_39)"
      />
      <path
        d="M53.7015 35.0497V46.1925H66.1192V57.9855H53.7015V93.0795H40.9462V57.9863H28.62V46.1925H40.9462V31.6687C40.9462 23.334 48.6307 18.198 54.1177 18.198H67.3672V28.8472H61.542C58.0185 28.8472 53.7015 31.7205 53.7015 35.0497Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_29_39" x1="48" y1="2.91997" x2="48" y2="93.08" gradientUnits="userSpaceOnUse">
          <stop offset="5.32779e-09" stopColor="#4483C4" />
          <stop offset="0.1325" stopColor="#3F75C5" />
          <stop offset="0.4988" stopColor="#3453C7" />
          <stop offset="0.8011" stopColor="#2D3EC9" />
          <stop offset="1" stopColor="#2A36C9" />
        </linearGradient>
      </defs>
    </g>
  </IconBase>
)
