import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import permissionsState from "../atoms/permissionsState"
import { PermissionActions, ResourceActions } from "../generated/graphql"
import { GET_PERMISSIONS } from "../graphql/queries/getPermissions"
import { logError } from "../log"
import { AuthStage } from "../models/AuthState"
import { StringKeyObject } from "../types"
import useAuth from "./useAuth"
import useResourceUris from "./useResourceUris"

export type resourceActions = StringKeyObject<ResourceActions[]>

enum LifecycleStage {
  PermissionsNotSet = "PermissionsNotSet",
  PermissionsSet = "PermissionsSet",
}

export const usePermissionsLifecycle = () => {
  const { authStage } = useAuth()
  const { clientResourceUri, platformIntegrationResourceUri, platformAdvertiserResourceUri, platformCampaignResourceUri, userResourceUri } = useResourceUris()

  const [lifecycleStage, setLifecycleStage] = useState(LifecycleStage.PermissionsNotSet)
  const resourceUris = [clientResourceUri, userResourceUri, platformIntegrationResourceUri, platformAdvertiserResourceUri, platformCampaignResourceUri]
  const [getPermissions] = useLazyQuery(GET_PERMISSIONS, {
    fetchPolicy: "network-only",
    variables: {
      resourceUris,
    },
  })

  const setPermissionsState = useSetRecoilState(permissionsState)
  const resetPermissionsState = useResetRecoilState(permissionsState)

  const loadPermissions = () => {
    getPermissions()
      .then(({ data }) =>
        setPermissionsState({
          permissions: mapResourceActions(data.getPermissions as PermissionActions[]),
          loading: false,
        })
      )
      .catch(logError)
    setLifecycleStage(LifecycleStage.PermissionsSet)
  }

  const onUserSignedOut = () => {
    resetPermissionsState()
    setLifecycleStage(LifecycleStage.PermissionsNotSet)
  }

  useEffect(() => {
    switch (true) {
      case lifecycleStage === LifecycleStage.PermissionsNotSet && authStage === AuthStage.Authenticated:
        loadPermissions()
        break
      case lifecycleStage === LifecycleStage.PermissionsSet && authStage === AuthStage.Unauthenticated:
        onUserSignedOut()
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifecycleStage, authStage])
}

const mapResourceActions = (data: PermissionActions[]): resourceActions =>
  data.reduce((acc, item) => {
    const { resourceUri, actions } = item
    return { ...acc, [resourceUri]: actions || [] }
  }, {} as resourceActions)

export default usePermissionsLifecycle
