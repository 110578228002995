import { FC } from "react"
import { Flex, Tooltip } from "@chakra-ui/react"
import { Platform } from "../../generated/graphql"
import { DV360Logo, FacebookLogo, XandrLogo } from "../../images"
import { toTitleCase } from "../../utils/stringUtils"

interface DSPLogoMapperProps {
  platform: Platform
}

const DSPLogoMapper: FC<DSPLogoMapperProps> = ({ platform }) => {
  const label = `${toTitleCase(platform)}`
  switch (platform) {
    case Platform.Dv360:
      return (
        <Tooltip aria-label={label} label={label} placement="top-start">
          <Flex justify="flex-start">
            <DV360Logo />
          </Flex>
        </Tooltip>
      )
      break
    case Platform.Facebook:
      return (
        <Tooltip aria-label={label} label={label} placement="top-start">
          <Flex justify="flex-start">
            <FacebookLogo />
          </Flex>
        </Tooltip>
      )
      break
    case Platform.Xandr:
      return (
        <Tooltip aria-label={label} label={label} placement="top-start">
          <Flex justify="flex-start">
            <XandrLogo />
          </Flex>
        </Tooltip>
      )
      break
    default:
      return null
  }
}

export default DSPLogoMapper
