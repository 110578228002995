import React, { FC, useEffect, useMemo, useState } from "react"
import { HStack, Switch } from "@chakra-ui/react"
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table"
import { useNavigate, useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { RoutePaths } from "../../../AppRoutes"
import settingsStateAtom, { PaginationSettings } from "../../../atoms/settingsState"
import { Platform, PlatformAdvertiser, PlatformAdvertiserId } from "../../../generated/graphql"
import usePermissions, { canUpdate, canView } from "../../../hooks/usePermissions"
import useResourceUris from "../../../hooks/useResourceUris"
import useSettings from "../../../hooks/useSettings"
import Button from "../../Button"
import DSPLogoMapper from "../../DSPLogoMapper"
import PageTable from "../PageTable"

interface TableRowData {
  clientId: number
  platformAdvertiserId: string
  platform: Platform
  name: string
  managed: boolean
}

const toTableRow = (data: PlatformAdvertiser[]): TableRowData[] =>
  data.map((v, i) => ({
    key: i,
    ...v,
    platformAdvertiserId: v.platformAdvertiserId.id,
    platform: v.platformAdvertiserId.platform,
  }))

const columnHelper = createColumnHelper<TableRowData>()

interface PlatformAdvertisersTableProps {
  data?: PlatformAdvertiser[]
  handleUpdateAdvertiser: (platformAdvertiserId: PlatformAdvertiserId, managed: boolean) => void
  loading: boolean
  mutating: boolean
}

const AdvertisersTable: FC<PlatformAdvertisersTableProps> = ({ data, handleUpdateAdvertiser, loading, mutating }) => {
  const { clientId, integrationId } = useParams()
  const navigate = useNavigate()
  const { platformAdvertiserResourceUri, platformCampaignResourceUri } = useResourceUris()
  const { permissions } = usePermissions()
  const { paginationSettings } = useSettings()
  const setState = useSetRecoilState(settingsStateAtom)

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>(paginationSettings.advertisers)

  useEffect(() => {
    if (pageIndex !== paginationSettings.advertisers.pageIndex || pageSize !== paginationSettings.advertisers.pageSize) {
      const newPaginationSettings = {
        ...paginationSettings,
        advertisers: { pageIndex, pageSize },
      } as PaginationSettings
      setState({ paginationSettings: newPaginationSettings })
    }
  }, [pageIndex, pageSize, paginationSettings, setState])

  const tableColumns = useMemo(
    () =>
      [
        columnHelper.accessor("managed", {
          id: "managed",
          header: () => <span>Managed</span>,
          cell: (info) => (
            <HStack>
              {canUpdate(permissions, platformAdvertiserResourceUri) && (
                <Switch
                  isDisabled={mutating}
                  isChecked={info.getValue()}
                  onChange={() =>
                    handleUpdateAdvertiser(
                      {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        platform: info.row.original!.platform,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        id: info.row.original!.platformAdvertiserId,
                      },
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      !info.row.original!.managed
                    )
                  }
                />
              )}
            </HStack>
          ),
        }),
        columnHelper.accessor("platformAdvertiserId", {
          id: "platformAdvertiserId",
          cell: (info) => info.getValue(),
          header: () => <span>Advertiser Id</span>,
          footer: (props) => props.column.id,
        }),
        columnHelper.accessor("name", {
          id: "name",
          cell: (info) => info.getValue(),
          header: () => <span>Name</span>,
          footer: (props) => props.column.id,
        }),
        columnHelper.display({
          id: "platform",
          cell: ({ row }) => (
            <div className="px-1">
              <DSPLogoMapper platform={row.original.platform} />
            </div>
          ),
          header: () => <span>Platform</span>,
          footer: (props) => props.column.id,
        }),
        columnHelper.display({
          id: "actions",
          header: () => <span>Actions</span>,
          cell: ({ row }) => (
            <div className="px-1">
              {canView(permissions, platformCampaignResourceUri) && (
                <Button
                  isDisabled={!row.original?.managed}
                  onClick={() => {
                    if (clientId && integrationId) {
                      navigate(
                        RoutePaths.campaignsList.resolve(clientId, integrationId, row.original?.platform ?? "", row.original?.platformAdvertiserId ?? "")
                      )
                    }
                  }}
                  isInline={true}
                >
                  View Campaigns
                </Button>
              )}
            </div>
          ),
        }),
      ] as ColumnDef<TableRowData, unknown>[],
    [clientId, platformAdvertiserResourceUri, platformCampaignResourceUri, handleUpdateAdvertiser, integrationId, navigate, permissions, mutating]
  )

  const tableRows = useMemo(() => {
    if (data?.length) {
      return toTableRow(data)
    }
    return [] as TableRowData[]
  }, [data])

  return (
    <>
      {integrationId && (
        <PageTable
          loading={loading}
          defaultSort={[
            { desc: true, id: "managed" },
            { desc: false, id: "name" },
          ]}
          pagination={{ pageIndex, pageSize }}
          onPaginationChange={setPagination}
          hasGlobalFilter={true}
          heightOtherElementsRem={20.35}
          tableColumns={tableColumns}
          tableRows={tableRows}
        />
      )}
    </>
  )
}

export default AdvertisersTable
