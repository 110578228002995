import React, { FC, useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { Box, Heading, Spinner, Text } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../../components/Button"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import { Client } from "../../../generated/graphql"
import { GET_CLIENT } from "../../../graphql"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { getDv360ConsoleEmail } from "../../../utils/vendorUtils"
import DV360SetupData from "./DV360SetupData"

const IntegrationInstructions: FC<WizardStepProps<DV360SetupData, object>> = ({ onNext }) => {
  const navigate = useNavigate()
  const { clientId } = useParams()

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT, {
    variables: {
      clientId: Number(clientId) ?? undefined,
    },
  })

  const [client, setClient] = useState<Client>()
  useEffect(() => {
    if (!clientLoading && clientData) setClient(clientData.getClient)
  }, [clientLoading, clientData, setClient])

  const [consoleEmail, setConsoleEmail] = useState<string>()

  useEffect(() => {
    if (client) {
      setConsoleEmail(getDv360ConsoleEmail(client))
    }
  }, [client, setConsoleEmail])

  const consoleEmailComponent = useMemo(() => {
    if (clientError) return "Please ask your account manager to provide this information"
    if (consoleEmail) return consoleEmail
    return <Spinner size="xs" />
  }, [consoleEmail, clientError])

  return (
    <>
      <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <Heading pt={3}>Authorize 59A to Access your DV360 Data</Heading>
      <Text fontSize="1.1rem" pt={3}>
        In order for 59A to integrate with your DV360 account, you need to authorize our system to access your DV360 data. You can do this from your DV360
        account console on the{" "}
        <Text
          as="a"
          fontSize="1.1rem"
          style={{
            textDecoration: "underline",
          }}
          target="_blank"
          href="https://displayvideo.google.com/ng_nav/users"
        >
          User Management page
        </Text>
        .
      </Text>

      <Text fontSize="1.1rem" pt={3}>
        The following steps will grant 59A permission to access your data:
      </Text>

      <Box pl="2rem">
        <ol>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              On the{" "}
              <Text
                as="a"
                fontSize="1.1rem"
                style={{
                  textDecoration: "underline",
                }}
                target="_blank"
                href="https://displayvideo.google.com/ng_nav/users"
              >
                User Management page
              </Text>
              , select "New User"
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Enter the following information in the new user screen:
            </Text>
            <Box pl="2rem">
              <ol type="a">
                <li>
                  <Text as="i" fontSize="1.1rem" pt={3}>
                    Name
                  </Text>
                  <Text as="span" fontSize="1.1rem" pt={3}>
                    {" "}
                    - Any name to help you identify this user as the 59A authorised account
                  </Text>
                </li>
                <li>
                  <Text as="i" fontSize="1.1rem" pt={3}>
                    Email
                  </Text>
                  <Text as="span" fontSize="1.1rem" pt={3}>
                    {" "}
                    - This should be:{" "}
                    <Text as="b" fontSize="1.1rem" pt={3}>
                      dv360-466@saas-59a.iam.gserviceaccount.com
                    </Text>
                  </Text>
                </li>
                <li>
                  <Text as="i" fontSize="1.1rem" pt={3}>
                    Type
                  </Text>
                  <Text as="span" fontSize="1.1rem" pt={3}>
                    {" "}
                    - This should be:{" "}
                    <Text as="b" fontSize="1.1rem" pt={3}>
                      Partner & Advertiser
                    </Text>
                  </Text>
                </li>
              </ol>
            </Box>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Click the "Add Partners or Advertisers" button
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              On the following screen, select all partners that you wish to integrate with the 59A system
            </Text>
            <Text as="b" fontSize="1.1rem" pt={3}>
              (Important - If you later try to manage campaigns that you have not authorised 59A to access, 59A will be unable to start the optimisation
              process)
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Click the "Select" button
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              You will be taken back to the previous screen. Click the "Save" button
            </Text>
          </li>
        </ol>
      </Box>
      <Text fontSize="1.1rem" pt={6}>
        59A requires both{" "}
        <Text as="b" fontSize="1.1rem" pt={3}>
          read
        </Text>{" "}
        and{" "}
        <Text as="b" fontSize="1.1rem" pt={3}>
          write
        </Text>{" "}
        access to DV360. You must assign the appropriate role to the newly created 59A user. We recommend the{" "}
        <Text as="b" fontSize="1.1rem" pt={3}>
          Standard
        </Text>{" "}
        role. You can select an appropriate role by doing the following:
      </Text>
      <Box pl="2rem">
        <ol>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              On the{" "}
              <Text
                as="a"
                fontSize="1.1rem"
                style={{
                  textDecoration: "underline",
                }}
                target="_blank"
                href="https://displayvideo.google.com/ng_nav/users"
              >
                User Management page
              </Text>
              , click on the 59A user you just created
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              On the following page, check the box next to any partners that you wish to reassign the role of
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Click the "Action" button
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Select change role and then select the role that you wish to assign (We recommend{" "}
              <Text as="b" fontSize="1.1rem" pt={3}>
                Standard
              </Text>
              )
            </Text>
          </li>
          <li>
            <Text fontSize="1.1rem" pt={3}>
              Click the "Save" button
            </Text>
          </li>
        </ol>
      </Box>
      <Text fontSize="1.1rem" pt={6}>
        59A also needs access to your DV360 web UI. You can do this by creating a new user in your DV360 account with the following details:
      </Text>
      <Box pl="2rem">
        <ol type="a">
          <li>
            <Text as="i" fontSize="1.1rem" pt={3}>
              Name
            </Text>
            <Text as="span" fontSize="1.1rem" pt={3}>
              {" "}
              - Any name to help you identify this user as the 59A authorised account
            </Text>
          </li>
          <li>
            <Text as="i" fontSize="1.1rem" pt={3}>
              Email
            </Text>
            <Text as="span" fontSize="1.1rem" pt={3}>
              {" "}
              -{" "}
              <Text as="b" fontSize="1.1rem" pt={3}>
                {consoleEmailComponent}
              </Text>
            </Text>
          </li>
          <li>
            <Text as="i" fontSize="1.1rem" pt={3}>
              Type
            </Text>
            <Text as="span" fontSize="1.1rem" pt={3}>
              {" "}
              - This should be:{" "}
              <Text as="b" fontSize="1.1rem" pt={3}>
                Partner & Advertiser
              </Text>
            </Text>
          </li>
        </ol>
      </Box>

      <Text fontSize="1.1rem" pt={6}>
        Once you have completed these steps, please click "Next" below to continue with this integration setup wizard.
      </Text>
      <WizardNavigateNextButton next={{ type: "button", onClick: onNext }} />
    </>
  )
}

export default IntegrationInstructions
