// Example GridState Idea
import { StringKeyObject } from "../../types"

type GridObject = {
  column: number
  row: number
  width: number
  height: number
}

type GridState = StringKeyObject<GridObject>

export const exampleGridState = {
  column: 1,
  row: 1,
  width: 100,
  height: 100,
}

export default GridState
