import { gql } from "@apollo/client"

export const PLATFORM_CAMPAIGN_FRAGMENT = gql`
  fragment PlatformCampaignFragment on PlatformCampaign {
    __typename
    id
    clientId
    platform
    platformAdvertiserId {
      __typename
      id
      platform
    }
    platformCampaignId {
      __typename
      id
      platform
    }
    name
    currency
    campaignType
    managed
    platformSetupStatus
  }
`
