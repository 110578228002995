import { FC, useEffect, useState } from "react"
import { Box, Flex, VStack } from "@chakra-ui/react"
import { CampaignKpi, DailyMetric, MetricName, Pixel } from "../../../generated/graphql"
import { TileProps } from "../../../layouts/TilesGrid"
import GridState from "../../../layouts/TilesGrid/GridState"
import CampaignDashboardStateController from "../../../pages/CampaignDashboardPage/types"
import { Optional } from "../../../types"
import Tile from "../../Tile"
import MetricsPairButton from "../MetricsPairButton"
import StackedBarsWithLinesGraph from "../StackedBarWithLinesGraph"
import { MetricDataSet, MetricsComparisonPair } from "../types"
import { extractPixelNamesFromPixels, type GraphDataType, unPackDailyMetricsForGraphs } from "../utils"
import SelectableMetricsGraphLoader from "./SelectableMetricsGraphLoader"

type SelectableMetricsGraphDataSets = {
  campaignKpis: CampaignKpi[]
  metrics: DailyMetric[]
  selectableMetrics: MetricsComparisonPair[]
}

type GraphDataSets = Record<GraphDataType, MetricDataSet>

const SelectableMetricsGraph: FC<TileProps<CampaignDashboardStateController, GridState, SelectableMetricsGraphDataSets>> = ({ tileData, queryDetails }) => {
  const [selectedMetricsPair, setSelectedMetricsPair] = useState<Optional<MetricsComparisonPair>>(tileData?.selectableMetrics[0])
  const [graphDataSets, setGraphDataSets] = useState<GraphDataSets>()

  useEffect(() => {
    if (selectedMetricsPair && tileData?.metrics.length) {
      setGraphDataSets(
        unPackDailyMetricsForGraphs(tileData?.metrics, [
          {
            name: MetricName.Spend,
            minimise: true,
            isKpi: false,
          },
          selectedMetricsPair.originalMetric,
          selectedMetricsPair.metricToCompare,
        ])
      )
    }
  }, [selectedMetricsPair, tileData])

  return (
    <Tile loading={queryDetails?.loading || !tileData?.metrics.length} loader={<SelectableMetricsGraphLoader />} error={queryDetails?.error}>
      <VStack h="330px" justify="space-between">
        {tileData?.selectableMetrics.map((selectableMetric, index) => {
          const selectableMetricPixels = (selectableMetric.originalMetric.pixelIds ?? []).map((pixelId) => {
            const pixelName = tileData.metrics[0].metrics
              .find((metric) => metric.pixels?.find((pixel) => pixel.id === pixelId))
              ?.pixels?.find((pixel) => pixel.id === pixelId)?.name
            return {
              id: pixelId,
              name: pixelName ? pixelName : pixelId,
            } as Pixel
          })

          const pixelNames = extractPixelNamesFromPixels(selectableMetricPixels)

          return (
            <MetricsPairButton
              key={`${selectableMetric.originalMetric.name}-${index}`}
              onClick={() => setSelectedMetricsPair({ ...selectableMetric, isSelected: true })}
              {...selectableMetric}
              pixelNames={pixelNames}
              isSelected={selectableMetric.originalMetric === selectedMetricsPair?.originalMetric}
            />
          )
        })}
      </VStack>
      <Flex h="100%" w="100%" align="flex-end" justify="flex-end">
        <Box mr="-46px">{graphDataSets && <StackedBarsWithLinesGraph graphDataSets={graphDataSets} />}</Box>
      </Flex>
    </Tile>
  )
}

export default SelectableMetricsGraph
