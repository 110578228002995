import React, { PropsWithChildren } from "react"
import { Button, ButtonGroup, Flex, FlexProps, HStack, Input, Spacer, Text } from "@chakra-ui/react"
import { Table } from "@tanstack/react-table"
import { Select } from "chakra-react-select"
import { LeftArrowIcon, RightArrowIcon, ToEndArrowIcon, ToStartArrowIcon } from "../../../icons"
import { getSelectChakraStyles, Option, selectComponents } from "../../forms/SelectInput/SelectBaseUI"

interface PaginationProps<D extends object> extends FlexProps {
  tableInstance: Table<D>
  pageSizeDisabled?: boolean
}

const Pagination = <D extends object>({ pageSizeDisabled = false, tableInstance, ...rest }: PropsWithChildren<PaginationProps<D>>) => {
  const pageSizeOptions = [10, 20, 30, 40, 50].map((pageSize) => ({
    label: pageSize.toString(),
    value: pageSize.toString(),
  }))

  return (
    <Flex justifyContent={"flex-end"} {...rest}>
      <HStack spacing={4} alignItems={"center"}>
        <HStack spacing={1}>
          <Button onClick={() => tableInstance.setPageIndex(0)} isDisabled={!tableInstance.getCanPreviousPage()} size={"sm"} width={8} variant="unstyled">
            <ToStartArrowIcon h={6} w={6} />
          </Button>
          <Spacer />
          <Button onClick={() => tableInstance.previousPage()} isDisabled={!tableInstance.getCanPreviousPage()} size={"sm"} width={8} variant="unstyled">
            <LeftArrowIcon h={6} w={6} />
          </Button>
          <Spacer />
          <Button onClick={() => tableInstance.nextPage()} isDisabled={!tableInstance.getCanNextPage()} size={"sm"} width={8} variant="unstyled">
            <RightArrowIcon h={6} w={6} />
          </Button>
          <Spacer />
          <Button
            onClick={() => tableInstance.setPageIndex(tableInstance.getPageCount() - 1)}
            disabled={!tableInstance.getCanNextPage()}
            size={"sm"}
            width={8}
            variant="unstyled"
          >
            <ToEndArrowIcon h={6} w={6} />
          </Button>
        </HStack>
        <Spacer />
        <ButtonGroup>
          <Flex alignItems={"center"}>
            <Text fontSize="xl" lineHeight={5}>
              Page
            </Text>
            <Text fontSize="xl" fontWeight="bold" lineHeight={5}>
              {`\u00A0\u00A0${tableInstance.getState().pagination.pageIndex + 1} of ${tableInstance.getPageCount()}`}
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems={"center"}>
            <Text fontSize="xl" lineHeight={5}>
              Go to page:
            </Text>
            <Input
              marginLeft={3}
              borderRadius={0}
              type="number"
              height={"38px"}
              width={16}
              defaultValue={tableInstance.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                tableInstance.setPageIndex(page)
              }}
              className="border p-1"
            />
          </Flex>
        </ButtonGroup>
        {pageSizeDisabled ? null : (
          <Flex alignItems={"center"}>
            <Text fontSize="xl" lineHeight={5} marginRight={3}>
              Page Size:
            </Text>
            <Select
              aria-label={`Select Input for page size`}
              onChange={(newValue: unknown) => {
                tableInstance.setPageSize(Number((newValue as Option).value))
              }}
              menuPlacement="top"
              options={pageSizeOptions}
              placeholder={tableInstance.getState().pagination.pageSize}
              value={tableInstance.getState().pagination.pageSize}
              components={selectComponents}
              chakraStyles={getSelectChakraStyles({ width: 24 })}
            />
          </Flex>
        )}
      </HStack>
    </Flex>
  )
}

export default Pagination
