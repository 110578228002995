import { gql } from "@apollo/client"

export const GET_PERMISSIONS = gql`
  query GetPermissions($resourceUris: [String!]!) {
    getPermissions(resourceUris: $resourceUris) {
      resourceUri
      actions
    }
  }
`
