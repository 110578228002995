import { FC } from "react"
import { Text } from "@chakra-ui/react"
import { MetricDataSet } from "./types"
import { formatMetric } from "./utils"

interface Props extends Pick<MetricDataSet, "data" | "name"> {
  currencySymbol: string
}

const MetricAverage: FC<Props> = ({ data, name, currencySymbol }) => {
  const sumValues = data.reduce((result, dataPoint) => {
    result = result + dataPoint.value

    return result
  }, 0)

  const value = sumValues / data.length

  return (
    <Text fontSize="1.1rem" as="b">
      {formatMetric({ name, value, currencySymbol })}
    </Text>
  )
}

export default MetricAverage
