import { FC } from "react"
import { Center, HStack, Text, VStack } from "@chakra-ui/react"
import Tile, { TileProps } from "../../components/Tile"
import { ErrorSadFace } from "../../images/ErrorSadFace"
import colors from "../../theme/colors"

interface Props extends TileProps {
  message: string
  emailSubject?: string
  emailBody?: string
}

const ErrorFallBack: FC<Props> = ({ message, emailSubject, emailBody, ...rest }) => {
  const mailtoParams = getMailtoParams(emailSubject, emailBody)

  return (
    <Tile w="container.xl" h="container.sm" {...rest}>
      <Center w="100%">
        <HStack w="75%" justify="space-between">
          <ErrorSadFace width="2xs" height="2xs" />
          <VStack pl={6} alignItems="flex-start">
            <Text as="b" fontSize="6xl">
              Uh-oh!
            </Text>
            <Text as="span" fontSize="3xl">
              {message}
            </Text>
            <div>
              <Text as="span" fontSize="3xl">{`Please email us at `}</Text>
              <Text fontSize="3xl" as="a" color={colors.colors.brand["400"]} href={`mailto:helpdesk@59a.co.uk${mailtoParams}`}>
                helpdesk@59a.co.uk
              </Text>
              <Text as="span" fontSize="3xl">
                .
              </Text>
            </div>
          </VStack>
        </HStack>
      </Center>
    </Tile>
  )
}

export default ErrorFallBack

const getMailtoParams = (subject = "", body = ""): string => {
  const questionMark = subject || body ? "?" : ""
  const encodedSubject = encodeURIComponent(subject)
  const encodedBody = encodeURIComponent(body)

  return `${questionMark}${encodedSubject ? "subject=" : ""}${encodedSubject}${encodedSubject && encodedBody ? "&" : ""}${
    encodedBody ? "body=" : ""
  }${encodedBody}`
}
