export enum EnvKey {
  UiVersion = "REACT_APP_UI_VERSION",
  GraphqlApiUrl = "REACT_APP_GRAPHQL_API",
  CognitoRegion = "REACT_APP_COGNITO_REGION",
  CognitoUserPoolId = "REACT_APP_COGNITO_USER_POOL_ID",
  CognitoClientId = "REACT_APP_COGNITO_CLIENT_ID",
  Stage = "REACT_APP_STAGE",
  MaintenanceHours = "REACT_APP_MAINTENANCE_HOURS",
}

export default class Env {
  static getString = (key: EnvKey, defaultVal: string): string => {
    return (process.env[key] || defaultVal).trim()
  }
  static requireString = (key: EnvKey): string => {
    return Env._require(key, process.env[key]?.trim())
  }

  static getBoolean = (key: EnvKey, defaultVal: boolean): boolean => {
    return Env._getBoolean(key) || defaultVal
  }
  static requireBoolean = (key: EnvKey): boolean => {
    return Env._require(key, Env._getBoolean(key))
  }

  private static _getBoolean = (key: EnvKey) => {
    const v = process.env[key]?.toLowerCase()?.trim()
    if (v === "true") return true
    if (v === "false") return false
    return undefined
  }

  private static _require<T>(key: EnvKey, val?: T): T {
    if (!val) throw Error(`Required environment variable: '${key}' not found`)
    return val
  }
}
