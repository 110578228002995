import { IconBase, IconBaseProps } from "./IconBase"

export const SortDescIcon = ({ viewBox = "0 0 21 26", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M13.5686 21.9639L18.4062 15.4333C18.5187 15.2715 18.6988 15.1573 18.9059 15.1171C19.1133 15.0768 19.3296 15.1138 19.5061 15.2196C19.6826 15.3252 19.8042 15.491 19.8428 15.6784C19.8814 15.8659 19.8339 16.0598 19.7114 16.2153L13.4369 24.6855C13.2451 24.9444 12.8876 25.06 12.5567 24.9695C12.2258 24.8791 11.9997 24.6045 12 24.2932V1.70585C12 1.31598 12.3511 1 12.7843 1C13.2175 1 13.5686 1.31598 13.5686 1.70585L13.5686 21.9639Z"
        fill="#67DD8F"
        stroke="#67DD8F"
        strokeWidth="1.5"
      />
      <path
        d="M2.4242 10.5672C2.17879 10.8787 1.70269 10.9582 1.34977 10.7464C0.996527 10.5349 0.894504 10.1089 1.11908 9.78524L7.39354 1.31499C7.58533 1.05636 7.94256 0.940836 8.27314 1.031C8.60371 1.12088 8.83013 1.39495 8.83043 1.70597V24.2933C8.83043 24.6832 8.47933 24.9991 8.04612 24.9991C7.61292 24.9991 7.26182 24.6832 7.26182 24.2933V4.0367L2.4242 10.5672Z"
        fill="#A7BDD6"
        stroke="#A7BDD6"
        strokeWidth="0.5"
      />
    </g>
  </IconBase>
)
