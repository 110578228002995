import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import PasswordInputUI, { PasswordInputUIProps } from "./PasswordInputUI"

interface PasswordInputProps extends Omit<PasswordInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
}

const PasswordInput: FC<PasswordInputProps> = ({ placeholder = "", width, size, control, name, helperText = "", tooltip = "", label, isDisabled = false }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <PasswordInputUI //
      placeholder={placeholder}
      field={field}
      name={name}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      size={size}
      width={width}
    />
  )
}

export default PasswordInput
