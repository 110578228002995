import React, { FC } from "react"
import { ApolloError } from "@apollo/client"
import { HStack } from "@chakra-ui/react"
import { UseFormHandleSubmit } from "react-hook-form"
import { z } from "zod"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import TransferLists from "../../../components/forms/TransferLists"
import { MutationAssignUsersToPlatformCampaignArgs, Platform } from "../../../generated/graphql"
import mapErrorTypeToMessage from "../../../graphql/errors"
import { CustomGraphQLError } from "../../../types"

export const assignUsersFormSchema = z.object({
  clientId: z.number(),
  platform: z.nativeEnum(Platform),
  platformCampaignId: z.string(),
  platformIntegrationId: z.number(),
  users: z.array(
    z.object({
      clientId: z.number(),
      userId: z.string(),
    })
  ),
})

type AssignUsersFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmitHandler: (data: MutationAssignUsersToPlatformCampaignArgs) => void
  error?: ApolloError
  campaignName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: UseFormHandleSubmit<MutationAssignUsersToPlatformCampaignArgs>
  caller: string
}

const AssignUsersForm: FC<AssignUsersFormProps> = ({ caller, error, onSubmitHandler, handleSubmit, campaignName }) => {
  const errorType = error && error.graphQLErrors ? (error?.graphQLErrors[0] as CustomGraphQLError).errorType : undefined

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <TransferLists caller={caller} />
      <HStack>
        <Button type="submit">Save</Button>
        <ErrorText
          height={12}
          message={
            errorType
              ? mapErrorTypeToMessage({
                  errorType,
                  name: campaignName,
                })
              : ""
          }
          size="small"
        />
      </HStack>
    </form>
  )
}

export default AssignUsersForm
