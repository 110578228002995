import React, { FC } from "react"
import { Box, Heading, HStack } from "@chakra-ui/react"
import { Control } from "react-hook-form"
import CurrencyInput from "../CurrencyInput"
import IntegerInput from "../IntegerInput"
import PercentageInput from "../PercentageInput"
import { FormValues, KpiEntry, KpiTargetType } from "./KPI"

interface KpiConstraintsProps {
  kpi: KpiEntry
  currencySymbol: string
  fieldName: keyof FormValues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FormValues, any>
}

enum MinMax {
  Minimum = "Min",
  Maximum = "Max",
}

const KpiConstraints: FC<KpiConstraintsProps> = ({ kpi, fieldName, currencySymbol, control }) => {
  return (
    <Box>
      <Heading size="sm">Target Constraints</Heading>
      <HStack borderTop={"solid 1px grey"} justify="space-between">
        <ConstraintsBlock //
          label={MinMax.Minimum}
          kpi={kpi}
          fieldName={`${fieldName}.constraints.min` as keyof FormValues}
          currencySymbol={currencySymbol}
          control={control}
        />
        <ConstraintsBlock //
          label={MinMax.Maximum}
          kpi={kpi}
          fieldName={`${fieldName}.constraints.max` as keyof FormValues}
          currencySymbol={currencySymbol}
          control={control}
        />
      </HStack>
    </Box>
  )
}

interface ConstraintsBlockProps {
  label: string
  kpi: KpiEntry
  currencySymbol: string
  fieldName: keyof FormValues
  control: Control<FormValues, unknown>
}

const ConstraintsBlock: FC<ConstraintsBlockProps> = ({ label, kpi, currencySymbol, fieldName, control }) => {
  const integerFieldName = `${fieldName}.target.integer` as keyof FormValues
  const monetaryFieldName = `${fieldName}.target.monetary` as keyof FormValues
  const percentageFieldName = `${fieldName}.target.percentage` as keyof FormValues

  const tooltipText =
    label === MinMax.Minimum ? `${label} constraint should be within a tenth of the target` : `${label} constraint should be within a ten times the target`

  return (
    <>
      {kpi.targetType === KpiTargetType.Integer && (
        <IntegerInput //
          size="sm"
          name={integerFieldName}
          control={control}
          label={label}
          tooltip={tooltipText}
          width={48}
        />
      )}
      {kpi.targetType === KpiTargetType.Monetary && (
        <CurrencyInput //
          size="sm"
          name={monetaryFieldName}
          control={control}
          symbol={currencySymbol}
          label={label}
          tooltip={tooltipText}
        />
      )}
      {kpi.targetType === KpiTargetType.Percentage && (
        <PercentageInput //
          size="sm"
          name={percentageFieldName}
          control={control}
          label={label}
          tooltip={tooltipText}
        />
      )}
    </>
  )
}

export default KpiConstraints
