import { IconBase, IconBaseProps } from "./IconBase"

export const ToStartArrowIcon = ({ viewBox = "0 0 29 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <path d="M12.67 0L15.5 2.829L6.161 12.004L15.5 21.171L12.67 24L0.5 12.004L12.67 0Z" fill="currentColor" />
      <path d="M25.67 0L28.5 2.829L19.161 12.004L28.5 21.171L25.67 24L13.5 12.004L25.67 0Z" fill="currentColor" />
    </>
  </IconBase>
)
