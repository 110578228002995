import React, { FC, useState } from "react"
import { Input, InputRightElement } from "@chakra-ui/react"
import Button from "../../Button"
import BaseInput, { BaseInputProps } from "../BaseInput"

export interface PasswordInputUIProps extends Omit<BaseInputProps, "children"> {
  placeholder?: string
}

const PasswordInputUI: FC<PasswordInputUIProps> = ({
  name,
  field,
  error,
  isDisabled = false,
  isInvalid,
  label = "",
  placeholder = "Please enter password",
  helperText = "",
  tooltip = "",
  size = "sm",
  width = 96,
  variant = "flushed",
}) => {
  const [value, setValue] = useState(field.value || "")
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)
  const buttonText = show ? "Hide" : "Show"

  return (
    <BaseInput
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
      width={width}
    >
      <Input
        aria-label={`Password Input for ${label}`}
        h="2.5rem"
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder={placeholder}
        onChange={(changedValue) => {
          const valueCopy = changedValue.currentTarget.value ?? null
          // send data to react hook form
          field.onChange(valueCopy)
          // update local state
          setValue(valueCopy)
        }}
        value={value}
        variant={variant}
      />
      <InputRightElement width="4.5rem">
        <Button aria-label={`${buttonText} password`} size="sm" onClick={handleClick} isInline={true}>
          {buttonText}
        </Button>
      </InputRightElement>
    </BaseInput>
  )
}

export default PasswordInputUI
