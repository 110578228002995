import { SegmentType } from "../../components/dataVisualisation/types"
import { CampaignType } from "../../generated/graphql"
import { StringKeyObject } from "../../types"

export const defaultCampaignDashboardState = {
  selectedSegmentsTab: 0,
  selectedCreativeIds: [],
  selectedLineItemIds: [],
  selectedMetricView: 0, // TODO: Add this for when we can come back and rehydrate
}

export type CampaignDashboardStateItems = {
  selectedSegmentsTab: number
  selectedCreativeIds: string[]
  selectedLineItemIds: string[]
  selectedMetricView: number // TODO: Add this for when we can come back and rehydrate
}

export type SelectedSegmentsTableChangeProps = {
  segmentType?: SegmentType
  segments: StringKeyObject<boolean>
}

export type SelectedSegmentsListChangeProps = {
  segmentType?: SegmentType
  segments: Array<string>
}

type CampaignDashboardStateController = {
  campaignType: CampaignType
  campaignDashboardState: CampaignDashboardStateItems
  campaignCurrencySymbol: string
  onResetSelectedSegments: VoidFunction
  onSelectedSegmentsListChange: ({ segmentType, segments }: SelectedSegmentsListChangeProps) => void
  onSelectedSegmentsTableChange: ({ segmentType, segments }: SelectedSegmentsTableChangeProps) => void
  onSegmentsTabChange: (index: number) => void
}

export default CampaignDashboardStateController
