import { FC } from "react"
import { Center, HStack, Text, VStack } from "@chakra-ui/react"
import Tile from "../../components/Tile"
import { ErrorSadFace } from "../../images/ErrorSadFace"

const MainLayoutErrorState: FC = () => (
  <Tile w="container.xl" h="container.sm">
    <Center w="100%">
      <HStack w="65%" justify="space-between">
        <ErrorSadFace width="2xs" height="2xs" />
        <VStack alignItems="flex-start">
          <Text as="b" fontSize="6xl">
            Uh-oh!
          </Text>
          <Text fontSize="3xl">Something's not quite right,</Text>
          <Text fontSize="3xl">please contact our support team.</Text>
        </VStack>
      </HStack>
    </Center>
  </Tile>
)

export default MainLayoutErrorState
