import React, { FC, useEffect, useMemo } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Box, useToast } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate } from "react-router-dom"
import Button from "../../components/Button"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import { Client } from "../../generated/graphql"
import { GET_CLIENT, UPDATE_ACCOUNT_SETTINGS } from "../../graphql"
import useAuth from "../../hooks/useAuth"
import MainLayout from "../../layouts/Main"
import { logError, logUnhandledError } from "../../log"
import AuthenticatedProps from "../AuthenticatedProps"
import AccountSettingsForm, { type FormValues } from "./components/AccountSettingsForm"

type AccountSettingsPageProps = AuthenticatedProps

const AccountSettingsPage: FC<AccountSettingsPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const userIsAdmin = user?.roles.some((role) => role.isAdmin)
  const clientId = Number(user?.clientId) ?? 0

  const navigate = useNavigate()

  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_CLIENT, {
    fetchPolicy: "network-only",
    variables: {
      clientId,
    },
  })

  const [updateAccountSettings, { loading: updateLoading, error: updateError, reset: updateReset }] = useMutation(UPDATE_ACCOUNT_SETTINGS, {
    refetchQueries: [
      {
        query: GET_CLIENT,
        variables: {
          clientId,
        },
      },
    ],
  })

  const accountData = useMemo(() => {
    if (queryError) logError("ERROR", queryError)
    if (data) {
      return data.getClient as Client
    }
    return undefined
  }, [data, queryError])

  const toast = useToast()

  useEffect(() => {
    if (updateError) {
      toast({
        title: "Update Account Settings failed.",
        description: "Please try again later or contact support.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })

      logError("ERROR", updateError)
    }
  }, [toast, updateError])

  const updateAccountSettingsHandler = async (data: FormValues) => {
    const { clientContact, billingContact } = data
    await updateAccountSettings({
      variables: {
        clientId,
        clientContact,
        billingContact,
      },
    })
    toast({
      title: "Account Settings Updated",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
    navigate(-1)
  }
  const pageTitle = `Update Account Settings`

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle}>
      {queryLoading && <Loading />}
      {!queryLoading && userIsAdmin && (
        <Box>
          <Button
            onClick={() => {
              updateReset()
              navigate(-1)
            }}
            isInline={true}
            isSecondary={true}
            my={3}
          >
            Back
          </Button>
          <ErrorBoundary
            fallback={
              <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
            }
            onError={logUnhandledError}
          >
            <AccountSettingsForm error={updateError} loading={updateLoading} data={accountData} onSubmitHandler={updateAccountSettingsHandler} />
          </ErrorBoundary>
        </Box>
      )}
      {!queryLoading && !userIsAdmin && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default AccountSettingsPage
