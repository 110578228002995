import React, { FC } from "react"
import useAuth from "../../hooks/useAuth"
import MainLayout from "../../layouts/Main"
import AuthenticatedProps from "../AuthenticatedProps"

type HomePageProps = AuthenticatedProps

const HomePage: FC<HomePageProps> = ({ signOut }) => {
  const { user } = useAuth()

  return <MainLayout user={user} signOut={signOut}></MainLayout>
}

export default HomePage
