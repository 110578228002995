import { IconBase, IconBaseProps } from "./IconBase"

export const SortIcon = ({ viewBox = "0 0 22 26", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <path
      d="M2.4242 10.5672C2.17879 10.8787 1.70269 10.9582 1.34977 10.7464C0.996527 10.5349 0.894504 10.1089 1.11908 9.78524L7.39354 1.31499C7.58533 1.05636 7.94256 0.940835 8.27314 1.031C8.60371 1.12088 8.83013 1.39495 8.83043 1.70597V24.2933C8.83043 24.6832 8.47933 24.9991 8.04612 24.9991C7.61292 24.9991 7.26182 24.6832 7.26182 24.2933V4.0367L2.4242 10.5672ZM14.7127 21.9639L19.5503 15.4333C19.6628 15.2715 19.8429 15.1573 20.05 15.1171C20.2574 15.0768 20.4737 15.1138 20.6502 15.2196C20.8267 15.3252 20.9483 15.491 20.9869 15.6784C21.0255 15.8659 20.978 16.0598 20.8555 16.2153L14.581 24.6855C14.3892 24.9444 14.0317 25.06 13.7008 24.9695C13.3699 24.8791 13.1438 24.6045 13.1441 24.2932V1.70585C13.1441 1.31598 13.4952 1 13.9284 1C14.3616 1 14.7127 1.31598 14.7127 1.70585L14.7127 21.9639Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </IconBase>
)
