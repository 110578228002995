import { StepsStyleConfig } from "chakra-ui-steps"
import colors from "../colors"

export const WizardStepsStyleConfig = {
  ...StepsStyleConfig,
  variants: {
    custom: {
      connector: {
        _highlighted: {
          borderColor: colors.colors.alerts["affirmative"],
        },
      },
      icon: {
        strokeWidth: "1px",
      },
      iconContainer: {},
      iconLabel: {
        color: "inherit",
        fontSize: "2rem",
        fontWeight: "bold",
        lineHeight: 1.35,
        _activeStep: {
          color: "inherit",
        },
      },
      label: {
        color: colors.colors.brand["200"],
        opacity: 1,
      },
      step: {},
      stepContainer: {},
      stepIconContainer: {
        bg: colors.colors.brand["700"],
        border: "1px solid",
        borderColor: colors.colors.brand["200"],
        color: colors.colors.brand["200"],
        height: "48px",
        width: "48px",
        _activeStep: {
          bg: colors.colors.brand["200"],
          borderColor: colors.colors.brand["200"],
          color: colors.colors.brand["700"],
        },
        _highlighted: {
          bg: colors.colors.alerts["affirmative"],
          borderColor: colors.colors.alerts["affirmative"],
        },
      },
    },
  },
  defaultProps: {
    variant: "custom",
  },
}
