import { IconBase, IconBaseProps } from "../IconBase"

export const ImpressionsIcon = ({ viewBox = "0 0 300 150", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <g clipPath="url(#clip0_415_1082)">
        <path
          d="M149.992 150C70.9697 150 8.37887 83.9712 5.75301 81.1605L0 74.996L5.75301 68.8315C8.37887 66.0208 70.9697 0 149.992 0C229.014 0 291.605 66.0208 294.239 68.8315L300 74.996L294.239 81.1605C291.613 83.9792 229.022 150 149.992 150V150ZM25.1843 74.988C42.3399 90.8784 92.3108 131.97 149.992 131.97C207.673 131.97 257.676 90.8943 274.808 75.004C257.652 59.1137 207.673 18.0224 149.992 18.0224C92.1755 18.0224 42.3161 59.0977 25.1843 74.988V74.988Z"
          fill="currentColor"
        />
        <path
          d="M149.992 25.0732C121.728 25.0732 98.9628 48.7331 100.292 77.3916C101.477 102.968 122.174 123.721 147.668 124.887C176.211 126.188 199.748 103.359 199.748 75.012C199.748 47.4395 177.476 25.0812 149.992 25.0812V25.0732ZM149.992 88.3391C142.449 88.3391 136.377 82.0309 136.727 74.3812C137.038 67.5939 142.592 62.0123 149.355 61.6929C156.978 61.3336 163.28 67.4262 163.28 75.004C163.28 82.3663 157.336 88.3391 150 88.3391H149.992Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_415_1082">
          <rect width="300" height="150" fill="white" />
        </clipPath>
      </defs>
    </g>
  </IconBase>
)
