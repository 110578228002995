import React, { FC } from "react"
import { Control, useController } from "react-hook-form"
import CheckboxGroupInputUI, { CheckboxGroupInputUIProps } from "./CheckboxGroupInputUI"

interface CheckboxGroupInputProps extends Omit<CheckboxGroupInputUIProps, "field" | "isInvalid"> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>
  direction?: "row" | "column"
}

const CheckboxGroupInput: FC<CheckboxGroupInputProps> = ({
  size = "sm",
  name,
  label,
  options,
  control,
  isDisabled = false,
  helperText = "",
  placeholder,
  direction = "column",
}) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  })

  return (
    <CheckboxGroupInputUI //
      options={options}
      field={field}
      name={name}
      label={label}
      error={error}
      isDisabled={isDisabled}
      isInvalid={invalid}
      placeholder={placeholder}
      helperText={helperText}
      size={size}
      direction={direction}
    />
  )
}

export default CheckboxGroupInput
