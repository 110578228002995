import { gql } from "@apollo/client"

export const DAILY_METRICS_FRAGMENT = gql`
  fragment DailyMetricsFragment on CampaignDailyMetrics {
    dailyMetrics {
      date
      metrics {
        __typename
        ... on CurrencyMetric {
          name
          valueFloat
          currency
          pixels {
            id
            name
          }
        }
        ... on FloatMetric {
          name
          valueFloat
          pixels {
            id
            name
          }
        }
        ... on IntMetric {
          name
          valueInt
          pixels {
            id
            name
          }
        }
      }
    }
  }
`
