import React, { useState } from "react"
import { Flex } from "@chakra-ui/react"
import colors from "../../../theme/colors"
import DebouncedTextInput from "../../DebouncedTextInput"
import BaseTable, { BaseTableProps } from "../BaseTable/BaseTable"

interface PageTableProps<D extends object> extends BaseTableProps<D> {
  hasGlobalFilter?: boolean
  heightOtherElementsRem?: number
  widthOtherElementsRem?: number
}

const PageTable = <D extends object>({
  tableColumns,
  defaultSort,
  loading,
  error,
  columnVisibility,
  pagination,
  onPaginationChange,
  pageSizeDisabled = false,
  hasGlobalFilter = false,
  heightOtherElementsRem = 16.35,
  widthOtherElementsRem = 10.9,
  renderSubComponent,
  tableRows,
  headerBackground = colors.colors.brand["900"],
  headerBorderBottom = "1px solid",
  headerBorderColor = colors.colors.brand["200"],
  headerFontSize = "1.2rem",
  paginationPaddingTop = "0rem",
  paginationMarginRight = "0px",
  rowFontSize = "1.1rem",
  scrollBarVerticalAdjust = 48,
}: PageTableProps<D>) => {
  const containerHeight = `calc(100vh - ${heightOtherElementsRem}rem)`
  const containerWidth = `calc(100vw - ${widthOtherElementsRem}rem)`
  const [globalFilterState, setGlobalFilterState] = useState<string>("")

  const setGlobalFilter = (change: string) => {
    setGlobalFilterState(change)
  }

  return (
    <>
      {hasGlobalFilter && (
        <Flex justify="end">
          <DebouncedTextInput
            placeholder="Search ..."
            label="Global Filter Search"
            value={globalFilterState ?? ""}
            onChange={(value: string | number) => setGlobalFilter(String(value))}
            width={96}
          />
        </Flex>
      )}
      <BaseTable<(typeof tableRows)[0]>
        error={error}
        loading={loading}
        defaultSort={defaultSort}
        tableColumns={tableColumns}
        tableRows={tableRows}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        pageSizeDisabled={pageSizeDisabled}
        renderSubComponent={renderSubComponent}
        columnVisibility={columnVisibility}
        globalFilter={hasGlobalFilter ? globalFilterState : undefined}
        setGlobalFilter={hasGlobalFilter ? setGlobalFilter : undefined}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        headerBackground={headerBackground}
        headerBorderBottom={headerBorderBottom}
        headerBorderColor={headerBorderColor}
        headerFontSize={headerFontSize}
        paginationPaddingTop={paginationPaddingTop}
        paginationMarginRight={paginationMarginRight}
        rowFontSize={rowFontSize}
        scrollBarVerticalAdjust={scrollBarVerticalAdjust}
      />
    </>
  )
}

export default PageTable
