import { IconBase, IconBaseProps } from "../IconBase"

export const CpmGbpIcon = ({ viewBox = "0 0 208 200", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <g clipPath="url(#clip0_502_752)">
        <path
          d="M103.655 0C78.4407 0 58 20.4049 58 45.575C58 70.7451 78.4407 91.15 103.655 91.15C128.869 91.15 149.31 70.7451 149.31 45.575C149.31 20.4049 128.869 0 103.655 0ZM120.396 69.3885H83.0189L84.3949 59.0101C91.1221 58.7048 92.4981 51.9131 92.4981 47.1055H86.8412V36.4218H92.651C92.651 22.991 101.366 13.6047 115.508 13.452L116.961 24.6699C108.628 25.433 105.417 29.0959 105.417 36.4218H116.655V47.1055H104.729C104.729 50.3106 104.729 57.1786 100.754 59.0864C100.754 59.239 100.831 59.3153 100.831 59.3916C103.659 58.6285 106.946 58.3233 112.145 58.3233H120.401V69.3885H120.396Z"
          fill="currentColor"
        />
        <path d="M191.293 112.723L16.0009 112.569" stroke="currentColor" strokeWidth="15" strokeMiterlimit="10" />
        <path
          d="M31.7186 191.187V154.586H17.5215V145.96C19.5098 146.013 21.4328 145.865 23.2906 145.515C25.1424 145.165 26.8102 144.548 28.288 143.664C29.7659 142.774 31.0183 141.611 32.0391 140.157C33.06 138.704 33.7307 136.906 34.0571 134.758H43.1737V191.193H31.7186V191.187Z"
          fill="currentColor"
        />
        <path
          d="M59.3825 148.427C60.4982 144.67 62.0038 141.648 63.9053 139.379C65.8068 137.103 68.0202 135.485 70.5515 134.511C73.0768 133.538 75.7521 133.048 78.5773 133.048C81.4025 133.048 84.1618 133.538 86.6871 134.511C89.2124 135.485 91.4438 137.109 93.3692 139.379C95.2947 141.648 96.8183 144.67 97.934 148.427C99.0497 152.189 99.6075 156.824 99.6075 162.342C99.6075 167.86 99.0497 172.77 97.934 176.586C96.8183 180.402 95.3007 183.442 93.3692 185.718C91.4378 187.993 89.2124 189.611 86.6871 190.585C84.1618 191.558 81.4565 192.048 78.5773 192.048C75.6981 192.048 73.0768 191.558 70.5515 190.585C68.0262 189.611 65.8068 187.987 63.9053 185.718C62.0038 183.448 60.4922 180.402 59.3825 176.586C58.2668 172.77 57.709 168.022 57.709 162.342C57.709 156.662 58.2668 152.183 59.3825 148.427ZM69.4478 168.231C69.5557 170.53 69.8976 172.752 70.4675 174.884C71.0373 177.022 71.9491 178.849 73.1967 180.36C74.4444 181.877 76.2379 182.63 78.5773 182.63C80.9166 182.63 82.8001 181.871 84.0778 180.36C85.3554 178.843 86.2792 177.022 86.849 174.884C87.4189 172.746 87.7608 170.53 87.8687 168.231C87.9767 165.931 88.0307 163.972 88.0307 162.348C88.0307 161.375 88.0187 160.198 87.9887 158.818C87.9587 157.439 87.8507 156.017 87.6648 154.56C87.4728 153.097 87.2029 151.652 86.849 150.218C86.4951 148.785 85.9673 147.501 85.2595 146.36C84.5516 145.226 83.6579 144.306 82.5722 143.601C81.4865 142.896 80.1549 142.544 78.5773 142.544C76.9997 142.544 75.6861 142.896 74.6244 143.601C73.5627 144.306 72.6929 145.226 72.0151 146.36C71.3373 147.495 70.8034 148.785 70.4255 150.218C70.0476 151.652 69.7717 153.097 69.6097 154.56C69.4478 156.023 69.3518 157.439 69.3218 158.818C69.2918 160.198 69.2798 161.375 69.2798 162.348C69.2798 163.972 69.3338 165.931 69.4418 168.231H69.4478Z"
          fill="currentColor"
        />
        <path
          d="M104.703 148.427C105.819 144.67 107.324 141.648 109.226 139.379C111.127 137.103 113.34 135.485 115.872 134.511C118.397 133.538 121.072 133.048 123.898 133.048C126.723 133.048 129.482 133.538 132.007 134.511C134.533 135.485 136.764 137.109 138.69 139.379C140.615 141.648 142.139 144.67 143.254 148.427C144.37 152.189 144.928 156.824 144.928 162.342C144.928 167.86 144.37 172.77 143.254 176.586C142.139 180.402 140.621 183.442 138.69 185.717C136.758 187.993 134.533 189.611 132.007 190.585C129.482 191.558 126.777 192.048 123.898 192.048C121.018 192.048 118.397 191.558 115.872 190.585C113.346 189.611 111.127 187.987 109.226 185.717C107.324 183.448 105.813 180.402 104.703 176.586C103.587 172.77 103.029 168.022 103.029 162.342C103.029 156.662 103.587 152.183 104.703 148.427ZM114.768 168.231C114.876 170.53 115.218 172.752 115.788 174.884C116.358 177.022 117.269 178.849 118.517 180.36C119.765 181.877 121.558 182.63 123.898 182.63C126.237 182.63 128.12 181.871 129.398 180.36C130.676 178.843 131.599 177.022 132.169 174.884C132.739 172.746 133.081 170.53 133.189 168.231C133.297 165.931 133.351 163.972 133.351 162.348C133.351 161.375 133.339 160.198 133.309 158.818C133.279 157.439 133.171 156.017 132.985 154.56C132.793 153.097 132.523 151.652 132.169 150.218C131.815 148.785 131.288 147.501 130.58 146.36C129.872 145.226 128.978 144.306 127.893 143.601C126.807 142.896 125.475 142.544 123.898 142.544C122.32 142.544 121.006 142.896 119.945 143.601C118.883 144.306 118.013 145.226 117.335 146.36C116.658 147.495 116.124 148.785 115.746 150.218C115.368 151.652 115.092 153.097 114.93 154.56C114.768 156.023 114.672 157.439 114.642 158.818C114.612 160.198 114.6 161.375 114.6 162.348C114.6 163.972 114.654 165.931 114.762 168.231H114.768Z"
          fill="currentColor"
        />
        <path
          d="M149.166 148.427C150.281 144.67 151.787 141.648 153.688 139.379C155.59 137.103 157.803 135.485 160.335 134.511C162.86 133.538 165.535 133.048 168.36 133.048C171.186 133.048 173.945 133.538 176.47 134.511C178.996 135.485 181.227 137.109 183.152 139.379C185.078 141.648 186.601 144.67 187.717 148.427C188.833 152.189 189.391 156.824 189.391 162.342C189.391 167.86 188.833 172.77 187.717 176.586C186.601 180.402 185.084 183.442 183.152 185.718C181.221 187.993 178.996 189.611 176.47 190.585C173.945 191.558 171.24 192.048 168.36 192.048C165.481 192.048 162.86 191.558 160.335 190.585C157.809 189.611 155.59 187.987 153.688 185.718C151.787 183.448 150.275 180.402 149.166 176.586C148.05 172.77 147.492 168.022 147.492 162.342C147.492 156.662 148.05 152.183 149.166 148.427ZM159.231 168.231C159.339 170.53 159.681 172.752 160.251 174.884C160.821 177.022 161.732 178.849 162.98 180.36C164.228 181.877 166.021 182.63 168.36 182.63C170.7 182.63 172.583 181.871 173.861 180.36C175.139 178.843 176.062 177.022 176.632 174.884C177.202 172.746 177.544 170.53 177.652 168.231C177.76 165.931 177.814 163.972 177.814 162.348C177.814 161.375 177.802 160.198 177.772 158.818C177.742 157.439 177.634 156.017 177.448 154.56C177.256 153.097 176.986 151.652 176.632 150.218C176.278 148.785 175.75 147.501 175.043 146.36C174.335 145.226 173.441 144.306 172.355 143.601C171.27 142.896 169.938 142.544 168.36 142.544C166.783 142.544 165.469 142.896 164.408 143.601C163.346 144.306 162.476 145.226 161.798 146.36C161.12 147.495 160.587 148.785 160.209 150.218C159.831 151.652 159.555 153.097 159.393 154.56C159.231 156.023 159.135 157.439 159.105 158.818C159.075 160.198 159.063 161.375 159.063 162.348C159.063 163.972 159.117 165.931 159.225 168.231H159.231Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_502_752">
          <rect width="208" height="200" fill="white" />
        </clipPath>
      </defs>
    </>
  </IconBase>
)
