import { FC } from "react"
import { Box, Center, Text, VStack } from "@chakra-ui/react"
import { NoData } from "../../../images"
import colors from "../../../theme/colors"

const TrendGraphNoData: FC = () => (
  <Box p={2} m={0} width="100%">
    <Center>
      <VStack alignItems="center">
        <Text fontSize="md" textAlign="center">
          This metric has no trend data for the last 7 days.
        </Text>
        <NoData color={colors.colors.brand["400"]} height="25%" width="25%" />
      </VStack>
    </Center>
  </Box>
)

export default TrendGraphNoData
