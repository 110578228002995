import { gql } from "@apollo/client"

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($clientId: Int!, $name: String!, $clientContact: String!, $billingContact: String!) {
    updateClient(clientId: $clientId, name: $name, clientContact: $clientContact, billingContact: $billingContact) {
      ... on DirectClient {
        clientId
        name
        clientContact
        billingContact
      }

      ... on AgencyClient {
        clientId
        name
        clientContact
        billingContact
      }

      ... on Fifty9AClient {
        clientId
        name
      }
    }
  }
`
