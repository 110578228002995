import { FC } from "react"
import { InfoIcon } from "@chakra-ui/icons"
import { Box, Center, Flex, Text, Tooltip } from "@chakra-ui/react"
import { QRTZtmIcon } from "../../icons"
import colors from "../../theme/colors"
import { MetricsComparisonPair } from "./types"
import { getMetricIcon, mapMetricNameToDisplayName } from "./utils"

interface MetricsPairButtonProps extends MetricsComparisonPair {
  onClick: () => void
  pixelNames?: string
}

const MetricsPairButton: FC<MetricsPairButtonProps> = ({ onClick, currencySymbol, isKpi, isSelected, originalMetric, metricToCompare, pixelNames }) => {
  const bgColor = isSelected ? colors.colors.brand["400"] : colors.colors.brand["200"]
  const textColor = colors.colors.brand["900"]

  return (
    <Flex
      cursor="pointer"
      onClick={onClick}
      borderRadius={4}
      px={2}
      py={2}
      w={48}
      h="60px"
      align="space-between"
      justify="space-between"
      position="relative"
      bg={bgColor}
    >
      <Flex align="center" justify="space-between" direction="column" width="70px" zIndex={10}>
        <Center>{getMetricIcon({ name: originalMetric.name, currency: currencySymbol, height: 8, width: 8 })}</Center>
        <Text fontSize="0.8rem" as="b" color={textColor}>
          {mapMetricNameToDisplayName[originalMetric.name]}
        </Text>
      </Flex>
      <Box position="absolute" top="24px" right="81px" zIndex={10} p={1}>
        <Text fontSize="1rem" fontWeight={600} color={textColor}>
          VS
        </Text>
      </Box>
      <Flex align="center" justify="space-between" direction="column" width="70px" zIndex={10}>
        <Center>{getMetricIcon({ name: metricToCompare.name, currency: currencySymbol, height: 8, width: 8 })}</Center>
        <Text fontSize="0.8rem" as="b" color={textColor}>
          {mapMetricNameToDisplayName[metricToCompare.name]}
        </Text>
      </Flex>
      {pixelNames && pixelNames.length > 0 && (
        <Box position="absolute" top="3px" right="6px" zIndex={20}>
          <Tooltip aria-label={pixelNames} label={pixelNames} placement="right-start">
            <InfoIcon color={"brand.900"} w={4} h={4} display="flex" alignSelf="flex-start" />
          </Tooltip>
        </Box>
      )}
      {isKpi && (
        <Box position="absolute" top="3px" right="84px">
          <QRTZtmIcon color={colors.colors.white} />
        </Box>
      )}
    </Flex>
  )
}

export default MetricsPairButton
