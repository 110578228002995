const useLocalStorage = () => {
  const getItem = (key: string) => window.localStorage.getItem(key)
  const removeItem = (key: string) => window.localStorage.removeItem(key)
  const setItem = (key: string, value: string) => window.localStorage.setItem(key, value)

  return {
    getItem,
    removeItem,
    setItem,
  }
}

export default useLocalStorage
