import React, { FC, useContext, useEffect, useState } from "react"
import { Box, Center, HStack, Button, Text, VStack } from "@chakra-ui/react"
import { LeftArrowIcon, RightArrowIcon } from "../../../icons"
import { CampaignsPageContext } from "../../../pages/CampaignsPage/CampaignsPage"
import { RolesTabContext } from "../../../pages/UserManagementPage/tabs/RolesTab"
import { UsersTabContext } from "../../../pages/UserManagementPage/tabs/UsersTab"
import colors from "../../../theme/colors"
import ListItemLabel from "../../ListItemLabel"

type ListItem = {
  label: string
  value: string | number
  isSelected: boolean
  isDisabled: boolean
}

type ListsItems = { [key: string]: ListItem[] }

export type TransferListsData = {
  canMakeAdminChanges: boolean
  userEmail: string
  available: {
    items: { name: string; value: string | number; isAdmin?: boolean }[]
  }
  selected: {
    items: { name: string; value: string | number; isAdmin?: boolean }[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setItems: React.Dispatch<React.SetStateAction<any>>
  }
}

type Props = {
  caller: string
}

const TransferLists: FC<Props> = ({ caller }) => {
  const contextProviders: { [key: string]: TransferListsData | undefined } = {
    UsersTab: useContext(UsersTabContext),
    RolesTab: useContext(RolesTabContext),
    CampaignsPage: useContext(CampaignsPageContext),
  }

  const context = contextProviders[caller] ?? undefined

  if (!context) throw new Error(`No ${caller}Context.Provider found when calling UsersTabContext.`)

  const available: ListItem[] = context.available.items.map((item) => ({
    label: item.name,
    value: item.value,
    isSelected: false,
    isDisabled:
      (context.canMakeAdminChanges && caller === "UsersTab" && item.isAdmin) ||
      (context.canMakeAdminChanges && caller === "RolesTab" && item.name === context.userEmail),
  }))
  const preSelected: ListItem[] = context.selected.items.map((item) => ({
    label: item.name,
    value: item.value,
    isSelected: false,
    isDisabled:
      (context.canMakeAdminChanges && caller === "UsersTab" && item.isAdmin) ||
      (context.canMakeAdminChanges && caller === "RolesTab" && item.name === context.userEmail),
  }))

  const [listsItems, setListsItems] = useState<ListsItems>({
    available,
    selected: preSelected,
  })

  const toggleListItemSelected = (itemValue: string | number, list: string) => {
    const itemToUpdate = listsItems[list].findIndex((value) => value.value === itemValue)
    const updatedItem = {
      ...listsItems[list][itemToUpdate],
      isSelected: !listsItems[list][itemToUpdate].isSelected,
    }
    const itemsBeforeUpdatedItem = listsItems[list].slice(0, itemToUpdate)
    const itemsAfterUpdatedItem = listsItems[list].slice(itemToUpdate + 1)

    setListsItems({
      available:
        list === "available"
          ? [...itemsBeforeUpdatedItem, updatedItem, ...itemsAfterUpdatedItem]
          : listsItems.available.map((listItem) => ({
              ...listItem,
              isSelected: false,
            })),
      selected:
        list === "selected"
          ? [...itemsBeforeUpdatedItem, updatedItem, ...itemsAfterUpdatedItem]
          : listsItems.selected.map((listItem) => ({
              ...listItem,
              isSelected: false,
            })),
    })
  }

  const moveSelectedItems = (list: string) => {
    const theOtherList = list === "available" ? "selected" : "available"

    const selectedItems = listsItems[list]
      .filter((listItem) => listItem.isSelected)
      .map((listItem) => ({
        ...listItem,
        isSelected: false,
      }))
    const itemsLeftBehind = listsItems[list]
      .filter((listItem) => !listItem.isSelected)
      .map((listItem) => ({
        ...listItem,
        isSelected: false,
      }))

    setListsItems({
      available:
        list === "available"
          ? itemsLeftBehind
          : [
              ...selectedItems,
              ...listsItems[theOtherList].map((listItem) => ({
                ...listItem,
                isSelected: false,
              })),
            ],
      selected:
        list === "selected"
          ? itemsLeftBehind
          : [
              ...selectedItems,
              ...listsItems[theOtherList].map((listItem) => ({
                ...listItem,
                isSelected: false,
              })),
            ],
    })
  }

  const clearSelectedItems = () => {
    const disabledItems = preSelected.filter((item) => item.isDisabled)
    const preSelectedWithNoDisabledItems = preSelected.filter((item) => !item.isDisabled)
    setListsItems({
      available: [...available, ...preSelectedWithNoDisabledItems],
      selected: [...disabledItems],
    })
  }

  useEffect(() => {
    context.selected.setItems(listsItems.selected.map((item) => item.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsItems])

  return (
    <VStack w="100%">
      <HStack h={"40vh"} w="100%" alignItems="flex-start">
        <VStack width={"45%"} height="100%">
          <Center>
            <Text fontSize="lg" as="b">
              Available
            </Text>
          </Center>
          <Box h={"90%"} w="100%" border={`${colors.colors.brand["200"]} solid 2px`} p={2} overflow={"auto"}>
            {listsItems.available.map((listItem, index) => (
              <ListItemLabel
                key={`not-selected-${index}`}
                w="100%"
                size="medium"
                hasTooltip={false}
                isSelected={listItem.isSelected}
                label={listItem.label}
                onClick={() => toggleListItemSelected(listItem.value, "available")}
                withMiddleEllipsis={false}
              />
            ))}
          </Box>
        </VStack>
        <VStack w={"10%"} alignSelf="center">
          <Button
            aria-label="Move Selected Items From Not Selected List To Selected List"
            size="md"
            width={8}
            variant="unstyled"
            onClick={() => moveSelectedItems("available")}
          >
            <RightArrowIcon />
          </Button>
          <Button
            aria-label="Move Selected Items From Selected List To Not Selected List"
            size="md"
            width={8}
            variant="unstyled"
            onClick={() => moveSelectedItems("selected")}
          >
            <LeftArrowIcon />
          </Button>
        </VStack>
        <VStack width={"45%"} height="100%">
          <Center>
            <Text fontSize="lg" as="b">
              Selected
            </Text>
          </Center>
          <Box h={"90%"} w="100%" border={`${colors.colors.brand["200"]} solid 2px`} p={2} overflow={"auto"}>
            {listsItems.selected.map((listItem, index) => (
              <ListItemLabel
                key={`selected-${index}`}
                w="100%"
                size="medium"
                hasTooltip={false}
                isSelected={listItem.isSelected}
                isDisabled={listItem.isDisabled}
                label={listItem.label}
                onClick={() => toggleListItemSelected(listItem.value, "selected")}
                withMiddleEllipsis={false}
              />
            ))}
          </Box>
        </VStack>
      </HStack>
      <Button alignSelf="flex-end" variant="outline" onClick={clearSelectedItems}>
        Clear Selected
      </Button>
    </VStack>
  )
}

export default TransferLists
