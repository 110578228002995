import { gql } from "@apollo/client"

export const GET_PLATFORM_CAMPAIGNS = gql`
  query GetPlatformCampaigns($clientId: Int!, $platform: Platform!, $platformIntegrationId: Int!, $platformAdvertiserId: String!) {
    getPlatformCampaigns(clientId: $clientId, platform: $platform, platformIntegrationId: $platformIntegrationId, platformAdvertiserId: $platformAdvertiserId) {
      __typename
      id
      clientId
      platform
      platformAdvertiserId {
        __typename
        id
        platform
      }
      platformCampaignId {
        __typename
        id
        platform
      }
      name
      currency
      campaignType
      managed
      platformSetupStatus
      kpis {
        type
        name
        target
        min
        max
        weight
        pixelIds
        minimise
      }
      assignees {
        clientId
        userId
        name {
          first
          last
        }
        email
      }
    }
  }
`
