import { gql } from "@apollo/client"

export const CREATE_USER = gql`
  mutation CreateUser($clientId: Int!, $isSuperUser: Boolean, $isAdministrator: Boolean, $email: String!, $firstName: String!, $lastName: String!) {
    createUser(clientId: $clientId, isSuperUser: $isSuperUser, isAdministrator: $isAdministrator, email: $email, firstName: $firstName, lastName: $lastName) {
      clientId
      email
      name {
        first
        last
      }
      userId
    }
  }
`
