import { IconBase, IconBaseProps } from "./IconBase"

export const ToEndArrowIcon = ({ viewBox = "0 0 29 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <>
      <path d="M3.32951 24L0.499512 21.171L9.83851 11.996L0.499512 2.829L3.32951 1.43051e-06L15.4995 11.996L3.32951 24Z" fill="currentColor" />
      <path d="M16.3295 24L13.4995 21.171L22.8385 11.996L13.4995 2.829L16.3295 1.43051e-06L28.4995 11.996L16.3295 24Z" fill="currentColor" />
    </>
  </IconBase>
)
