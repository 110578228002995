import { FC, useEffect, useState } from "react"
import { IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { CloseIcon } from "../../icons"
import colors from "../../theme/colors"
import { BaseInputProps } from "../forms/BaseInput"

interface Props extends Omit<BaseInputProps, "children" | "name" | "onChange" | "field"> {
  placeholder?: string
  debounce?: number
  onChange: (value: string | number) => void
  value: string | number
}

const DebouncedTextInput: FC<Props> = ({ label, value: initialValue, onChange, debounce = 500, variant = "flushed", ...props }) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <InputGroup size="md" width={96}>
      <Input aria-label={`Text Input for ${label}`} {...props} value={value} onChange={(e) => setValue(e.target.value)} />
      {value && (
        <InputRightElement p={1}>
          <IconButton
            onClick={() => setValue("")}
            aria-label={`Clear Text Input for ${label}`}
            isRound={true}
            sx={{ fontSize: "1.1rem", _hover: { color: colors.colors.brand["900"], background: "white" } }}
            icon={<CloseIcon width={4} height={4} />}
            bg="transparent"
            minWidth={6}
            width={6}
            height={6}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default DebouncedTextInput
