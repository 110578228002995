import React, { FC } from "react"
import IntegrationSetupComplete from "../../../components/integrations/IntergrationSetupComplete"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import FacebookSetupData, { IntegrationVerifyBlockStepData } from "./FacebookSetupData"

type IntegrationSetupCompleteBlockProps = WizardStepProps<FacebookSetupData, object>

const IntegrationSetupCompleteBlock: FC<IntegrationSetupCompleteBlockProps> = ({ stepIndex, wizardData, onBack }) => {
  const prevStepData = wizardData[stepIndex - 1] as IntegrationVerifyBlockStepData
  const success = prevStepData?.success || false

  return <IntegrationSetupComplete onBack={onBack} success={success} />
}

export default IntegrationSetupCompleteBlock
