import { ResourceUris } from "@fifty9a/utils-api-auth"
import { UserProfile } from "../models/AuthState"
import useAuth from "./useAuth"

const useResourceUris = () => {
  const { user } = useAuth()
  const { clientId: userClientId } = (user ?? { clientId: 0 }) as UserProfile
  const clientId = Number(userClientId)

  const clientResourceUri = ResourceUris.client(clientId)
  const userResourceUri = ResourceUris.user(clientId, "*")
  const platformIntegrationResourceUri = ResourceUris.platformIntegration(clientId, "*")
  const platformAdvertiserResourceUri = ResourceUris.platformAdvertiser(clientId, "*", "*", "*")
  const platformCampaignResourceUri = ResourceUris.platformCampaign(clientId, "*", "*", "*", "*")

  return {
    clientResourceUri,
    userResourceUri,
    platformIntegrationResourceUri,
    platformAdvertiserResourceUri,
    platformCampaignResourceUri,
  }
}

export default useResourceUris
