import React, { FC } from "react"
import ButtonUI, { ButtonUIProps } from "./ButtonUI"

const Button: FC<ButtonUIProps> = ({ onClick, children, isFullWidth, isInline, isSecondary, size = "md", variant, buttonRef = null, ...rest }) => (
  <ButtonUI //
    {...rest}
    children={children}
    isFullWidth={isFullWidth}
    isInline={isInline}
    isSecondary={isSecondary}
    onClick={onClick}
    size={size}
    buttonRef={buttonRef}
    variant={variant}
  />
)

export default Button
