import getSymbolFromCurrency from "currency-symbol-map"
import {
  CampaignAggregatedMetricsItemised,
  CampaignKpi,
  CampaignType,
  CurrencyMetric,
  DailyMetric,
  FloatMetric,
  IntMetric,
  Metric,
  MetricName,
  Pixel,
} from "../../generated/graphql"
import {
  ClicksIcon,
  ConversionsEurIcon,
  ConversionsGbpIcon,
  ConversionsUsdIcon,
  CpaEurIcon,
  CpaGbpIcon,
  CpaUsdIcon,
  CpcEurIcon,
  CpcGbpIcon,
  CpcUsdIcon,
  CpmEurIcon,
  CpmGbpIcon,
  CpmUsdIcon,
  CpvEurIcon,
  CpvGbpIcon,
  CpvUsdIcon,
  CtrIcon,
  FrequencyIcon,
  ImpressionsIcon,
  VcpmIcon,
  VcrIcon,
  ViewabilityIcon,
} from "../../icons/metrics"
import { logError } from "../../log"
import colors from "../../theme/colors"
import { StringKeyObject } from "../../types"
import { enumEntryFromString } from "../../utils/enumUtils"
import { roundToUpToDecimals } from "../../utils/numberUtils"
import {
  CampaignTypeMetrics,
  DataPoint,
  ItemisedMetricDataSet,
  MetricDataSet,
  MetricEntries,
  MetricIdentifier,
  MetricsComparisonPair,
  MetricType,
  OrderOfMetrics,
} from "./types"

export const mapMetricNameToDisplayName: Record<MetricName, string> = {
  [MetricName.Clicks]: "Clicks",
  [MetricName.Conversions]: "Conversions",
  [MetricName.ClickReach]: "Click Reach",
  [MetricName.CompleteViewsVideo]: "Complete Views",
  [MetricName.Cpa]: "CPA",
  [MetricName.Cpc]: "CPC",
  [MetricName.Cpcv]: "CPCV",
  [MetricName.Cpm]: "CPM",
  [MetricName.Cpv]: "CPV",
  [MetricName.Ctr]: "CTR",
  [MetricName.Frequency]: "Frequency",
  [MetricName.CoViewedFrequency]: "Co-Viewed Frequency",
  [MetricName.ViewableFrequency]: "Viewable Frequency",
  [MetricName.Impressions]: "Impressions",
  [MetricName.CoViewedImpressions]: "Co-Viewed Impressions",
  [MetricName.MeasurableImpressions]: "Measurable Impressions",
  [MetricName.ViewableImpressions]: "Viewable Impressions",
  [MetricName.ImpressionsReach]: "Impressions Reach",
  [MetricName.CoViewedImpressionsReach]: "Co-Viewed Impressions Reach",
  [MetricName.ViewableImpressionsReach]: "Viewable Impressions Reach",
  [MetricName.TotalReach]: "Total Reach",
  [MetricName.Roas]: "ROAS",
  [MetricName.Spend]: "Spend",
  [MetricName.Vcpm]: "vCPM",
  [MetricName.Viewability]: "Viewability",
  [MetricName.Vcr]: "VCR",
}

export const isCurrencyMetric = (metric: Metric): metric is CurrencyMetric => metric.__typename === "CurrencyMetric"
const isFloatMetric = (metric: Metric): metric is FloatMetric => "valueFloat" in metric

export const extractValueFromMetric = (metric?: Metric): number => {
  if (!metric) {
    return 0
  }

  if (isCurrencyMetric(metric)) {
    return roundToUpToDecimals({
      value: (metric as CurrencyMetric).valueFloat ?? 0,
    })
  } else if (isFloatMetric(metric)) {
    return roundToUpToDecimals({
      value: (metric as FloatMetric).valueFloat ?? 0,
    })
  } else {
    return (metric as IntMetric).valueInt ?? 0
  }
}

const intlNumberFormat = new Intl.NumberFormat("en-GB", {
  maximumFractionDigits: 4,
})

type FormatMetricOpts = {
  name: MetricName
  value: number
  currencySymbol?: string
}

export const formatMetric = (opts: FormatMetricOpts) => {
  const { name, value, currencySymbol } = opts
  const metricEntry = MetricEntries.find((entry) => entry.name === name)
  if (!metricEntry) {
    logError("No MetricEntry found for ", name)
    return ""
  }

  const metricValueMappings: Record<MetricType, () => string> = {
    [MetricType.Value]: () => intlNumberFormat.format(value),
    [MetricType.Monetary]: () => `${currencySymbol}${intlNumberFormat.format(value)}`,
    [MetricType.Percentage]: () => `${intlNumberFormat.format(value * 100)}%`,
  }

  return metricValueMappings[metricEntry.type]()
}

export const extractPixelNamesFromPixels = (pixels?: Pixel[]): string => {
  if (pixels) {
    return (
      pixels
        ?.map((pixel) => {
          const { name, id } = pixel
          if (name === id) return `${name} - No Data`

          return name
        })
        .join(", ") ?? ""
    )
  }
  return ""
}

type GetMetricIconOpts = {
  name: MetricName
  currency: string
  color?: string
  height?: number
  width?: number
}

export const getMetricIcon = ({ name, currency, color = colors.colors.brand["900"], height, width }: GetMetricIconOpts): JSX.Element | undefined => {
  const notImplementedIcon = <div>Icon Not Implemented Yet!</div>
  switch (name) {
    case MetricName.Clicks:
      return <ClicksIcon color={color} height={height} width={width} />
    case MetricName.Conversions:
      if (currency === "€") return <ConversionsEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <ConversionsGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <ConversionsUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpa:
      if (currency === "€") return <CpaEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpaGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpaUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpc:
      if (currency === "€") return <CpcEurIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpcGbpIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpcUsdIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpm:
      if (currency === "€") return <CpmEurIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpmUsdIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpmGbpIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Cpv:
      if (currency === "€") return <CpvEurIcon color={color} height={height} width={width} />
      if (currency === "$") return <CpvUsdIcon color={color} height={height} width={width} />
      if (currency === "£") return <CpvGbpIcon color={color} height={height} width={width} />
      return notImplementedIcon
    case MetricName.Ctr:
      return <CtrIcon color={color} height={height} width={width} />
    case MetricName.Frequency:
      return <FrequencyIcon color={color} height={height} width={width} />
    case MetricName.Impressions:
      return <ImpressionsIcon color={color} height={height} width={width} />
    case MetricName.Vcpm:
      return <VcpmIcon color={color} height={height} width={width} />
    case MetricName.Vcr:
      return <VcrIcon color={color} height={height} width={width} />
    case MetricName.Viewability:
      return <ViewabilityIcon color={color} height={height} width={width} />
    default:
      break
  }
}

const mapMetricNameToComparator: Record<MetricName, MetricName> = {
  [MetricName.Clicks]: MetricName.Ctr,
  [MetricName.Conversions]: MetricName.Cpa,
  [MetricName.ClickReach]: MetricName.ClickReach,
  [MetricName.CompleteViewsVideo]: MetricName.Cpcv,
  [MetricName.Cpa]: MetricName.Conversions,
  [MetricName.Cpc]: MetricName.Clicks,
  [MetricName.Cpcv]: MetricName.CompleteViewsVideo,
  [MetricName.Cpm]: MetricName.Impressions,
  [MetricName.Cpv]: MetricName.Viewability,
  [MetricName.Ctr]: MetricName.Clicks,
  [MetricName.Frequency]: MetricName.ImpressionsReach,
  [MetricName.CoViewedFrequency]: MetricName.CoViewedImpressionsReach,
  [MetricName.ViewableFrequency]: MetricName.ViewableImpressionsReach,
  [MetricName.Impressions]: MetricName.Cpm,
  [MetricName.CoViewedImpressions]: MetricName.CoViewedImpressions,
  [MetricName.MeasurableImpressions]: MetricName.MeasurableImpressions,
  [MetricName.ViewableImpressions]: MetricName.ViewableImpressions,
  [MetricName.ImpressionsReach]: MetricName.Frequency,
  [MetricName.CoViewedImpressionsReach]: MetricName.CoViewedFrequency,
  [MetricName.ViewableImpressionsReach]: MetricName.ViewableFrequency,
  [MetricName.TotalReach]: MetricName.TotalReach,
  [MetricName.Roas]: MetricName.Conversions,
  [MetricName.Spend]: MetricName.Spend,
  [MetricName.Vcpm]: MetricName.Viewability,
  [MetricName.Viewability]: MetricName.Impressions,
  [MetricName.Vcr]: MetricName.CompleteViewsVideo,
}

const addConversionsMetricIdentifiers = (metricIdentifiers: MetricIdentifier[]): MetricIdentifier[] => {
  const cpaMetricsIndexes: number[] = []
  metricIdentifiers.forEach((metricIdentifier, index) => {
    if (metricIdentifier.name === MetricName.Cpa) {
      cpaMetricsIndexes.push(index)
    }
  })

  cpaMetricsIndexes.forEach((cpaMetricIndex, index) => {
    metricIdentifiers.splice(cpaMetricIndex + 1 + index, 0, {
      name: MetricName.Conversions,
      pixelIds: metricIdentifiers[cpaMetricIndex + index].pixelIds,
      minimise: false,
    })
  })

  return metricIdentifiers
}

type GetMetricsToDisplayOpts = {
  campaignKpis: CampaignKpi[]
  campaignType: CampaignType
  isAddingConversionMetrics?: boolean
}

const getMetricsToDisplay = (opts: GetMetricsToDisplayOpts): MetricIdentifier[] => {
  const { campaignKpis, campaignType, isAddingConversionMetrics } = opts
  const campaignKpisMetricIdentifiers = campaignKpis.map((campaignKpi) => {
    const metricName = enumEntryFromString(MetricName, campaignKpi.name.toUpperCase()) as MetricName

    const pixelIds =
      (metricName === MetricName.Cpa || metricName === MetricName.Conversions) && campaignKpi.pixelIds?.length && campaignKpi.pixelIds?.length > 0
        ? campaignKpi.pixelIds
        : undefined

    return {
      name: metricName,
      pixelIds,
      target: campaignKpi.target,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      minimise: MetricEntries.find((entry) => entry.name === metricName)!.minimise,
      isKpi: true,
    } as MetricIdentifier
  })

  return (isAddingConversionMetrics ? addConversionsMetricIdentifiers(campaignKpisMetricIdentifiers) : campaignKpisMetricIdentifiers).concat(
    CampaignTypeMetrics[campaignType]
      .map((metric) => ({
        name: metric,
        pixelIds: undefined,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        minimise: MetricEntries.find((entry) => entry.name === metric)!.minimise,
        isKpi: false,
      }))
      .filter(
        (campaignTypeMetricIdentifier) =>
          !campaignKpisMetricIdentifiers.some((campaignKpisMetricIdentifier) => campaignKpisMetricIdentifier.name === campaignTypeMetricIdentifier.name)
      )
      .sort((a, b) => OrderOfMetrics.indexOf(a.name) - OrderOfMetrics.indexOf(b.name))
  )
}

export const getSelectableMetrics = (campaignKpis: CampaignKpi[], campaignType: CampaignType, currencySymbol: string): MetricsComparisonPair[] => {
  const selectableMetrics: MetricsComparisonPair[] = []

  // Remove Spend as this is always the bars for the SelectableMetrics graph.
  const metricsToDisplay = getMetricsToDisplay({
    campaignKpis,
    campaignType,
  }).filter((metricToDisplay) => metricToDisplay.name !== MetricName.Spend)

  const metricComparisonPairs = metricsToDisplay.map((metricIdentifier) => {
    const { name, pixelIds } = metricIdentifier
    const metricToCompareName = mapMetricNameToComparator[name]
    return [
      metricIdentifier,
      {
        name: metricToCompareName,
        pixelIds,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        minimise: MetricEntries.find((entry) => entry.name === metricToCompareName)!.minimise,
      },
    ]
  })

  metricComparisonPairs.forEach((metricComparisonPair) => {
    // Check if already in selectableMetrics but not if a CPA metric then skip if so.
    const duplicateMetric = selectableMetrics.some(
      (selectableMetric) =>
        (selectableMetric.originalMetric.name === metricComparisonPair[0].name || selectableMetric.originalMetric.name === metricComparisonPair[1].name) &&
        (selectableMetric.metricToCompare.name === metricComparisonPair[0].name || selectableMetric.metricToCompare.name === metricComparisonPair[1].name) &&
        selectableMetric.originalMetric.name !== MetricName.Cpa
    )

    if (duplicateMetric) return

    selectableMetrics.push({
      originalMetric: metricComparisonPair[0],
      metricToCompare: metricComparisonPair[1],
      currencySymbol,
      isKpi: campaignKpis.map((campaignKPI) => campaignKPI.name).indexOf(metricComparisonPair[0].name) >= 0,
    })
  })

  selectableMetrics[0].isSelected = true

  // We only have 5 buttons for the SelectableMetricsGraph so grab the first 5 metrics.
  return selectableMetrics.slice(0, 5)
}

type ConvertToMetricDataSetOpts = {
  dailyMetrics?: DailyMetric[]
  metrics?: Metric[]
  metricIdentifier: MetricIdentifier
}

export const convertToMetricDataSet = (opts: ConvertToMetricDataSetOpts): MetricDataSet => {
  const { dailyMetrics, metrics, metricIdentifier } = opts

  if (!metrics && !dailyMetrics) {
    return {
      name: metricIdentifier.name,
      pixels: metricIdentifier.pixelIds?.map((pixelId) => ({ id: pixelId, name: pixelId })) ?? undefined,
      isKpi: metricIdentifier.isKpi,
      target: metricIdentifier.target,
      minimise: metricIdentifier.minimise,
      data: [],
    }
  }

  // Get the pixels from the data where possible
  const pixels =
    metricIdentifier.name === MetricName.Cpa || metricIdentifier.name === MetricName.Conversions
      ? (metricIdentifier.pixelIds ?? []).map((pixelId) => {
          const metricsDataToSearch = dailyMetrics && dailyMetrics?.length > 0 ? dailyMetrics[0].metrics : metrics

          const pixel = (metricsDataToSearch ?? [])
            .find((metric) => metric.pixels?.some((pixel) => pixel.id === pixelId))
            ?.pixels?.find((pixel) => pixel.id === pixelId)

          return {
            id: pixel?.id ?? pixelId,
            name: pixel?.name ?? pixelId,
          }
        })
      : undefined

  // Gather Data for this metric
  const dailyData = (dailyMetrics ?? [])
    .map((dailyMetric) => {
      const metric = dailyMetric.metrics.find(
        (metric) =>
          ((metricIdentifier.name === MetricName.Cpa || metricIdentifier.name === MetricName.Conversions) &&
            metric.name === metricIdentifier.name &&
            metricIdentifier.pixelIds?.some((pixelId) => metric.pixels?.some((pixel) => pixel.id === pixelId))) ||
          (metricIdentifier.name !== MetricName.Cpa && metricIdentifier.name !== MetricName.Conversions && metricIdentifier.name === metric.name)
      )
      if (!metric) return undefined

      const metricValue = extractValueFromMetric(metric)

      return {
        date: dailyMetric.date,
        formattedValue: formatMetric({
          name: metricIdentifier.name,
          value: metricValue,
          currencySymbol: isCurrencyMetric(metric) ? getSymbolFromCurrency(metric.currency) : undefined,
        }),
        value: metricValue,
      }
    })
    .filter((dataPoint) => dataPoint !== undefined) as DataPoint[]

  const metricForSingleDataPoint = metrics?.find(
    (metric) =>
      ((metricIdentifier.name === MetricName.Cpa || metricIdentifier.name === MetricName.Conversions) &&
        metric.name === metricIdentifier.name &&
        metricIdentifier.pixelIds?.some((pixelId) => metric.pixels?.some((pixel) => pixel.id === pixelId))) ||
      (metricIdentifier.name !== MetricName.Cpa && metricIdentifier.name !== MetricName.Conversions && metricIdentifier.name === metric.name)
  )

  const metricValueForSingleDataPoint = extractValueFromMetric(metricForSingleDataPoint)

  const singleDataPoint = metricForSingleDataPoint
    ? [
        {
          formattedValue: formatMetric({
            name: metricIdentifier.name,
            value: metricValueForSingleDataPoint,
            currencySymbol: isCurrencyMetric(metricForSingleDataPoint) ? getSymbolFromCurrency(metricForSingleDataPoint.currency) : undefined,
          }),
          value: metricValueForSingleDataPoint,
        },
      ]
    : []

  return {
    name: metricIdentifier.name,
    pixels,
    target: metricIdentifier.target,
    minimise: metricIdentifier.minimise,
    isKpi: metricIdentifier.isKpi,
    data: dailyData.length > 0 ? dailyData : singleDataPoint,
  }
}

export const unPackDailyMetricsForTrendGraphs = (
  dailyMetrics: DailyMetric[], //
  campaignType: CampaignType,
  campaignKpis: CampaignKpi[]
): MetricDataSet[] => {
  const metrics: MetricDataSet[] = []

  // Only get the first 5 metrics to display as there are only 5 Trend Graphs
  const metricsToDisplay = getMetricsToDisplay({ campaignKpis, campaignType }).slice(0, 5)

  metricsToDisplay.forEach((metricToDisplay) => {
    metrics.push(convertToMetricDataSet({ dailyMetrics, metricIdentifier: metricToDisplay }))
  })

  return metrics
}

export const unPackMetricsForMetricsStrip = (metrics: Metric[], campaignKpis: CampaignKpi[], campaignType: CampaignType): MetricDataSet[] => {
  const metricsToDisplay = getMetricsToDisplay({ campaignKpis, campaignType, isAddingConversionMetrics: true })

  return metricsToDisplay.map((metricToDisplay) =>
    convertToMetricDataSet({
      metrics,
      metricIdentifier: metricToDisplay,
    })
  )
}

export type GraphDataType = "BARS" | "LINE_ONE" | "LINE_TWO"

export const unPackDailyMetricsForGraphs = (
  dailyMetrics: DailyMetric[], //
  metricsToDisplay: MetricIdentifier[]
): Record<GraphDataType, MetricDataSet> => {
  const metricsObjects: StringKeyObject<MetricDataSet> = {}

  metricsToDisplay.forEach((metricToDisplay, index) => {
    let metricNameKey: GraphDataType = "BARS"
    switch (index) {
      case 1:
        metricNameKey = "LINE_ONE"
        break
      case 2:
        metricNameKey = "LINE_TWO"
        break
    }

    metricsObjects[metricNameKey] = convertToMetricDataSet({ dailyMetrics, metricIdentifier: metricToDisplay })
  })

  return metricsObjects as Record<GraphDataType, MetricDataSet>
}

type UnPackItemisedMetricsForTablesOpts = {
  itemisedMetrics: CampaignAggregatedMetricsItemised[]
  campaignKpis: CampaignKpi[]
  campaignType: CampaignType
}

export const unPackItemisedMetricsForTables = (opts: UnPackItemisedMetricsForTablesOpts): ItemisedMetricDataSet[] => {
  const { campaignKpis, campaignType, itemisedMetrics } = opts
  const metricsToDisplay = getMetricsToDisplay({ campaignKpis, campaignType, isAddingConversionMetrics: true })

  return itemisedMetrics.map((itemisedMetric) => ({
    itemId: itemisedMetric.itemId ?? "",
    itemName: itemisedMetric.itemName ?? "",
    metricDataSets: metricsToDisplay.map((metricToDisplay) =>
      convertToMetricDataSet({
        metrics: itemisedMetric.metrics,
        metricIdentifier: metricToDisplay,
      })
    ),
  }))
}
