import { gql } from "@apollo/client"

export const GET_USER_PROFILES = gql`
  query GetUserProfiles {
    getUserProfiles {
      clientId
      email
      name {
        first
        last
      }
      userId
      roles {
        roleId
        name
        isAdmin
      }
    }
  }
`
