import { graphql } from "msw"

export const getUserProfiles = graphql.query("GetUserProfiles", (req, res, ctx) => {
  return res(
    ctx.data({
      getUserProfiles: [
        {
          clientId: 1,
          email: "barns.anderson@59a.co.uk",
          name: {
            first: "Barns",
            last: "Anderson",
            __typename: "UserNames",
          },
          userId: "2",
          roles: [
            {
              roleId: 1,
              name: "Administrator",
              __typename: "UserProfileRole",
            },
          ],
          __typename: "UserProfileWithRoles",
        },
        {
          clientId: 1,
          email: "barns.anderson+saas@59a.co.uk",
          name: {
            first: "Barns",
            last: "SaaS",
            __typename: "UserNames",
          },
          userId: "13",
          roles: [],
          __typename: "UserProfileWithRoles",
        },
        {
          clientId: 1,
          email: "mircea.ungureanu@59a.co.uk",
          name: {
            first: "Mircea",
            last: "Ungureanu",
            __typename: "UserNames",
          },
          userId: "44",
          roles: [
            {
              roleId: 1,
              name: "Administrator",
              __typename: "UserProfileRole",
            },
          ],
          __typename: "UserProfileWithRoles",
        },
        {
          clientId: 1,
          email: "mircea.ungureanu+saas@59a.co.uk",
          name: {
            first: "Mircea",
            last: "Sass",
            __typename: "UserNames",
          },
          userId: "46",
          roles: [],
          __typename: "UserProfileWithRoles",
        },
      ],
    })
  )
})
