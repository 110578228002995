import { IconBase, IconBaseProps } from "./IconBase"

export const CloseIcon = ({ viewBox = "0 0 24 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.82999 24L0 21.171L9.1651 12.1667L9 12.004L9.02481 11.9796L0 2.82898L2.82999 0L12.0031 9.04187L21.171 0L24 2.82996L14.9823 11.9785L15 11.996L14.8268 12.1667L24 21.171L21.17 24L12.0215 14.9823L12.004 15L11.9796 14.9752L2.82999 24Z"
      fill="currentColor"
    />
  </IconBase>
)
