import { IconBase, IconBaseProps } from "./IconBase"

export const QRTZtmIcon = ({ viewBox = "0 0 44 44", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <g>
      <path
        d="M29.9783 42.9L17.0184 29.9092L30.0092 16.9493L42.9691 29.9401L29.9783 42.9ZM17.559 29.9092L29.9783 42.3594L42.4285 29.9401L30.0092 17.4899L17.559 29.9092Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M42.4903 31.0986L24.1394 31.0677L24.1703 12.7323L42.5212 12.7632L42.4903 31.0986ZM24.5256 30.697L42.1041 30.7279L42.135 13.1494L24.541 13.103L24.5256 30.697Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M30.0092 26.8662L17.0493 13.8754L30.0401 0.915451L43 13.9063L30.0092 26.8662ZM17.5899 13.8754L30.0092 26.3255L42.4594 13.9063L30.0401 1.45609L17.5899 13.8754Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M31.1677 19.7606L12.8168 19.7297L12.8477 1.37885L31.1986 1.40974L31.1677 19.7606ZM13.203 19.3435L30.7815 19.3744L30.8124 1.78046L13.2339 1.76502L13.203 19.3435Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M13.9754 26.8507L1.01544 13.8599L14.0217 0.899994L26.9816 13.8908L13.9754 26.8507ZM1.55608 13.8599L13.9754 26.3101L26.4255 13.8908L14.0217 1.44063L1.55608 13.8599Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M19.8297 31.0677L1.47885 31.0368L1.50975 12.7014L19.8606 12.7323L19.8297 31.0677ZM1.86502 30.6661L19.4435 30.697L19.4744 13.103L1.88047 13.0721L1.86502 30.6661Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M13.9599 42.8691L1 29.8783L13.9908 16.9184L26.9507 29.9092L13.9599 42.8691ZM1.54064 29.8783L13.9599 42.3285L26.4101 29.9092L13.9908 17.459L1.54064 29.8783Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1368 42.4212L12.7859 42.3903L12.8168 24.0394L31.1677 24.0703L31.1368 42.4212ZM13.203 24.4256L13.1721 42.0041L30.7506 42.035L30.7815 24.4565L13.203 24.4256Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </g>
  </IconBase>
)
