import { useParams } from "react-router-dom"
import { stringOrThrow } from "../utils/typeUtils"

interface IntegrationsParams {
  clientId: string
}

interface AdvertisersParams {
  clientId: string
  integrationId: string
}

interface CampaignsParams extends AdvertisersParams {
  platform: string
  advertiserId: string
}

interface CampaignParams extends CampaignsParams {
  campaignId: string
}

export const useIntegrationParams = (): IntegrationsParams => {
  const { clientId } = useParams()

  return {
    clientId: stringOrThrow(clientId),
  }
}

export const useAdvertiserParams = (): AdvertisersParams => {
  const { integrationId } = useParams()
  return {
    ...useIntegrationParams(),
    integrationId: stringOrThrow(integrationId),
  }
}

export const useCampaignsParams = (): CampaignsParams => {
  const { platform, advertiserId } = useParams()
  return {
    ...useAdvertiserParams(),
    platform: stringOrThrow(platform),
    advertiserId: stringOrThrow(advertiserId),
  }
}

export const useCampaignParams = (): CampaignParams => {
  const { campaignId } = useParams()
  return {
    ...useCampaignsParams(),
    campaignId: stringOrThrow(campaignId),
  }
}
