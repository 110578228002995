import { assignUsersToPlatformCampaign } from "./assignUsersToPlatformCampaign.mock"
import { getClientsMock } from "./getClients.mock"
import { getCurrentUserProfileMock } from "./getCurrentUserProfile.mock"
import { getPermissionsMock } from "./getPermissions.mock"
import { getPlatformAdvertisersByIntegration } from "./getPlatformAdvertisersByIntegration.mock"
import { getPlatformCampaigns } from "./getPlatformCampaigns.mock"
import { getPlatformIntegrationMock } from "./getPlatformIntegration.mock"
import { getPlatformIntegrationsMock } from "./getPlatformIntegrations.mock"
import { getUserProfiles } from "./getUserProfiles.mock"
import { triggerSchedules } from "./triggerSchedules.mock"
import { updatePlatformAdvertiser } from "./updatePlatformAdvertiser.mock"
import { updateScheduleState } from "./updateScheduleState.mock"

export const handlers = [
  assignUsersToPlatformCampaign, //
  getClientsMock,
  getCurrentUserProfileMock,
  getPermissionsMock,
  getPlatformAdvertisersByIntegration,
  getPlatformCampaigns,
  getPlatformIntegrationMock,
  getPlatformIntegrationsMock,
  getUserProfiles,
  triggerSchedules,
  updatePlatformAdvertiser,
  updateScheduleState,
]
