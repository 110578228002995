export const head = <T>(v: T[]) => v[0]

export const tail = <T>(v: T[]) => (v.length > 0 ? v.slice(1) : [])

export const foldLeft = <A, B>(initial: A, vals: B[], f: (acc: A, val: B) => A) => {
  const foldInternal = (a: A, b: B, bs: B[]): A => {
    return b //
      ? foldInternal(f(a, b), head(bs), tail(bs))
      : a
  }
  return foldInternal(initial, head(vals), tail(vals))
}
