import React, { useCallback, useState } from "react"
import { PaginationState, RowSelectionState, Updater } from "@tanstack/react-table"
import { SelectedSegmentsTableChangeProps } from "../../../pages/CampaignDashboardPage/types"
import colors from "../../../theme/colors"
import { SegmentType } from "../../dataVisualisation/types"
import BaseTable, { BaseTableProps } from "../BaseTable/BaseTable"

interface Props<D extends object>
  extends Omit<BaseTableProps<D>, "dataType" | "selectedRows" | "setSelectedRows" | "selectedRowsHandler" | "pagination" | "onPaginationChange"> {
  dataType: SegmentType
  heightOtherElements?: number
  rowSelection: RowSelectionState
  onSelectedRowsChange: ({ segmentType, segments }: SelectedSegmentsTableChangeProps) => void
}

const TileTable = <D extends object>({
  error,
  loading,
  defaultSort,
  columnVisibility = {
    id: false,
    itemId: false,
  },
  pageSizeDisabled = true,
  renderSubComponent,
  dataType,
  tableColumns,
  tableRows,
  rowSelection,
  onSelectedRowsChange,
  containerHeight = "23rem",
  containerWidth,
  headerBackground = colors.colors.brand["700"],
  headerBorderBottom,
  headerBorderColor = colors.colors.brand["200"],
  headerFontSize = "0.9rem",
  paginationPaddingTop,
  paginationMarginRight = "0px",
  rowFontSize = "0.9rem",
  scrollBarVerticalAdjust = 48,
}: Props<D>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 7,
  })

  const setSelectedRows = useCallback(
    (updaterOrValue: Updater<RowSelectionState>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (updaterOrValue as any)(rowSelection)
      onSelectedRowsChange({
        segmentType: dataType,
        segments: value,
      })
    },
    [dataType, rowSelection, onSelectedRowsChange]
  )

  if (tableColumns.length > 0 && tableRows.length > 0) {
    return (
      <BaseTable<(typeof tableRows)[0]>
        error={error}
        loading={loading}
        defaultSort={defaultSort}
        columnVisibility={columnVisibility}
        tableColumns={tableColumns}
        tableRows={tableRows}
        rowSelection={rowSelection}
        setSelectedRows={setSelectedRows}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageSizeDisabled={pageSizeDisabled}
        renderSubComponent={renderSubComponent}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        headerBackground={headerBackground}
        headerBorderBottom={headerBorderBottom}
        headerBorderColor={headerBorderColor}
        headerFontSize={headerFontSize}
        paginationPaddingTop={paginationPaddingTop}
        paginationMarginRight={paginationMarginRight}
        rowFontSize={rowFontSize}
        scrollBarVerticalAdjust={scrollBarVerticalAdjust}
      />
    )
  } else return null
}

export default TileTable
