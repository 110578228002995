import React, { FC, useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { Flex } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import { useNavigate, useParams } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import Button from "../../components/Button"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import IntegrationsTable from "../../components/tables/IntegrationsTable"
import { PlatformIntegration } from "../../generated/graphql"
import { GET_PLATFORM_INTEGRATIONS } from "../../graphql"
import useAuth from "../../hooks/useAuth"
import usePermissions, { canCreate, canView } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import { logError, logUnhandledError } from "../../log"
import AuthenticatedProps from "../AuthenticatedProps"

type IntegrationsPageProps = AuthenticatedProps

const IntegrationsPage: FC<IntegrationsPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformIntegrationResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()

  const navigate = useNavigate()
  const { clientId: paramsClientId } = useParams()

  const clientId = paramsClientId ?? user?.clientId

  const breadcrumbItems: BreadcrumbItemProps[] = [{ text: "Integrations" }]
  if (user?.is59A) {
    breadcrumbItems.splice(0, 0, {
      text: "Clients",
      onClick: () => navigate(RoutePaths.clientsList.resolve()),
    })
    breadcrumbItems.splice(1, 0, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      text: paramsClientId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onClick: () => navigate(RoutePaths.integrationsList.resolve(paramsClientId!)),
    })
  }

  const [integrationsData, setIntegrationsData] = useState<Array<PlatformIntegration>>()
  const { loading, error, data } = useQuery(GET_PLATFORM_INTEGRATIONS, {
    variables: { clientId: Number(clientId) },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (error) {
      // TODO: error state
      logError("ERROR", error)
    }
    if (data) {
      setIntegrationsData(data.getPlatformIntegrations as Array<PlatformIntegration>)
    }
  }, [error, data])

  const pageTitle = "Platform Integrations"

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && canView(permissions, platformIntegrationResourceUri) && (
        <>
          {canCreate(permissions, platformIntegrationResourceUri) && (
            <Flex justify="start" py={3} mb="-3.25rem">
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <Button onClick={() => navigate(RoutePaths.integrationSetupIndex.resolve(clientId!))}>Create Integration</Button>
            </Flex>
          )}
          <ErrorBoundary
            fallback={
              <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
            }
            onError={logUnhandledError}
          >
            <IntegrationsTable data={integrationsData} loading={loading} />
          </ErrorBoundary>
        </>
      )}
      {!permissionsLoading && !canView(permissions, platformIntegrationResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default IntegrationsPage
