/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { detailedDiff } from "deep-object-diff"
import { Optional, StringKeyObject } from "../types"

// Copied from the venus repository

type ObjectDiffOpts = {
  pathPrefix?: string
}

type HasDifferencesOpts = {
  include?: Optional<string[]>
  exclude?: Optional<string[]>
}

type EvaluateOpts = {
  flattenArrayDifferences?: boolean
}

const DefaultObjectDiffOpts: ObjectDiffOpts = {}

const DefaultEvaluateOpts: EvaluateOpts = {
  flattenArrayDifferences: false,
}

type EvaluationResult = {
  added: StringKeyObject<any>
  removed: StringKeyObject<any>
  updated: StringKeyObject<{ old: any; new: any }>
}

class ObjectDiff {
  private _rawOutput: Optional<StringKeyObject<any>>
  private _evaluated: Optional<EvaluationResult>

  constructor(public original: object, public updated: object, private opts: ObjectDiffOpts = DefaultObjectDiffOpts) {}

  raw() {
    if (this._rawOutput === undefined) {
      this._rawOutput = detailedDiff(this.original, this.updated)
    }
    return this._rawOutput
  }

  evaluate({ flattenArrayDifferences = false }: EvaluateOpts = DefaultEvaluateOpts) {
    const _eval = (comparisonObj: StringKeyObject<any>, value: any): StringKeyObject<any> => {
      const _combinePath = (p1: Optional<string>, p2: string) => (p1 ? `${p1}.${p2}` : p2)
      const _currentValue = (path: string, value: any) => ({ [path]: value })
      const _recurse = (comparisonObj: StringKeyObject<any>, value: any, path?: string): StringKeyObject<any> => {
        if (value == null) {
          return _currentValue(path!, null)
        } else if (Array.isArray(value)) {
          return _currentValue(path!, value)
        } else if (typeof value === "object") {
          return Object.entries(value)
            .map(([key, value]) => {
              if (comparisonObj[key]) {
                return _recurse(comparisonObj[key], value, _combinePath(path, key))
              } else {
                return _currentValue(_combinePath(path, key), value)
              }
            }) //
            .reduce((acc, v) => ({ ...acc, ...v }), {})
        } else {
          return _currentValue(path!, value)
        }
      }
      return _recurse(comparisonObj, value)
    }

    const _getValue = (obj: StringKeyObject<any>, path: string): any => {
      const _recurse = (obj: StringKeyObject<any>, path: string[]): any => {
        const key = path.shift()!
        return path.length === 0 ? obj[key] : _recurse(obj[key] as StringKeyObject<any>, path)
      }
      return _recurse(obj, path.split("."))
    }

    const buildKey = (key: string) => (this.opts.pathPrefix !== undefined ? `${this.opts.pathPrefix}.${key}` : key)

    if (!this._evaluated) {
      const object = this.raw()
      const original = this.original

      this._evaluated = {
        added: Object.fromEntries(Object.entries(_eval(original, object.added)).map(([key, value]: any) => [buildKey(key), value] as [string, any])),
        removed: Object.fromEntries(
          Object.entries(_eval(original, object.deleted)).map(([key, _]: any) => [buildKey(key), _getValue(original, key)] as [string, any])
        ),
        updated: Object.fromEntries(
          Object.entries(_eval(original, object.updated)).map(
            ([key, value]: any) => [buildKey(key), { old: _getValue(original, key), new: value }] as [string, any]
          )
        ),
      }
    }
    return flattenArrayDifferences ? this._flattenArrayDifferences(this._evaluated) : this._evaluated
  }

  hasDifferences = ({ include, exclude }: HasDifferencesOpts = {}) => {
    const _pathFilter = (changedPaths: string[], filterPaths: Optional<string[]>, filterFunc: (path: string, filterPaths: Set<string>) => boolean) => {
      if (filterPaths) {
        const pathSet = new Set(filterPaths)
        return changedPaths.filter((p) => filterFunc(p, pathSet))
      }
      return changedPaths
    }
    const _applyInclude = (changedPaths: string[]) => _pathFilter(changedPaths, include, (p, fSet) => fSet.has(p)) //
    const _applyExclude = (changedPaths: string[]) => _pathFilter(changedPaths, exclude, (p, fSet) => !fSet.has(p)) //

    const paths = Object.keys(this.evaluate())
    if (paths.length === 0) {
      return false
    }

    const evaluatedResult = this.evaluate({ flattenArrayDifferences: true })
    const changedPaths = _applyInclude(
      _applyExclude([
        ...Object.keys(evaluatedResult.added), //
        ...Object.keys(evaluatedResult.removed),
        ...Object.keys(evaluatedResult.updated),
      ])
    )

    return changedPaths.length > 0
  }

  private _flattenArrayDifferences(evaluated: EvaluationResult): EvaluationResult {
    const normaliseKey = (key: string): string => {
      if (key.match(/^.*\.\d+$/)) {
        const parts = key.split(".")
        parts.pop()
        return parts.join(".")
      }
      return key
    }

    const mergeValues = (oldValues: any, newValue: any) => {
      return Array.isArray(oldValues) ? [...oldValues, newValue] : [oldValues, newValue]
    }

    const updatedFlattened: StringKeyObject<any> = {}
    for (const [key, value] of Object.entries(evaluated.updated)) {
      const normalisedKey = normaliseKey(key)
      const currentFlattenedVal = updatedFlattened[normalisedKey]
      updatedFlattened[normalisedKey] = currentFlattenedVal ? mergeValues(currentFlattenedVal, value) : value
    }
    return { added: evaluated.added, removed: evaluated.removed, updated: updatedFlattened }
  }
}

export default ObjectDiff
