export interface UserProfile {
  sub: string
  clientId: string
  uid: string
  email: string
  name: {
    first: string
    last: string
  }
  is59A: boolean
  roles: {
    roleId: number
    name: string
    isAdmin: boolean
  }[]
}

export enum AuthStage {
  Unauthenticated = "Unauthenticated",
  LoadingProfile = "LoadingProfile",
  Authenticated = "Authenticated",
}

interface AuthState {
  user?: UserProfile
  authStage: AuthStage
}

export default AuthState
