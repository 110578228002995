import { FC } from "react"
import { Flex, Icon, Text } from "@chakra-ui/react"
import { GoTriangleDown, GoTriangleUp } from "react-icons/go"
import colors from "../../theme/colors"
import { roundToUpToDecimals } from "../../utils/numberUtils"

type PercentageChangeProps = {
  fromValue: number
  toValue: number
  minimise: boolean
  decimalPlaces?: number
}

const PercentageChange: FC<PercentageChangeProps> = ({ fromValue, toValue, minimise, decimalPlaces = 1 }) => {
  const percentageChange = ((toValue - fromValue) / fromValue) * 100
  const roundedPercentageChange = roundToUpToDecimals({ value: percentageChange, decimalPlaces })
  const color = (percentageChange < 0 && minimise) || (percentageChange > 0 && !minimise) ? colors.colors.alerts.affirmative : colors.colors.alerts.warning
  const icon = percentageChange < 0 ? GoTriangleDown : GoTriangleUp

  return (
    <Flex m={0} p={0} align="center">
      <Icon as={icon} color={color} boxSize="1.25rem" />
      <Text color={color}>{roundedPercentageChange}%</Text>
    </Flex>
  )
}

export default PercentageChange
