import React, { FC, useMemo, useState } from "react"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { ErrorBoundary } from "react-error-boundary"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RoutePaths } from "../../AppRoutes"
import ErrorFallBack from "../../components/ErrorFallback"
import Loading from "../../components/Loading"
import { GET_PLATFORM_INTEGRATION } from "../../graphql"
import useAuth from "../../hooks/useAuth"
import { canCreate, canUpdate, usePermissions } from "../../hooks/usePermissions"
import useResourceUris from "../../hooks/useResourceUris"
import MainLayout, { BreadcrumbItemProps } from "../../layouts/Main"
import WizardLayout, { WizardStep } from "../../layouts/Wizard/WizardLayout"
import { logError, logUnhandledError } from "../../log"
import { Optional } from "../../types"
import AuthenticatedProps from "../AuthenticatedProps"
import FacebookSetupData from "./components/FacebookSetupData"
import IntegrationDetailsForm from "./components/IntegrationDetailsForm"
import IntegrationSetupCompleteBlock from "./components/IntegrationSetupCompleteBlock"
import IntegrationVerifyBlock from "./components/IntegrationVerifyBlock"

const CREATE_FACEBOOK_INTEGRATION = gql`
  mutation createFacebookPlatformIntegration($clientId: Int!, $name: String!, $adAccountId: String!, $token: String!) {
    createFacebookPlatformIntegration(clientId: $clientId, name: $name, adAccountId: $adAccountId, token: $token) {
      platformIntegrationId
    }
  }
`

const UPDATE_FACEBOOK_INTEGRATION = gql`
  mutation updateFacebookPlatformIntegration($clientId: Int!, $name: String!, $platformIntegrationId: Int!, $adAccountId: String!, $token: String!) {
    updateFacebookPlatformIntegration(
      clientId: $clientId
      name: $name
      platformIntegrationId: $platformIntegrationId
      adAccountId: $adAccountId
      token: $token
    ) {
      platformIntegrationId
    }
  }
`

type IntegrationSetupFacebookPageProps = AuthenticatedProps

const IntegrationSetupFacebookPage: FC<IntegrationSetupFacebookPageProps> = ({ signOut }) => {
  const { user } = useAuth()
  const { platformIntegrationResourceUri } = useResourceUris()
  const { permissions, loading: permissionsLoading } = usePermissions()
  const navigate = useNavigate()
  const { clientId } = useParams()
  if (!clientId) throw Error("Expected clientId in parameters")

  const { state } = useLocation()
  const stateIntegrationId = (state as { integrationId?: string })?.integrationId

  const [platformIntegrationId, setPlatformIntegrationId] = useState<Optional<string>>(stateIntegrationId)

  const breadcrumbItems: BreadcrumbItemProps[] = [
    { text: "Integration Setup", onClick: () => navigate(RoutePaths.integrationSetupIndex.resolve(clientId)) }, //
    { text: "Facebook" },
  ]

  const [createIntegration, createIntegrationQueryDetails] = useMutation(CREATE_FACEBOOK_INTEGRATION)
  const [updateIntegration, updateIntegrationQueryDetails] = useMutation(UPDATE_FACEBOOK_INTEGRATION)
  const [getIntegration, getIntegrationQueryDetails] = useLazyQuery(GET_PLATFORM_INTEGRATION)
  const { loading, called, error, data } = getIntegrationQueryDetails

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onIntegrationDetailsSubmit = async (data: any) => {
    const { name, adAccountId, token } = data
    if (platformIntegrationId) {
      const result = await updateIntegration({
        variables: {
          clientId: Number(clientId),
          name,
          platformIntegrationId: Number(stateIntegrationId),
          adAccountId,
          token,
        },
      })
      setPlatformIntegrationId(result.data.updateFacebookPlatformIntegration.platformIntegrationId)
    } else {
      const result = await createIntegration({
        variables: {
          clientId: Number(clientId),
          name,
          adAccountId,
          token,
        },
      })
      setPlatformIntegrationId(result.data.createFacebookPlatformIntegration.platformIntegrationId)
    }
  }

  const getExistingIntegration = () => {
    setPlatformIntegrationId(platformIntegrationId)
    return getIntegration({
      variables: { clientId: Number(clientId), platformIntegrationId },
      fetchPolicy: "no-cache",
    })
  }

  const formTitle = `${stateIntegrationId ? "Update" : "New"} Integration Details`

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const steps: WizardStep<FacebookSetupData, any, any>[] = [
    {
      title: formTitle,
      component: IntegrationDetailsForm,
      props: {
        onSubmit: onIntegrationDetailsSubmit,
        queryDetails: platformIntegrationId ? updateIntegrationQueryDetails : createIntegrationQueryDetails,
      },
    },
    {
      title: "Verify Integration",
      component: IntegrationVerifyBlock,
      props: { getIntegrationState: getExistingIntegration, queryDetails: getIntegrationQueryDetails },
    },
    { title: "Summary", component: IntegrationSetupCompleteBlock },
  ]

  if (stateIntegrationId && called === false) {
    getExistingIntegration().then()
  }

  const defaultWizardData: FacebookSetupData = useMemo(() => {
    if (error) {
      logError(error)
    }
    if (data !== undefined) {
      return [
        {
          name: data.getPlatformIntegration.name,
          adAccountId: data.getPlatformIntegration.platformParams.facebook.adAccountId,
          token: undefined,
        },
        {
          success: false,
        },
        {},
      ]
    } else {
      return [
        {
          name: undefined,
          adAccountId: undefined,
          token: undefined,
        },
        {
          success: false,
        },
        {},
      ]
    }
  }, [data, error])

  const pageTitle = `${stateIntegrationId ? "Update" : "Setup"} Facebook Integration`

  const fallbackErrorMessage = `There is a problem with ${pageTitle}.`
  const fallbackErrorEmailSubject = `Unhandled Error in ${pageTitle}`
  const fallbackErrorEmailBody = `
Hello 59A Helpdesk,
  
I encountered a problem in ${pageTitle}} when doing...
`

  const permissionsErrorMessage = `You do not have permission to view this page.`
  const permissionsErrorEmailSubject = `Permissions Issue in ${pageTitle}`
  const permissionsErrorEmailBody = `
Hello 59A Helpdesk,

I encountered a permissions issue in ${pageTitle}.
`

  return (
    <MainLayout user={user} signOut={signOut} heading={pageTitle} breadcrumbItems={breadcrumbItems}>
      {permissionsLoading && <Loading />}
      {!permissionsLoading && (canCreate(permissions, platformIntegrationResourceUri) || canUpdate(permissions, platformIntegrationResourceUri)) && (
        <>
          {((called && !loading) || stateIntegrationId === undefined) && (
            <ErrorBoundary
              fallback={
                <ErrorFallBack marginTop={6} message={fallbackErrorMessage} emailSubject={fallbackErrorEmailSubject} emailBody={fallbackErrorEmailBody} />
              }
              onError={logUnhandledError}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
              <WizardLayout steps={steps} initialData={defaultWizardData} onComplete={() => {}} />
            </ErrorBoundary>
          )}
        </>
      )}
      {!permissionsLoading && !canCreate(permissions, platformIntegrationResourceUri) && !canUpdate(permissions, platformIntegrationResourceUri) && (
        <ErrorFallBack marginTop={6} message={permissionsErrorMessage} emailSubject={permissionsErrorEmailSubject} emailBody={permissionsErrorEmailBody} />
      )}
    </MainLayout>
  )
}

export default IntegrationSetupFacebookPage
