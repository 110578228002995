import { IconBase, IconBaseProps } from "./IconBase"

export const CogIcon = ({ viewBox = "0 0 24 24", ...rest }: IconBaseProps) => (
  <IconBase viewBox={viewBox} {...rest}>
    <path
      d="M24 14.187V9.813C21.852 9.047 21.274 9.011 20.973 8.284C20.67 7.555 21.056 7.115 22.032 5.061L18.939 1.968C16.913 2.931 16.451 3.332 15.715 3.027C14.988 2.725 14.947 2.138 14.188 0H9.813C9.049 2.144 9.013 2.725 8.284 3.027C7.532 3.34 7.081 2.927 5.061 1.968L1.968 5.061C2.945 7.116 3.33 7.554 3.027 8.285C2.725 9.012 2.146 9.049 0 9.813V14.188C2.139 14.948 2.725 14.988 3.027 15.716C3.331 16.45 2.946 16.883 1.968 18.939L5.061 22.032C7.06 21.082 7.531 20.659 8.284 20.973C9.012 21.275 9.048 21.853 9.813 24H14.187C14.945 21.869 14.986 21.277 15.724 20.969C16.469 20.661 16.91 21.068 18.939 22.031L22.032 18.938C21.057 16.888 20.67 16.446 20.973 15.715C21.273 14.989 21.853 14.952 24 14.187V14.187ZM19.125 14.951C18.548 16.345 19.057 17.409 19.613 18.529L18.529 19.613C17.436 19.07 16.368 18.537 14.956 19.123C13.56 19.704 13.166 20.816 12.768 22H11.234C10.836 20.815 10.443 19.703 9.051 19.125C7.632 18.537 6.544 19.08 5.472 19.613L4.389 18.529C4.946 17.411 5.455 16.349 4.876 14.949C4.297 13.558 3.185 13.165 2 12.767V11.234C3.185 10.836 4.297 10.443 4.875 9.05C5.453 7.656 4.943 6.591 4.387 5.471L5.471 4.387C6.553 4.925 7.633 5.464 9.051 4.875C10.443 4.298 10.836 3.185 11.234 2H12.768C13.166 3.185 13.56 4.297 14.952 4.875C16.371 5.463 17.458 4.92 18.531 4.387L19.615 5.471C19.059 6.592 18.55 7.658 19.127 9.051C19.704 10.442 20.816 10.835 22.002 11.234V12.768C20.814 13.166 19.7 13.559 19.125 14.951V14.951ZM12 9C13.654 9 15 10.346 15 12C15 13.654 13.654 15 12 15C10.346 15 9 13.654 9 12C9 10.346 10.346 9 12 9ZM12 7C9.238 7 7 9.238 7 12C7 14.762 9.238 17 12 17C14.762 17 17 14.762 17 12C17 9.238 14.762 7 12 7Z"
      fill="currentColor"
    />
  </IconBase>
)
