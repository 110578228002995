import React, { PropsWithChildren, ReactElement, useState } from "react"
import { ApolloError } from "@apollo/client"
import { Box, Checkbox, HStack, Skeleton, Table as ChakraTable, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils"
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table"
import { SortAscIcon, SortDescIcon, SortIcon } from "../../../icons"
import colors from "../../../theme/colors"
import { StringKeyObject } from "../../../types"
import Pagination from "../Pagination"

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }

  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export interface BaseTableProps<D extends object> {
  error?: ApolloError | boolean
  loading: boolean
  defaultSort?: SortingState
  tableColumns: ColumnDef<D>[]
  columnVisibility?: StringKeyObject<boolean>
  onPaginationChange: OnChangeFn<PaginationState>
  pagination: PaginationState
  pageSizeDisabled?: boolean
  renderSubComponent?: ({ row }: { row: Row<D> }) => JSX.Element
  rowSelection?: RowSelectionState
  setSelectedRows?: (updaterOrValue: Updater<RowSelectionState>) => void
  globalFilter?: string
  setGlobalFilter?: (change: string) => void
  tableRows: D[]
  containerHeight?: string
  containerWidth?: string
  headerBackground?: string
  headerBorderBottom?: string
  headerBorderColor?: string
  headerFontSize?: string
  paginationPaddingTop?: string
  paginationMarginRight?: string
  rowFontSize?: string
  scrollBarVerticalAdjust?: number
}

const BaseTable = <D extends object>({
  error,
  loading,
  defaultSort,
  columnVisibility,
  onPaginationChange,
  pagination,
  pageSizeDisabled = false,
  renderSubComponent,
  tableColumns,
  tableRows,
  rowSelection,
  setSelectedRows,
  globalFilter,
  setGlobalFilter,
  containerHeight,
  containerWidth,
  headerBackground,
  headerBorderBottom,
  headerBorderColor,
  headerFontSize,
  paginationPaddingTop,
  paginationMarginRight,
  rowFontSize,
  scrollBarVerticalAdjust,
}: PropsWithChildren<BaseTableProps<D>>) => {
  const [sorting, setSorting] = useState<SortingState>(defaultSort ?? [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank,
    })

    return itemRank.passed
  }

  const columns =
    setSelectedRows && tableColumns.findIndex((column) => column.id === "select") === -1
      ? ([
          {
            id: "select",
            header: ({ table }) => (
              <Box px={1}>
                <Checkbox
                  {...{
                    isChecked: table.getIsAllRowsSelected(),
                    isIndeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              </Box>
            ),
            cell: ({ row }) => (
              <Box px={1}>
                <Checkbox
                  {...{
                    isChecked: row.getIsSelected(),
                    isIndeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                  }}
                />
              </Box>
            ),
          },
          ...tableColumns,
        ] as ColumnDef<D>[])
      : tableColumns

  const tableInstance = useReactTable({
    data: tableRows,
    columns,
    globalFilterFn: (row, columnId, filterValue) => {
      const value = row.getValue(columnId)
      const safeValue = typeof value === "number" ? String(value) : value

      return (safeValue as string)?.toLowerCase().includes(filterValue.toLowerCase())
    },
    autoResetAll: false,
    state: {
      pagination,
      sorting,
      rowSelection,
      columnVisibility,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onSortingChange: setSorting,
    onPaginationChange,
    onRowSelectionChange: setSelectedRows ? setSelectedRows : undefined,
    onGlobalFilterChange: setGlobalFilter ? setGlobalFilter : undefined,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  })

  const createLoader = (pageSize: number): ReactElement[] => {
    const loadingElements: ReactElement[] = new Array(pageSize)

    const skeletonRow = (index: number, columns: number) => {
      const cells: ReactElement[] = new Array(columns)
      for (let i = 0; i < columns; i++) {
        cells[i] = (
          <Td key={`table-loader-cell-${i}`} py={3} paddingLeft={0}>
            <Skeleton fadeDuration={0.2} h="24px" width={"100%"} />
          </Td>
        )
      }
      return (
        <Tr key={`table-loader-row-${index}`} h="48px">
          {cells}
        </Tr>
      )
    }

    for (let i = 0; i < pageSize; i++) {
      loadingElements[i] = skeletonRow(i, tableInstance.getVisibleFlatColumns().length)
    }
    return loadingElements
  }

  return (
    <>
      {error ? (
        // TODO: Barns - Proper error state for Table
        <div>Table Error State: {JSON.stringify(error)}</div>
      ) : (
        <Box p={0} m={0} h={containerHeight} w={containerWidth}>
          <Box
            overflowY="scroll"
            overflowX="scroll"
            h="100%"
            w="100%"
            sx={{
              "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
              },
              "::-webkit-scrollbar-track": {
                background: colors.colors.brand["700"],
                marginTop: `${scrollBarVerticalAdjust}px`,
              },
              "::-webkit-scrollbar-thumb": {
                background: colors.colors.brand["200"],
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            {tableColumns.length > 0 && (
              <ChakraTable
                variant="custom"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: 0,
                }}
              >
                <Thead
                  bg={headerBackground}
                  sx={{
                    paddingBottom: "1px",
                    marginBottom: "3px",
                    position: "sticky",
                    top: "0px",
                  }}
                  zIndex="sticky"
                >
                  {tableInstance.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{
                            borderBottom: headerBorderBottom,
                            borderColor: headerBorderColor,
                            fontSize: headerFontSize,
                            fontWeight: "normal",
                            paddingLeft: 0,
                            marginRight: "-3px",
                            textTransform: "none",
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <HStack
                              {...{
                                sx: header.column.getCanSort()
                                  ? {
                                      ...{ cursor: "pointer" },
                                    }
                                  : undefined,
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, {
                                table: tableInstance,
                                header,
                                column: header.column,
                              })}
                              {{
                                asc: <SortAscIcon />,
                                desc: <SortDescIcon />,
                              }[header.column.getIsSorted() as string] ?? (header.column.getCanSort() ? <SortIcon /> : null)}
                            </HStack>
                          )}
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {loading
                    ? createLoader(pagination.pageSize)
                    : tableInstance
                        .getRowModel()
                        .rows.slice(0, tableInstance.getState().pagination.pageSize)
                        .map((row) =>
                          renderSubComponent ? (
                            <>
                              <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                  <Td key={cell.id} py={3} pl={0} pr={2} fontSize={rowFontSize} whiteSpace="nowrap">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </Td>
                                ))}
                              </Tr>
                              {renderSubComponent({ row })}
                            </>
                          ) : (
                            <Tr key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id} py={3} pl={0} fontSize={rowFontSize} whiteSpace="nowrap">
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                              ))}
                            </Tr>
                          )
                        )}
                </Tbody>
              </ChakraTable>
            )}
          </Box>
          <Pagination tableInstance={tableInstance} marginRight={paginationMarginRight} pageSizeDisabled={pageSizeDisabled} paddingTop={paginationPaddingTop} />
        </Box>
      )}
    </>
  )
}

export default BaseTable
