import React, { FC } from "react"
import { Select } from "chakra-react-select"
import BaseInput, { BaseInputProps } from "../BaseInput"
import { getSelectChakraStyles, Option, selectComponents } from "./SelectBaseUI"

export interface SelectInputUIProps extends Omit<BaseInputProps, "children"> {
  isReadOnly?: boolean
  isInModal?: boolean
  isMulti?: boolean
  placeholder?: string
  options: Option[]
}

const SelectInputUI: FC<SelectInputUIProps> = ({
  options,
  name,
  field,
  error,
  helperText = "",
  label = "",
  placeholder = "Select an option",
  isReadOnly = false,
  isDisabled = false,
  isInvalid,
  isMulti = false,
  isInModal = false,
  tooltip = "",
  size = "sm",
  width = 80,
  zIndex = "auto",
  ...rest
}) => {
  const selectedOptions = options.filter((value) => (Array.isArray(field.value) && field.value.includes(value.value)) || field.value === value.value)

  return (
    <BaseInput //
      name={name}
      field={field}
      error={error}
      label={label}
      tooltip={tooltip}
      helperText={helperText}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      size={size}
      width={width}
      zIndex={zIndex}
      {...rest}
    >
      <Select
        aria-label={`Select Input for ${label}`}
        isMulti={isMulti}
        components={selectComponents}
        value={selectedOptions}
        defaultValue={field.value}
        onChange={(nextValue: unknown) => {
          const nextValueCopy = (nextValue as Option[] | Option) ?? null
          // send data to react hook form
          if (Array.isArray(nextValueCopy)) {
            field.onChange(nextValueCopy.map((value) => value.value))
          } else {
            field.onChange(nextValueCopy.value)
          }
        }}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        name={name}
        onBlur={field.onBlur}
        ref={field.ref}
        placeholder={placeholder}
        options={options}
        chakraStyles={getSelectChakraStyles({ isInModal, width })}
      />
    </BaseInput>
  )
}

export default SelectInputUI
