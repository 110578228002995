import { FC } from "react"
import { Flex, Text, HStack } from "@chakra-ui/react"
import { NoData } from "../../../images"
import colors from "../../../theme/colors"

const StackedBarsWithLinesGraphNoData: FC = () => (
  <Flex p={2} m={0} height="100%" width="100%">
    <HStack alignSelf="flex-end" justifyContent="center">
      <Text fontSize="3xl" textAlign="center">
        This metric has no data for the date range selected.
      </Text>
      <NoData color={colors.colors.brand["400"]} height="35%" width="35%" />
    </HStack>
  </Flex>
)

export default StackedBarsWithLinesGraphNoData
